import { makeStyles } from '@mui/styles';
import {
  Grid,
  FormControl,
  MenuItem,
  styled,
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SiteDetail } from '../types/ClientData';
import {
  sitesFieldsMap,
  SiteDetailDataFields,
  PricingVisibility,
  pricingVisibilityOptions,
} from '../constants/VendorData';
import { disabledSiteDetailField, hideSiteDetailField, ideaRoomOnlySiteDetailField } from '../utils/vendorDataUtils';
import { LoadingSelect } from './LoadingSelect';
import { I18nKeys } from '../constants/I18nKeys';
import { SiteInputLabel } from './SiteInputLabel';
import { IdeaRoomOnlyIndicator } from './IdeaRoomOnlyIndicator';

const useStyles = makeStyles({
  inputLabel: {
    marginTop: '16px',
  },
  checkboxFieldContainer: {
    padding: '0 8px',
    '&:first-child': {
      paddingTop: '8px',
    },
    '&:last-child': {
      paddingBottom: '8px',
    },
  },
});

interface Props {
  data?: SiteDetail;
  vendorPropChange: Function;
  isIdeaRoomUser: boolean;
  selectedGroupId: string;
}

const FieldGrid = styled(Grid)({
  padding: '8px',
});

export const SitesPricingInformation: React.FC<Props> = ({
  data = {},
  vendorPropChange,
  isIdeaRoomUser,
  selectedGroupId,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container>
      <FieldGrid item xs={12} sm={6}>
        <FormControl fullWidth>
          <SiteInputLabel
            shrink
            className={classes.inputLabel}
            htmlFor="pricingVisibility-select"
            i18nKey={sitesFieldsMap[SiteDetailDataFields.PricingVisibility]?.i18nKey}
            ideaRoomOnly={ideaRoomOnlySiteDetailField(
              SiteDetailDataFields.PricingVisibility,
              data,
              isIdeaRoomUser,
              selectedGroupId,
            )}
          />
          <LoadingSelect
            value={data.pricingVisibility || ''}
            onChange={(e: any): void => vendorPropChange(e.target.name, e.target.value || null)}
            inputProps={{
              displayEmpty: true,
              name: SiteDetailDataFields.PricingVisibility,
              id: 'pricingVisibility-select',
            }}
            variant="filled"
            disabled={disabledSiteDetailField(
              SiteDetailDataFields.PricingVisibility,
              data,
              isIdeaRoomUser,
              selectedGroupId,
            )}
            alerttext={
              disabledSiteDetailField(SiteDetailDataFields.PricingVisibility, data, isIdeaRoomUser, selectedGroupId)
                ? t(I18nKeys.SitesProSubscriptionAlert)
                : undefined
            }
            loading={false}
          >
            {pricingVisibilityOptions.map((option) => (
              <MenuItem key={option.key} value={option.value !== undefined ? option.value : option.key}>
                {option.label}
              </MenuItem>
            ))}
          </LoadingSelect>
        </FormControl>
      </FieldGrid>
      {[
        PricingVisibility.ProtectedRange,
        PricingVisibility.SummarizedRange,
        PricingVisibility.SummarizedRangeHideLineItems,
      ].includes(data.pricingVisibility || PricingVisibility.Detailed) && (
        <FieldGrid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              label={t(sitesFieldsMap[SiteDetailDataFields.PriceRangePercentage]?.i18nKey || '')}
              name={SiteDetailDataFields.PriceRangePercentage}
              type="number"
              value={
                data.priceRangePercentage
                  ? parseFloat((data.priceRangePercentage * 100).toFixed(7)).toString()
                  : data.priceRangePercentage
              }
              onChange={(e: any): void => vendorPropChange(e.target.name, e.target.value, true)}
              InputProps={{
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
              }}
              variant="filled"
              disabled={disabledSiteDetailField(
                SiteDetailDataFields.PriceRangePercentage,
                data,
                isIdeaRoomUser,
                selectedGroupId,
              )}
            />
          </FormControl>
        </FieldGrid>
      )}
      <FieldGrid item xs={12} sm={6}>
        <FormControl fullWidth>
          <SiteInputLabel
            shrink
            className={classes.inputLabel}
            htmlFor="pricingVisibilityStaging-select"
            i18nKey={sitesFieldsMap[SiteDetailDataFields.PricingVisibilityStaging]?.i18nKey}
            ideaRoomOnly={ideaRoomOnlySiteDetailField(
              SiteDetailDataFields.PricingVisibilityStaging,
              data,
              isIdeaRoomUser,
              selectedGroupId,
            )}
          />
          <LoadingSelect
            value={data.pricingVisibilityStaging || ''}
            onChange={(e: any): void => vendorPropChange(e.target.name, e.target.value || null)}
            inputProps={{
              displayEmpty: true,
              name: SiteDetailDataFields.PricingVisibilityStaging,
              id: 'pricingVisibilityStaging-select',
            }}
            variant="filled"
            disabled={disabledSiteDetailField(
              SiteDetailDataFields.PricingVisibilityStaging,
              data,
              isIdeaRoomUser,
              selectedGroupId,
            )}
            alerttext={
              disabledSiteDetailField(
                SiteDetailDataFields.PricingVisibilityStaging,
                data,
                isIdeaRoomUser,
                selectedGroupId,
              )
                ? t(I18nKeys.SitesProSubscriptionAlert)
                : undefined
            }
            loading={false}
          >
            {pricingVisibilityOptions
              .filter(
                (option) =>
                  ![
                    PricingVisibility.ProtectedRange,
                    PricingVisibility.SummarizedRange,
                    PricingVisibility.SummarizedRangeHideLineItems,
                  ].includes(option.key),
              )
              .map((option) => (
                <MenuItem key={option.key} value={option.value !== undefined ? option.value : option.key}>
                  {option.label}
                </MenuItem>
              ))}
          </LoadingSelect>
        </FormControl>
      </FieldGrid>

      <Grid container>
        {[
          { field: SiteDetailDataFields.PricingEnableClientManaged, label: I18nKeys.SitesPricingClientManagedPricing },
          { field: SiteDetailDataFields.PricingEnableClientUpdates, label: I18nKeys.SitesPricingClientUpdates },
        ].map(
          ({ field, label }) =>
            !hideSiteDetailField(field, data, isIdeaRoomUser, selectedGroupId) && (
              <FieldGrid item xs={12} className={classes.checkboxFieldContainer}>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={field}
                        checked={data?.[field] === undefined ? true : !!data[field]}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                          vendorPropChange(e.target.name, e.target.checked ? 1 : 0);
                        }}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        disabled={disabledSiteDetailField(field, data, isIdeaRoomUser, selectedGroupId)}
                      />
                    }
                    label={
                      <>
                        {ideaRoomOnlySiteDetailField(field, data, isIdeaRoomUser, selectedGroupId) && (
                          <IdeaRoomOnlyIndicator />
                        )}
                        <Typography style={{ display: 'inline' }}>{t(label)}</Typography>
                      </>
                    }
                  />
                </FormControl>
              </FieldGrid>
            ),
        )}
      </Grid>
    </Grid>
  );
};
