import { Box, Stack, Typography, Slide } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import React, { useEffect, useState } from 'react';
import { SearchInput } from './SearchInput';
import { useAppSelector } from '../hooks';
import { AppState } from '../types/AppState';
import { useClientUpdatePricingRepo } from '../hooks/useClientUpdatePricingRepo';
import { ClientUpdateCategory, ComponentCategoryItem } from '../types/PricingClientUpdate';
import {
  filterClientUpdateCategories,
  filterClientUpdateItems,
  getClientUpdateSearchCountText,
  getComponentCategoryItems,
  groupComponentCategoryItems,
} from '../utils/pricingClientUpdateUtils';
import { PricingComponentRegionSelect } from './PricingComponentRegionSelect';
import { PricingTab } from '../constants/Pricing';
import { ComponentCategoryKey, SizeBasedCategoryKey } from '../constants/ClientUpdateCategoryKey';

export const PRICING_CLIENT_UPDATE_SEARCH_HEIGHT = 60;

const useStyles = makeStyles(() => ({
  searchSection: {
    width: '100%',
  },
  searchField: {
    width: '250px',
  },
}));

type Props = {
  searchValue?: string;
  setSearchValue: (value: string) => void;
};

export const PricingClientUpdateSearch: React.FC<Props> = ({ searchValue = '', setSearchValue }: Props) => {
  const classes = useStyles();

  const {
    viewer: { selectedPricingTabId: pricingTab },
    pricing: {
      component: { selectedCategoryKey: componentCategoryKey },
      sizeBased: { selectedCategoryKey: sizeBasedCategoryKey },
    },
  } = useAppSelector((state: AppState) => state);

  const {
    clientUpdateCategoriesList: categories,
    isLoadingClientUpdateCategoriesList,
    componentCategoryItemsWithConditions,
    isLoadingComponentCategoryItemsWithConditions,
    sizeBasedCategoryPricingSheets,
    isLoadingSizeBasedCategoryPricingSheets,
  } = useClientUpdatePricingRepo({
    useClientUpdateCategories: true,
    useComponentCategoryItemsWithConditions: true,
    useSizeBasedCategoryPricingSheets: true,
  });

  const containerRef = React.useRef<HTMLDivElement>(null);

  const [items, setItems] = useState<ComponentCategoryItem[] | undefined>(undefined);
  const [filteredItems, setFilteredItems] = useState<ClientUpdateCategory[] | ComponentCategoryItem[] | undefined>(
    undefined,
  );
  const [category, setCategory] = useState<ComponentCategoryKey | SizeBasedCategoryKey | undefined>(undefined);

  useEffect(() => {
    if (pricingTab === PricingTab.Component) {
      setCategory(componentCategoryKey);
      setItems(
        groupComponentCategoryItems(
          componentCategoryKey as ComponentCategoryKey,
          getComponentCategoryItems(componentCategoryItemsWithConditions),
        ),
      );
    } else {
      setCategory(sizeBasedCategoryKey);
    }
  }, [
    componentCategoryKey,
    sizeBasedCategoryKey,
    pricingTab,
    componentCategoryItemsWithConditions,
    sizeBasedCategoryPricingSheets,
  ]);

  useEffect(() => {
    setFilteredItems(
      category ? filterClientUpdateItems(searchValue, items) : filterClientUpdateCategories(searchValue, categories),
    );
  }, [category, searchValue, items, categories]);

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={3}
      className={classes.searchSection}
    >
      <SearchInput
        classes={classes}
        searchTerm={searchValue}
        onChange={(val) => setSearchValue(val)}
        onClearClick={() => setSearchValue('')}
        startAdornment={<SearchIcon />}
        variant="outlined"
        size="small"
        margin="dense"
      />
      <Box ref={containerRef} style={{ overflow: 'hidden', flex: 1 }}>
        <Slide
          direction="right"
          in={
            category
              ? !!items && pricingTab === PricingTab.Component && !isLoadingComponentCategoryItemsWithConditions
              : !!categories && !isLoadingClientUpdateCategoriesList
          }
          container={containerRef.current}
        >
          <Typography
            variant="subtitle1"
            component="div"
            color="text.secondary"
            style={{
              marginTop: '1rem',
            }}
          >
            {getClientUpdateSearchCountText(searchValue, pricingTab || '', category, filteredItems, items, categories)}
          </Typography>
        </Slide>
      </Box>
      {pricingTab !== PricingTab.SizeBased && <PricingComponentRegionSelect />}
    </Stack>
  );
};
