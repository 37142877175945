import { createApi } from '@reduxjs/toolkit/query/react';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '@reduxjs/toolkit/dist/query/core/apiState';
import { MaybeDrafted, PatchCollection } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { flatMap } from 'lodash';
import { PriceColumn } from '@idearoom/types';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { ClientDataType } from '../constants/ClientDataType';
import {
  CellMetadata,
  ClientDataBranchLogCommit,
  ClientDataBranchMetadata,
  ClientDataTableChanges,
  ClientDataTableRowDiff,
  ClientDataTablesDataQueryParam,
  ClientTable,
  ConflictResolution,
  ConstraintResolution,
  PublishingResult,
  TableMetadata,
} from '../types/ClientData';
import {
  CELL_METADATA_TABLE,
  ClientDataCacheTagType,
  ClientDataCellHistoryChange,
  ClientPublishedVersions,
  ClientVersion,
  DoltDiffType,
  MergeStatus,
  PricingDataCacheTagType,
  SITE_DETAIL_TABLE,
} from '../constants/ClientData';
import { compoundCaseToTitleCase } from '../utils/stringUtils';
import { GridData, TableData } from '../types/DataGrid';
import { getUpdatedTableDataDiff } from '../ducks/client-data/getUpdatedTableDataDiff';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';
import { getUpdatedActiveBranches } from '../ducks/client-data/getUpdatedActiveBranches';
import { User } from '../types/User';
import { addFetchingEndpoint, removeFetchingEndpoint } from '../ducks/clientDataSlice';
import { AppState } from '../types/AppState';
import { API_NAMES } from '../constants/App';
import { Commit } from '../types/Commit';
import {
  amplifyAPIBaseQuery,
  getRequestHeader,
  displayIfNotBranchNotFound,
  getEndpointCacheKey,
} from '../utils/apiUtils';
import { FetchError } from '../types/API';
import { Vendor } from '../types/VendorData';
import { areDiffValuesDifferent, Decimal65, getRowDiffColumnToFromValues } from '../utils/clientDataUtils';
import { updateComponentCategoryItemsCache, pricingApi, getPricingCacheTag } from './pricingApi';
import { PricingSheet } from '../types/PricingSheet';
import { SizeBasedCategoryKey } from '../constants/ClientUpdateCategoryKey';
import { getPricingSheetDimensions, getPricingSheetTable } from '../utils/pricingSheetUtils';
import { UserPreference } from '../constants/User';
import { isCarportView } from '../utils/clientIdUtils';
import { GridViewType } from '../constants/GridViewType';
import { PricingSheetDimension, PricingTab } from '../constants/Pricing';

export const getClientDataCacheTag = (
  type: ClientDataCacheTagType,
  params: {
    clientDataType: ClientDataType;
    clientId: string;
    groupId?: string;
    branch?: string;
    table?: string;
    category?: string;
  },
): { type: ClientDataCacheTagType; id: string } => {
  const { clientDataType, clientId, groupId, branch, table, category } = params;
  return {
    type,
    id: [clientDataType, clientId, branch, table, groupId, category].filter((param) => param).join('-'),
  };
};

export const clientDataApi = createApi({
  reducerPath: 'clientDataApi',
  tagTypes: [
    ClientDataCacheTagType.Branches,
    ClientDataCacheTagType.BranchTableDiff,
    ClientDataCacheTagType.TableData,
    ClientDataCacheTagType.PublishedVersions,
    ClientDataCacheTagType.ChangesSummary,
    ClientDataCacheTagType.CellMetadata,
    ClientDataCacheTagType.TablesData,
    ClientDataCacheTagType.VendorData,
    ClientDataCacheTagType.ComponentCategoryItems,
    ClientDataCacheTagType.ComponentCategories,
    ClientDataCacheTagType.SizeBasedCategories,
    ClientDataCacheTagType.Regions,
  ],
  refetchOnFocus: true,
  refetchOnReconnect: true,
  baseQuery: amplifyAPIBaseQuery({
    apiName: API_NAMES.API_PUBLIC,
    baseUrl: '/v1/internal/authoring',
  }),
  endpoints: (builder) => ({
    /**
     * Gets the client tables for the given clientId
     *
     * @param dataType ClientDataType
     * @param groupId
     * @param clientId
     * @returns client data tables
     */
    getClientDataTables: builder.query<ClientTable[], { dataType: ClientDataType; groupId: string; clientId: string }>({
      query: ({ dataType, groupId, clientId }) => ({
        url: `/${dataType}/tables`,
        method: 'get',
        init: {
          headers: getRequestHeader({ groupId, clientId }),
        },
      }),
      transformResponse: (response: ClientTable[]) =>
        response
          .filter(
            (table) => !table.formattedTableName.startsWith('_') && table.formattedTableName !== SITE_DETAIL_TABLE,
          )
          .map((table) => ({
            ...table,
            label: compoundCaseToTitleCase(table.formattedTableName.replace(/[aA]ttribute[s]*/g, '')),
          })),
    }),

    /**
     * Get the client vendor tables and columns for the given clientId
     *
     * @param dataType ClientDataType
     * @param clientId
     * @returns client table columns
     */
    getClientDataTablesColumns: builder.query<
      { [key: string]: string[] },
      { dataType: ClientDataType; clientId: string }
    >({
      query: ({ dataType, clientId }) => ({
        url: `/${dataType}/columns`,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId }),
        },
      }),
    }),

    /**
     * Gets the client table metadata for all tables
     *
     * @param clientId
     * @param table
     * @returns client table metadata
     */
    getClientDataAllTableMetadata: builder.query<
      TableMetadata[],
      { dataType: ClientDataType; clientId: string; branch: ClientDataBranch }
    >({
      query: ({ dataType, clientId, branch }) => ({
        url: `/${dataType}/tables/metadata?branch=${branch}`,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId }),
        },
      }),
    }),

    getBranchDiffMerges: builder.query<
      Commit[],
      {
        dataType: ClientDataType;
        clientId: string;
        groupId: string;
        branch: string;
        fromBranch?: string;
        tables?: string[];
        limit?: number;
      }
    >({
      query: ({ dataType, clientId, groupId, branch, fromBranch = ClientDataBranch.Main, limit = 10, tables }) => ({
        url: `/${dataType}/branches/${branch}/merges?fromBranch=${fromBranch}&limit=${limit}${
          tables ? `&tables=${tables.join(',')}` : ``
        }`,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId, groupId }),
        },
        displayToastOnError:
          displayIfNotBranchNotFound(ClientDataBranch.Pricing) ||
          displayIfNotBranchNotFound(ClientDataBranch.PricingSizeBased),
      }),
    }),

    /**
     * Gets the client table metadata for the given clientId and table
     *
     * @param clientId
     * @param table
     * @returns client table metadata
     */
    getClientDataTableMetadata: builder.query<
      TableMetadata,
      { dataType: ClientDataType; clientId: string; table: string; branch: ClientDataBranch }
    >({
      query: ({ dataType, clientId, table, branch }) => ({
        url: `/${dataType}/tables/${table}/metadata?branch=${branch}`,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId }),
        },
      }),
    }),

    /**
     * Saves the client table metadata for the given client and table
     *
     * @param clientId
     * @param table
     * @param sheds
     * @param carports
     * @param tableMetadata
     * @returns {Promise}
     */
    updateClientTableMetadata: builder.mutation<
      void,
      {
        dataType: ClientDataType;
        clientId: string;
        table: string;
        sheds: boolean;
        carports: boolean;
        tableMetadata: TableMetadata;
        branch: ClientDataBranch;
      }
    >({
      query: ({ dataType, clientId, table, sheds, carports, tableMetadata, branch }) => ({
        url: `/${dataType}/tables/${table}/metadata?branch=${branch}`,
        method: 'put',
        init: {
          headers: getRequestHeader({ clientId }),
          body: {
            sheds,
            carports,
            tableMetadata,
          },
        },
      }),
    }),

    /**
     * Gets the client table cell metadata for the given clientId and table
     *
     * @param dataType ClientDataType
     * @param clientId
     * @param table
     * @param branch
     * @returns client table cell metadata
     */
    getClientDataCellMetadata: builder.query<
      CellMetadata[],
      { dataType: ClientDataType; clientId: string; groupId: string; table: string; branch: ClientDataBranch }
    >({
      query: ({ dataType, clientId, groupId, table, branch }) => ({
        url: `/${dataType}/tables/${table}/cell-metadata?branch=${branch}`,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId, groupId }),
        },
        displayToastOnError:
          displayIfNotBranchNotFound(ClientDataBranch.Pricing) ||
          displayIfNotBranchNotFound(ClientDataBranch.PricingSizeBased),
      }),
      providesTags: (result, error, { dataType, clientId, groupId, branch, table }) => [
        getClientDataCacheTag(ClientDataCacheTagType.CellMetadata, {
          clientDataType: dataType,
          clientId,
          branch,
          groupId,
        }),
      ],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const endpointCacheKey = getEndpointCacheKey('getClientDataCellMetadata', args);
        const promise = new Promise<void>((resolve) => {
          queryFulfilled.then(() => resolve());
        });
        dispatch(addFetchingEndpoint({ endpointCacheKey, promise }));
        await queryFulfilled;
        dispatch(removeFetchingEndpoint(endpointCacheKey));
      },
    }),

    /**
     * Saves the client table cell metadata for the given client, table, and row ID
     *
     * @param clientId
     * @param table
     * @param rowId
     * @param cellMetadata
     * @returns {Promise}
     */
    updateClientDataCellMetadata: builder.mutation<
      void,
      {
        dataType: ClientDataType;
        clientId: string;
        groupId: string;
        table: string;
        branch: ClientDataBranch;
        cellMetadata: CellMetadata;
        rowId: string;
        newBranch: boolean;
        message: string;
      }
    >({
      query: ({ dataType, clientId, table, cellMetadata, branch, rowId, newBranch, message }) => {
        const { metadata } = cellMetadata;
        return {
          url: `/${dataType}/tables/${table}/cell-metadata/${rowId}?branch=${branch}`,
          method: 'put',
          init: {
            headers: getRequestHeader({ clientId }),
            body: {
              metadata,
              newBranch,
              message,
            },
          },
        };
      },
      // When creating a new branch or editing multiple tables at once, it's just easier to refetch all the branches again
      invalidatesTags: (result, error, { dataType, clientId, branch, newBranch }) => [
        ...(newBranch
          ? [getClientDataCacheTag(ClientDataCacheTagType.Branches, { clientDataType: dataType, clientId })]
          : []),
        getClientDataCacheTag(ClientDataCacheTagType.BranchTableDiff, {
          clientDataType: dataType,
          clientId,
          branch,
          table: CELL_METADATA_TABLE,
        }),
      ],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const { dataType, clientId, table, branch, cellMetadata, groupId } = args;
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const patchResult = updateCellMetadataCache(
          { dataType, clientId, groupId, table, branch, cellsMetadata: [cellMetadata] },
          dispatch,
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
          dispatch(
            clientDataApi.util.invalidateTags([
              getClientDataCacheTag(ClientDataCacheTagType.TableData, {
                clientDataType: dataType,
                clientId,
                groupId,
                branch,
              }),
            ]),
          );
        }
      },
    }),

    /**
     * Saves the client table cell metadata for the given client, ant table
     *
     * @param clientId
     * @param table
     * @param rowId
     * @param cellMetadata
     * @returns {Promise}
     */
    updateClientDataCellsMetadata: builder.mutation<
      void,
      {
        dataType: ClientDataType;
        clientId: string;
        groupId: string;
        table: string;
        branch: ClientDataBranch;
        cellsMetadata: CellMetadata[];
        newBranch: boolean;
        message: string;
      }
    >({
      query: ({ dataType, clientId, table, cellsMetadata, groupId, branch, newBranch, message }) => ({
        url: `/${dataType}/tables/${table}/cell-metadata?branch=${branch}`,
        method: 'put',
        init: {
          headers: getRequestHeader({ clientId, groupId }),
          body: {
            metadata: cellsMetadata,
            newBranch,
            message,
          },
        },
      }),
      // When creating a new branch or editing multiple tables at once, it's just easier to refetch all the branches again
      invalidatesTags: (result, error, { dataType, clientId, branch, newBranch }) => [
        ...(newBranch
          ? [getClientDataCacheTag(ClientDataCacheTagType.Branches, { clientDataType: dataType, clientId })]
          : []),
        getClientDataCacheTag(ClientDataCacheTagType.BranchTableDiff, {
          clientDataType: dataType,
          clientId,
          branch,
          table: CELL_METADATA_TABLE,
        }),
      ],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const patchResult = updateCellMetadataCache(args, dispatch);
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
          const { dataType, clientId, branch, groupId } = args;
          dispatch(
            clientDataApi.util.invalidateTags([
              getClientDataCacheTag(ClientDataCacheTagType.TableData, {
                clientDataType: dataType,
                clientId,
                groupId,
                branch,
              }),
            ]),
          );
        }
      },
    }),

    /**
     * Gets the branch table diff for a specific dataset, branch, table, and client.
     *
     * @param dataType ClientDataType
     * @param branch
     * @param table
     * @param clientId
     * @returns branches
     */
    getClientDataBranchTableDiff: builder.query<
      ClientDataTableRowDiff[],
      { dataType: ClientDataType; branch: ClientDataBranch; table: string; clientId: string }
    >({
      query: ({ dataType, clientId, branch, table }) => ({
        url: `/${dataType}/branches/${branch}/diff/${table}`,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId }),
        },
        displayToastOnError:
          displayIfNotBranchNotFound(ClientDataBranch.Pricing) ||
          displayIfNotBranchNotFound(ClientDataBranch.PricingSizeBased),
      }),
      providesTags: (result, error, { dataType, clientId, branch, table }) => [
        getClientDataCacheTag(ClientDataCacheTagType.BranchTableDiff, { clientDataType: dataType, clientId, branch }),
        getClientDataCacheTag(ClientDataCacheTagType.BranchTableDiff, {
          clientDataType: dataType,
          clientId,
          branch,
          table,
        }),
      ],
      transformResponse: (response: { table: string; changes: ClientDataTableRowDiff[] }) => response.changes,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const endpointCacheKey = getEndpointCacheKey('getClientDataBranchTableDiff', args);
        const promise = new Promise<void>((resolve) => {
          queryFulfilled.then(() => resolve());
        });
        dispatch(addFetchingEndpoint({ endpointCacheKey, promise }));
        await queryFulfilled;
        dispatch(removeFetchingEndpoint(endpointCacheKey));
      },
    }),

    /**
     * Gets the branch table diff for a dataset, branch, client and specified tables.
     *
     * @param dataType ClientDataType
     * @param branch
     * @param tables
     * @param clientId
     * @returns table diffs
     */
    getClientDataBranchDiff: builder.query<
      { table: string; changes: ClientDataTableRowDiff[] }[],
      { dataType: ClientDataType; branch: ClientDataBranch; tables: string[]; clientId: string }
    >({
      query: ({ dataType, clientId, branch, tables }) => ({
        url: `/${dataType}/branches/${branch}/diff?tables=${tables.join(',')}`,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId }),
        },
        displayToastOnError: displayIfNotBranchNotFound(ClientDataBranch.Pricing),
      }),
      providesTags: (result, error, { dataType, clientId, branch, tables }) => [
        getClientDataCacheTag(ClientDataCacheTagType.BranchTableDiff, { clientDataType: dataType, clientId, branch }),
        ...tables.map((table) =>
          getClientDataCacheTag(ClientDataCacheTagType.BranchTableDiff, {
            clientDataType: dataType,
            clientId,
            branch,
            table,
          }),
        ),
      ],
      async onQueryStarted({ dataType, branch, tables, clientId }, { dispatch, queryFulfilled }) {
        await Promise.all(
          tables.map(async (table) => {
            const endpointCacheKey = getEndpointCacheKey('getClientDataBranchTableDiff', {
              dataType,
              branch,
              table,
              clientId,
            });
            const promise = new Promise<void>((resolve) => {
              queryFulfilled.then(() => resolve());
            });
            dispatch(addFetchingEndpoint({ endpointCacheKey, promise }));
            await queryFulfilled;
            dispatch(removeFetchingEndpoint(endpointCacheKey));
          }),
        );
      },
    }),

    /**
     * Gets all the client data from the repository (for searching).
     *
     * @param dataType ClientDataType
     * @param clientId
     * @param branch
     * @returns client data
     */
    getClientData: builder.query<
      GridData,
      { dataType: ClientDataType; branch: ClientDataBranch; groupId: string; clientId: string }
    >({
      query: ({ dataType, branch, groupId, clientId }) => ({
        url: `/${dataType}/data?branch=${branch}`,
        method: 'get',
        init: {
          headers: getRequestHeader({ groupId, clientId }),
        },
      }),
    }),

    /**
     * Gets the client table data from the repository.
     *
     * @param dataType ClientDataType
     * @param clientId
     * @param branch
     * @param table
     * @returns client data
     */
    getClientDataTableData: builder.query<
      TableData[],
      {
        dataType: ClientDataType;
        branch: ClientDataBranch;
        clientId: string;
        groupId: string;
        table: string;
        columns?: string[];
        enabledOnly?: boolean;
      }
    >({
      query: ({ dataType, clientId, branch, table, groupId, columns, enabledOnly = false }) => ({
        url: `/${dataType}/data/${table}?branch=${branch}&enabledOnly=${enabledOnly}${
          columns ? `&columns=${columns.join(',')}` : ''
        }`,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId, groupId }),
        },
      }),
      providesTags: (result, error, { dataType, clientId, groupId, branch, table }) => [
        getClientDataCacheTag(ClientDataCacheTagType.TableData, {
          clientDataType: dataType,
          clientId,
          groupId,
          branch,
        }),
        getClientDataCacheTag(ClientDataCacheTagType.TableData, {
          clientDataType: dataType,
          clientId,
          groupId,
          branch,
          table,
        }),
      ],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const endpointCacheKey = getEndpointCacheKey('getClientDataTableData', args);
        const promise = new Promise<void>((resolve) => {
          queryFulfilled.then(() => resolve());
        });
        dispatch(addFetchingEndpoint({ endpointCacheKey, promise }));
        await queryFulfilled;
        dispatch(removeFetchingEndpoint(endpointCacheKey));
      },
    }),

    /**
     * Gets the client table data for the specified tables and columns
     *
     * @returns  data
     */
    getClientDataTablesData: builder.query<GridData[], { tables: ClientDataTablesDataQueryParam[] }>({
      async queryFn({ tables }, api, extraOptions, apiQuery) {
        const result = await Promise.all(
          tables.map(({ dataType, branch, clientId, tableNames, columns }) =>
            apiQuery({
              url: `/${dataType}/data?branch=${branch}&tables=${tableNames.join(',')}&columns=${columns.join(',')}`,
              method: 'get',
              init: {
                headers: getRequestHeader({ clientId }),
              },
            }),
          ),
        );
        const queryWithError = result.find((query) => query.error);
        if (queryWithError) {
          return { error: queryWithError.error as FetchError };
        }
        return { data: result.map((query) => query.data as GridData) };
      },
      providesTags: (result, error, { tables }) => [
        ...tables.reduce<{ type: ClientDataCacheTagType; id: string }[]>(
          (acc, table) => [
            ...acc,
            ...table.tableNames.map((tableName) =>
              getClientDataCacheTag(ClientDataCacheTagType.TablesData, {
                clientDataType: table.dataType,
                clientId: table.clientId,
                branch: table.branch,
                table: tableName,
              }),
            ),
          ],
          [],
        ),
      ],
    }),

    /**
     * Updates the client data in the repository.
     *
     * @param dataType
     * @param clientId
     * @param data
     * @param message
     * @param branch
     * @returns
     */
    updateClientData: builder.mutation<
      void,
      {
        dataType: ClientDataType;
        clientId: string;
        groupId: string;
        data: GridData;
        metadata: {
          [table: string]: CellMetadata[];
        } | null;
        message: string;
        branch: ClientDataBranch;
        newBranch: boolean;
        user: User | undefined;
      }
    >({
      query: ({ branch, dataType, clientId, groupId, data, metadata, message, newBranch }) => ({
        url: `/${dataType}/data?branch=${branch}`,
        method: 'put',
        init: {
          headers: getRequestHeader({ clientId, groupId }),
          body: { data, metadata, message, newBranch },
        },
      }),
      invalidatesTags: (result, error, { newBranch, dataType, clientId, data, branch }) => [
        // When creating a new branch or editing multiple tables at once, it's just easier to refetch all the branches again
        ...(newBranch || Object.keys(data).length > 1
          ? [getClientDataCacheTag(ClientDataCacheTagType.Branches, { clientDataType: dataType, clientId })]
          : []),
        ClientDataCacheTagType.ChangesSummary,
        ...Object.keys(data).map((table) =>
          getClientDataCacheTag(ClientDataCacheTagType.TablesData, {
            clientDataType: dataType,
            clientId,
            branch,
            table,
          }),
        ),
      ],
      async onQueryStarted(
        { branch, dataType, clientId, data, metadata, user, groupId },
        { dispatch, queryFulfilled, getState },
      ) {
        // Optimistically updates the cache so we don't have to refetch these queries again
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const patch = await updateClientDataGridDataCache(
          { branch, dataType, clientId, data, metadata, user, groupId },
          false,
          dispatch,
          getState() as RootState<any, any, 'clientDataApi'> & RootState<any, any, 'pricingApi'> & AppState,
          true,
        );

        try {
          await queryFulfilled;
        } catch {
          const {
            pricing: {
              sizeBased: { selectedCategoryKey: sizeBasedCategoryKey, selectedPricingSheetId: sizeBasedPricingSheetId },
            },
          } = getState() as AppState;
          // Update failed, fetch new data just to be sure (the error might be related to a recently added/modified row)
          patch.undo();
          dispatch(
            clientDataApi.util.invalidateTags([
              getClientDataCacheTag(ClientDataCacheTagType.TableData, {
                clientDataType: dataType,
                clientId,
                groupId,
                branch,
              }),
            ]),
          );
          dispatch(
            pricingApi.util.invalidateTags([
              PricingDataCacheTagType.PricingSheets,
              getPricingCacheTag(ClientDataCacheTagType.SizeBasedCategories, {
                groupId,
                clientId,
                category: sizeBasedCategoryKey,
                id: sizeBasedPricingSheetId,
                branch,
              }),
            ]),
          );
        }
      },
    }),

    /**
     * Delete the client data in the repository.
     *
     * @param dataType
     * @param clientId
     * @param data
     * @param message
     * @param branch
     * @returns
     */
    deleteClientData: builder.mutation<
      void,
      {
        dataType: ClientDataType;
        clientId: string;
        data: { [tableName: string]: Pick<TableData, ClientDataFixedColumns.RowId>[] };
        message: string;
        branch: ClientDataBranch;
        newBranch: boolean;
        user: User | undefined;
        groupId: string;
      }
    >({
      query: ({ branch, dataType, clientId, data, message, newBranch }) => ({
        url: `/${dataType}/data/deletes?branch=${branch}`,
        method: 'post',
        init: {
          headers: getRequestHeader({ clientId }),
          body: { data, message, newBranch },
        },
      }),
      invalidatesTags: (result, error, { newBranch, dataType, clientId, data, branch }) => [
        // When creating a new branch or editing multiple tables at once, it's just easier to refetch all the branches again
        ...(newBranch || Object.keys(data).length > 1
          ? [getClientDataCacheTag(ClientDataCacheTagType.Branches, { clientDataType: dataType, clientId })]
          : []),
        ClientDataCacheTagType.ChangesSummary,
        ...Object.keys(data).map((table) =>
          getClientDataCacheTag(ClientDataCacheTagType.TablesData, {
            clientDataType: dataType,
            clientId,
            branch,
            table,
          }),
        ),
      ],
      async onQueryStarted(
        { branch, dataType, clientId, data, user, groupId },
        { dispatch, queryFulfilled, getState },
      ) {
        // Optimistically updates the cache so we don't have to refetch these queries again
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const patch = await updateClientDataGridDataCache(
          { branch, dataType, clientId, data, user, metadata: null, groupId },
          true,
          dispatch,
          getState() as RootState<any, any, 'clientDataApi'> & RootState<any, any, 'pricingApi'> & AppState,
          true,
        );

        try {
          await queryFulfilled;
        } catch {
          // Update failed, fetch new data just to be sure (the error might be related to a recently added/modified row)
          patch.undo();
          dispatch(
            clientDataApi.util.invalidateTags([
              getClientDataCacheTag(ClientDataCacheTagType.TableData, {
                clientDataType: dataType,
                clientId,
                groupId,
                branch,
              }),
            ]),
          );
        }
      },
    }),

    /**
     * Gets the active branches for a specific dataset and client
     *
     * @param dataType ClientDataType
     * @param clientId
     * @returns branches
     */
    getClientDataBranches: builder.query<
      ClientDataBranchMetadata[],
      { dataType: ClientDataType; clientId: string; groupId: string }
    >({
      query: ({ dataType, clientId, groupId }) => ({
        url: `/${dataType}/branches`,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId, groupId }),
        },
      }),
      providesTags: (result, error, { dataType, clientId }) => [
        getClientDataCacheTag(ClientDataCacheTagType.Branches, { clientDataType: dataType, clientId }),
      ],
    }),

    /**
     * Creates a branch for a specific dataType and clientId.
     *
     * @param branch
     * @param dataType
     * @param clientId
     * @param description
     */
    addBranch: builder.mutation<
      ClientDataBranchMetadata,
      { branch: ClientDataBranch; dataType: ClientDataType; clientId: string; description?: string; groupId: string }
    >({
      query: ({ branch, dataType, clientId, description }) => ({
        url: `/${dataType}/branches/${branch}`,
        method: 'put',
        init: {
          headers: getRequestHeader({ clientId }),
          body: {
            description,
          },
        },
      }),
      async onQueryStarted({ dataType, clientId, groupId }, { dispatch, queryFulfilled }) {
        try {
          const { data: newBranchMetadata } = await queryFulfilled;
          // performs a pessimistic update on the cache after the branch creation, so we don't have to refetch
          dispatch(
            clientDataApi.util.updateQueryData('getClientDataBranches', { dataType, clientId, groupId }, (draft) => {
              draft.push(newBranchMetadata);
            }),
          );
        } catch {
          // Branch creation failed for some reason, refetch active branches from server
          dispatch(
            clientDataApi.util.invalidateTags([
              getClientDataCacheTag(ClientDataCacheTagType.Branches, { clientDataType: dataType, clientId }),
            ]),
          );
        }
      },
    }),

    /**
     * Deletes a branch for a specific dataType and clientId.
     *
     * @param branch
     * @param dataType
     * @param clientId
     */
    deleteBranch: builder.mutation<
      void,
      { branch: ClientDataBranch; dataType: ClientDataType; clientId: string; groupId: string }
    >({
      query: ({ branch, dataType, clientId, groupId }) => ({
        url: `/${dataType}/branches/${branch}`,
        method: 'del',
        init: {
          headers: getRequestHeader({ clientId, groupId }),
        },
      }),
      invalidatesTags: (result, error, { branch, dataType, clientId, groupId }) => [
        ...[ClientDataCacheTagType.CellMetadata, ClientDataCacheTagType.BranchTableDiff].map(
          (tagType: ClientDataCacheTagType) =>
            getClientDataCacheTag(tagType, {
              clientDataType: dataType,
              clientId,
              branch,
            }),
        ),
        getClientDataCacheTag(ClientDataCacheTagType.TableData, {
          clientDataType: dataType,
          clientId,
          groupId,
          branch,
        }),
      ],
      async onQueryStarted({ branch, dataType, clientId, groupId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          // performs a pessimistic update on the cache after the branch deletion, so we don't have to refetch
          dispatch(
            clientDataApi.util.updateQueryData('getClientDataBranches', { dataType, clientId, groupId }, (draft) =>
              draft.filter((branchMetadata) => branchMetadata.branchType !== branch),
            ),
          );
          dispatch(
            pricingApi.util.invalidateTags([
              PricingDataCacheTagType.PricingSheets,
              ClientDataCacheTagType.SizeBasedCategories,
              ClientDataCacheTagType.ComponentCategories,
              ClientDataCacheTagType.ComponentCategoryItems,
              ClientDataCacheTagType.Regions,
            ]),
          );
        } catch {
          // Branch deletion failed for some reason, refetch active branches from server
          dispatch(
            clientDataApi.util.invalidateTags([
              getClientDataCacheTag(ClientDataCacheTagType.Branches, { clientDataType: dataType, clientId }),
            ]),
          );
        }
      },
    }),

    /**
     * Saves the branch metadata for a specific dataType and clientId.
     *
     * @param branch
     * @param dataType
     * @param clientId
     */
    saveBranchMetadata: builder.mutation<
      void,
      { branch: ClientDataBranch; dataType: ClientDataType; clientId: string; description: string; groupId: string }
    >({
      query: ({ branch, dataType, clientId, groupId, description }) => ({
        url: `/${dataType}/branches/${branch}/metadata`,
        method: 'put',
        init: {
          headers: getRequestHeader({ clientId, groupId }),
          body: { description },
        },
      }),
      async onQueryStarted({ branch, dataType, clientId, description, groupId }, { dispatch, queryFulfilled }) {
        // performs an optimistic update on the cache
        const patch = dispatch(
          clientDataApi.util.updateQueryData('getClientDataBranches', { dataType, clientId, groupId }, (draft) => {
            const branchData = draft.find((branchMetadata) => branchMetadata.branchType === branch);
            if (branchData) {
              branchData.description = description;
            }
          }),
        );
        try {
          await queryFulfilled;
        } catch (error) {
          patch.undo();
        }
      },
    }),

    /**
     * Gets the branch log for a specific dataset and client
     *
     * @param dataType ClientDataType
     * @param clientId
     * @returns branches
     */
    getClientDataBranchLog: builder.query<
      ClientDataBranchLogCommit[],
      {
        dataType: ClientDataType;
        branch: string;
        clientId: string;
        messageNotEqualFilter: string;
      }
    >({
      query: ({ dataType, clientId, branch, messageNotEqualFilter }) => ({
        url: `/${dataType}/branches/${branch}/log?${new URLSearchParams({
          notEqual: messageNotEqualFilter,
        })}`,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId }),
        },
      }),
    }),

    /**
     * Gets the branch changes summary for a specific dataset and client
     *
     * @param dataType ClientDataType
     * @param clientId
     * @returns branches
     */
    getClientDataBranchChangesSummary: builder.query<
      ClientDataTableChanges[],
      {
        dataType: ClientDataType;
        branch: string;
        clientId: string;
      }
    >({
      query: ({ dataType, clientId, branch }) => ({
        url: `/${dataType}/branches/${branch}/changes`,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId }),
        },
      }),
      providesTags: [ClientDataCacheTagType.ChangesSummary],
    }),

    /**
     * Publishes the branch to production
     */
    publishClientData: builder.mutation<
      PublishingResult,
      {
        dataType: ClientDataType;
        clientId: string;
        groupId: string;
        branch: ClientDataBranch;
        message: string;
        conflictResolution?: { [branchName: string]: ConflictResolution[] };
        constraintResolution?: { [branchName: string]: ConstraintResolution[] };
      }
    >({
      query: ({ dataType, clientId, groupId, branch, message, conflictResolution, constraintResolution }) => {
        const body = { message, conflictResolution, constraintResolution };
        return {
          url: `/${dataType}/branches/${branch}/publish`,
          method: 'post',
          init: {
            headers: getRequestHeader({ clientId, groupId }),
            body,
          },
          // Errors are going to be displayed on the dialog
          displayToastOnError: false,
        };
      },
      async onQueryStarted({ dataType, clientId, branch, groupId }, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        if (data.mainMerge.status === MergeStatus.Succeed) {
          // removes the branch that was just published from the cache
          dispatch(
            clientDataApi.util.updateQueryData('getClientDataBranches', { dataType, clientId, groupId }, (draft) =>
              draft.filter((branchMetadata) => branchMetadata.branchType !== branch),
            ),
          );
        }
      },
      invalidatesTags: [ClientDataCacheTagType.PublishedVersions],
    }),

    /**
     * Publishes the vendor to S3
     */
    publishVendor: builder.mutation<
      void,
      {
        clientId: string;
        suppliers: string[];
        groupId: string;
      }
    >({
      query: ({ clientId, suppliers, groupId }) => ({
        url: `/vendor/publish?suppliers=${suppliers.join(',')}`,
        method: 'post',
        init: {
          headers: getRequestHeader({ clientId, groupId }),
          body: {}, // sc-31328 - Needs to send an empty body otherwise it fails when deployed
        },
        // Errors are going to be displayed on the dialog
        displayToastOnError: false,
      }),
      invalidatesTags: [ClientDataCacheTagType.PublishedVersions],
    }),

    /**
     * Creates a new dataset from a given vendor template client ID
     *
     * @param clientId
     * @param vendorDataTemplateId clientId to copy for all vendor tables
     * @param supplierDataTemplateId clientId to copy for all supplier tables (optional and not used by dealers)
     * @param supplierKey (dealers only) default supplier for new dealer
     */
    createDataset: builder.mutation<
      void,
      {
        clientId: string;
        groupId: string;
        dataType: ClientDataType;
        templateId: string;
        clientName?: string;
        supplierKey?: string;
        distribution?: string;
      }
    >({
      query: ({ clientId, groupId, dataType, templateId, clientName, supplierKey, distribution }) => ({
        url: `/${dataType}/template/${templateId}`,
        method: 'post',
        init: {
          headers: getRequestHeader({ clientId }),
          body: {
            clientName: clientName || clientId,
            groupId,
            supplierKey: supplierKey || '',
            distribution: distribution || '',
          },
        },
      }),
    }),

    /**
     * Rolls back the dataset to a specific version.
     * Opens a new unpublished branch with the reverted changes.
     */
    rollbackDataset: builder.mutation<
      void,
      {
        dataType: ClientDataType;
        clientId: string;
        version: number;
      }
    >({
      query: ({ dataType, clientId, version }) => ({
        url: `/${dataType}/rollback/${version}`,
        method: 'post',
        init: {
          headers: getRequestHeader({ clientId }),
          body: {}, // sc-31328 - Needs to send an empty body otherwise it fails when deployed
        },
      }),
      invalidatesTags: (result, error, { dataType, clientId }) => [
        getClientDataCacheTag(ClientDataCacheTagType.Branches, { clientDataType: dataType, clientId }),
      ],
    }),

    /**
     * Searches the given table for all vendors that match the given search term
     *
     * @param dataType ClientDataType
     * @param clientId
     * @param table
     * @param searchTerm
     * @param branch
     * @returns client data
     */
    searchTable: builder.query<
      { [clientId: string]: GridData },
      {
        dataType: ClientDataType;
        clientId: string;
        table: string;
        searchTerm: string;
        branch?: string;
      }
    >({
      query: ({ dataType, clientId, table, branch, searchTerm }) => ({
        url: `/${dataType}/search/table/${table}?branch=${branch || ''}&searchTerm=${encodeURIComponent(
          searchTerm || '',
        )}`,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId }),
        },
      }),
    }),

    /**
     * Gets the list of vendors using this supplier
     *
     * @param clientId
     * @returns vendors keys
     */
    getSupplierVendorsList: builder.query<string[], { clientId: string; groupId: string }>({
      query: ({ clientId, groupId }) => ({
        url: `/supplier/vendors`,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId, groupId }),
        },
      }),
    }),

    /**
     * Gets the client published versions for vendor, suppliers and reference.
     *
     * @param dataType
     * @param clientId
     * @returns
     */
    getClientPublishedVersions: builder.query<
      ClientPublishedVersions,
      {
        dataType: ClientDataType;
        clientId: string;
      }
    >({
      query: ({ dataType, clientId }) => ({
        url: `/${dataType}/publish/versions`,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId }),
        },
      }),
      providesTags: [ClientDataCacheTagType.PublishedVersions],
    }),

    /**
     * Gets the saved versions of a dataset.
     *
     * @param dataType
     * @param clientId
     * @returns
     */
    getClientVersions: builder.query<
      ClientVersion[],
      {
        dataType: ClientDataType;
        clientId: string;
        limit?: number;
      }
    >({
      query: ({ dataType, clientId, limit }) => ({
        url: `/${dataType}/versions?limit=${limit}`,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId }),
        },
      }),
      providesTags: [ClientDataCacheTagType.PublishedVersions],
    }),

    /**
     * Gets all supplier vendors for a given branch and configurator
     *
     * @param clientId
     * @returns suppliers
     */
    getSuppliers: builder.query<{ key: string; name: string }[], { clientId: string }>({
      query: ({ clientId }) => ({
        url: `/suppliers`,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId }),
        },
      }),
    }),

    /**
     * Gets all vendors for a given branch and configurator
     *
     * @param clientId
     * @returns suppliers
     */
    getVendors: builder.query<{ key: string; name: string }[], { clientId: string }>({
      query: ({ clientId }) => ({
        url: `/vendors`,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId }),
        },
      }),
    }),

    /**
     * Gets the vendor data for the given client.
     * Combines client data with the data from the vendorData table.
     *
     * @param clientId
     * @returns vendor data
     */
    getVendorData: builder.query<Vendor, { clientId: string }>({
      query: ({ clientId }) => ({
        url: `/vendor`,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId }),
        },
      }),
      providesTags: (result, error, { clientId }) => [
        getClientDataCacheTag(ClientDataCacheTagType.VendorData, { clientDataType: ClientDataType.Vendor, clientId }),
      ],
    }),

    /**
     * Gets all vendors marked as templates in vendor data for the given client's configurator type
     *
     * @param clientId
     * @returns vendor keys
     */
    getTemplateVendors: builder.query<string[], { clientId: string }>({
      query: ({ clientId }) => ({
        url: `/templates`,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId }),
        },
      }),
    }),

    /**
     * Gets the log of changes that happened in a specific cell
     *
     * @returns log of changes
     */
    getBranchHistory: builder.query<
      ClientDataCellHistoryChange[],
      {
        dataType: ClientDataType;
        clientId: string;
        branch?: string;
      }
    >({
      query: ({ dataType, branch, clientId }) => ({
        url: `/${dataType}/history/?branch=${branch || ''}`,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId }),
        },
      }),
    }),

    /**
     * Gets the log of changes that happened in a specific cell
     *
     * @returns log of changes
     */
    getCellHistory: builder.query<
      ClientDataCellHistoryChange[],
      {
        dataType: ClientDataType;
        clientId: string;
        table: string;
        rowId: string;
        column: string;
        branch?: string;
      }
    >({
      query: ({ dataType, table, rowId, column, clientId, branch }) => ({
        url: `/${dataType}/history/${table}/${rowId}/${column}?branch=${branch || ''}`,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId }),
        },
      }),
    }),

    /**
     * Gets all open branches
     *
     * @returns open branches
     */
    getOpenBranches: builder.query<
      {
        name: string;
        last_committer: string;
        last_commit_date: string;
        createDate: string;
        creator: string;
      },
      {
        clientId: string;
      }
    >({
      query: ({ clientId }) => ({
        url: `/open-branches`,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId }),
        },
      }),
    }),

    /**
     * Gets all visible styles for a given client ID
     *
     * @returns open branches
     */
    getVisibleStyles: builder.query<
      {
        key: string;
        label: string;
      }[],
      {
        clientId: string;
        groupId: string;
        dataType: ClientDataType;
        branch?: string;
      }
    >({
      query: ({ clientId, groupId, dataType, branch }) => ({
        url: `/${dataType}/visible-styles?branch=${branch || ''}`,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId, groupId }),
        },
      }),
    }),
  }),
});

const updateCellMetadataCache = (
  {
    dataType,
    clientId,
    groupId,
    table,
    branch,
    cellsMetadata,
  }: {
    dataType: ClientDataType;
    clientId: string;
    groupId: string;
    table: string;
    branch: ClientDataBranch;
    cellsMetadata: CellMetadata[];
  },
  dispatch: ThunkDispatch<any, any, AnyAction>,
) => {
  const patches: PatchCollection[] = [];
  patches.push(
    dispatch(
      clientDataApi.util.updateQueryData(
        'getClientDataCellMetadata',
        { dataType, branch, clientId, table, groupId },
        (draft) => [
          ...draft.filter(
            (draftMetadata) =>
              draftMetadata.clientId !== clientId ||
              draftMetadata.table !== table ||
              !cellsMetadata.some((updatedMetadata) => draftMetadata.rowId === updatedMetadata.rowId),
          ),
          ...cellsMetadata.map((update) => ({
            rowId: update.rowId,
            table,
            clientId,
            metadata: update.metadata,
          })),
        ],
      ),
    ),
  );
  return { undo: () => patches.forEach((patch) => patch.undo()) };
};

const updateDraftTableData = (
  draftTableData: MaybeDrafted<TableData[]>,
  updatedTableData: TableData[],
  isDelete: boolean,
) => {
  if (isDelete) {
    // eslint-disable-next-line no-param-reassign
    draftTableData = draftTableData.filter(
      (row) => !updatedTableData.some((deletedRow) => row.rowId === deletedRow.rowId),
    );
  } else {
    updatedTableData.forEach((updatedRow) => {
      const { [ClientDataFixedColumns.RowId]: rowId } = updatedRow;
      const { [ClientDataFixedColumns.Order]: originalOrder } = (draftTableData.find((row) => row.rowId === rowId) ||
        {}) as TableData;
      // eslint-disable-next-line no-param-reassign
      draftTableData = draftTableData.filter((row) => row[ClientDataFixedColumns.RowId] !== rowId);

      // Use original order if the updated row doesn't have an order
      const newOrder =
        updatedRow[ClientDataFixedColumns.Order] === undefined
          ? originalOrder
          : updatedRow[ClientDataFixedColumns.Order];

      // Find the index of the first row that has an order greater than the updated row
      const insertIndex = draftTableData.findIndex((row) => {
        const [rowOrder, updatedRowOrder] = [row?.order, newOrder].map((order) =>
          order !== undefined && order !== null ? new Decimal65(order as string) : new Decimal65(0),
        );
        return rowOrder.greaterThan(updatedRowOrder);
      });
      // If insertIndex is -1, the row is being dragged to the end of the table
      if (insertIndex === -1) draftTableData.push(updatedRow);
      else draftTableData.splice(insertIndex, 0, updatedRow);
    });
  }
  return draftTableData;
};

/**
 * Updates the draft pricing sheet with the updated table data
 *
 * @param draftPricingSheet draft pricing sheet to update
 * @param updatedTableData updated table data
 * @returns updated draft pricing sheet
 */
const updateDraftPricingSheet = (
  pricingTab: string,
  gridViewType: GridViewType,
  draftPricingSheet: MaybeDrafted<PricingSheet>,
  updatedTableData: TableData[],
  isDelete: boolean,
) => {
  const updatedDraftPricingSheet = { ...draftPricingSheet, prices: [...(draftPricingSheet.prices || [])] };
  const { priceSetLabel: existingPriceSetLabel } = draftPricingSheet;

  updatedTableData.forEach((updatedRow) => {
    const { priceSetLabel = existingPriceSetLabel } = updatedRow;

    const { prices } = updatedDraftPricingSheet;
    const priceIndex = prices.findIndex((row) => row[ClientDataFixedColumns.RowId] === updatedRow.rowId);
    const price = prices[priceIndex];

    if (priceSetLabel !== existingPriceSetLabel) {
      updatedDraftPricingSheet.priceSetLabel = `${priceSetLabel}`;
    }

    let newPrice = { ...(price || {}), ...updatedRow };
    if (price) {
      if (isDelete) {
        prices.splice(priceIndex, 1);
        return;
      }
      prices.splice(priceIndex, 1, newPrice);
    } else {
      const dimensions = getPricingSheetDimensions(pricingTab as PricingTab, gridViewType);
      const { y } = dimensions;
      const x = dimensions.x === PricingSheetDimension.Region ? PricingSheetDimension.Width : dimensions.x;

      const [xValue, yValue] = [x, y].map((axis) => Number(newPrice[axis]));

      const position = prices.findIndex((p) => (p[x] || 0) > xValue || (xValue === p[x] && (p[y] || 0) > yValue));
      newPrice = { ...newPrice, [x]: xValue, [y]: yValue, hidden: {}, order: `${newPrice.order}` };
      if (position >= 0) {
        prices.splice(position, 0, newPrice);
      } else {
        prices.push(newPrice);
      }
    }
  });
  return updatedDraftPricingSheet;
};

/**
 * Updates the draft pricing sheet branch diff with the updated table data
 *
 * @param table table which the updated rows belong to
 * @param updatedRows updated table data rows
 * @param originalPricingSheet original pricing sheet
 * @param branchDiffQueryArgs branch diff query arguments
 * @param fetchPromises array of fetch promises
 * @param patches array of patches for updating the cache
 * @param state the app state
 * @param dispatch the Redux dispatch function
 * @returns whether changes exist in the table following the update
 */
const updateDraftPricingSheetBranchDiff = (
  table: string,
  updatedRows: TableData[],
  originalPricingSheet: PricingSheet | undefined,
  branchDiffQueryArgs: Record<string, any>,
  fetchPromises: Promise<void>[],
  patches: PatchCollection[],
  state: RootState<any, any, 'clientDataApi'> & RootState<any, any, 'pricingApi'> & AppState,
  dispatch: ThunkDispatch<any, any, AnyAction>,
): boolean => {
  let changesExist = false;

  const fetchingGetClientDataBranchDiffPromise =
    state.clientData.fetchingEndpoints[getEndpointCacheKey('getClientDataBranchDiff', branchDiffQueryArgs)];
  if (fetchingGetClientDataBranchDiffPromise) fetchPromises.push(fetchingGetClientDataBranchDiffPromise);

  const { data: originalBranchDiff = [] } = clientDataApi.endpoints.getClientDataBranchDiff.select(
    branchDiffQueryArgs as any,
  )(state);

  patches.push(
    dispatch(
      clientDataApi.util.updateQueryData('getClientDataBranchDiff', branchDiffQueryArgs as any, () => {
        let { changes: newTableDiffChanges } = originalBranchDiff.find((diff) => diff.table === table) || {
          table,
          changes: [],
        };

        const { priceSetLabel: existingPriceSetLabel = '' } = originalPricingSheet || {};
        updatedRows.forEach((updatedRow) => {
          const price = originalPricingSheet?.prices?.find(
            (row) => row[ClientDataFixedColumns.RowId] === updatedRow[ClientDataFixedColumns.RowId],
          ) as TableData | undefined;
          newTableDiffChanges = getUpdatedTableDataDiff(
            newTableDiffChanges,
            price ? { ...price, priceSetLabel: existingPriceSetLabel } : null,
            {
              ...(price || {}),
              priceSetLabel: existingPriceSetLabel,
              ...updatedRow,
            },
          );

          const rowDiff = newTableDiffChanges.find(
            (diff) => diff[ClientDataFixedColumns.RowId] === updatedRow[ClientDataFixedColumns.RowId],
          );
          if (!changesExist && rowDiff) {
            changesExist = Object.keys(updatedRow).some((key) => {
              if (!(Object.values(PriceColumn) as string[]).includes(key)) return false;
              const columnDiff = getRowDiffColumnToFromValues(rowDiff, key || '');
              if (!columnDiff) return false;
              return areDiffValuesDifferent(columnDiff.to, columnDiff.from) || rowDiff?.diffType === DoltDiffType.Added;
            });
          }
        });

        const newTablesDiff = [
          ...originalBranchDiff.filter((diff) => diff.table !== table),
          { table, changes: newTableDiffChanges },
        ];
        return newTablesDiff;
      }),
    ),
  );
  return changesExist;
};

export const updateClientDataGridDataCache = async (
  {
    dataType,
    clientId,
    groupId,
    branch,
    data,
    metadata,
    user,
  }: {
    dataType: ClientDataType;
    clientId: string;
    branch: ClientDataBranch;
    data: GridData;
    metadata: {
      [table: string]: CellMetadata[];
    } | null;
    user: User | undefined;
    groupId: string;
  },
  isDelete: boolean,
  dispatch: ThunkDispatch<any, any, AnyAction>,
  state: RootState<any, any, 'clientDataApi'> & RootState<any, any, 'pricingApi'> & AppState,
  reapplyAfterFetch: boolean,
) => {
  const fetchPromises: Promise<void>[] = [];
  const patches: PatchCollection[] = [];

  if (branch === ClientDataBranch.ClientUpdate) {
    updateComponentCategoryItemsCache({ groupId, clientId, data, fetchPromises, patches }, isDelete, dispatch, state);
  }

  Object.keys(data).forEach((table) => {
    const clientDataTableDataQueryArgs = {
      dataType,
      branch,
      clientId,
      groupId,
      table,
    };
    const fetchingGetClientDataTableDataPromise =
      state.clientData.fetchingEndpoints[getEndpointCacheKey('getClientDataTableData', clientDataTableDataQueryArgs)];
    if (fetchingGetClientDataTableDataPromise) fetchPromises.push(fetchingGetClientDataTableDataPromise);

    const { data: originalTableData } =
      clientDataApi.endpoints.getClientDataTableData.select(clientDataTableDataQueryArgs)(state);

    // If we don't have data cached for this specific table we can skip the cache update
    if (originalTableData) {
      const updatedRows = data[table];
      patches.push(
        dispatch(
          clientDataApi.util.updateQueryData('getClientDataTableData', clientDataTableDataQueryArgs, (draft) =>
            updateDraftTableData(draft, updatedRows, isDelete),
          ),
        ),
      );
      const clientDataBranchTableDiffQueryArgs = {
        dataType,
        branch,
        clientId,
        table,
      };

      const fetchingGetClientDataBranchTableDiffPromise =
        state.clientData.fetchingEndpoints[
          getEndpointCacheKey('getClientDataBranchTableDiff', clientDataBranchTableDiffQueryArgs)
        ];
      if (fetchingGetClientDataBranchTableDiffPromise) fetchPromises.push(fetchingGetClientDataBranchTableDiffPromise);

      let selectedTableDataDiff: ClientDataTableRowDiff[] = [];
      patches.push(
        dispatch(
          clientDataApi.util.updateQueryData(
            'getClientDataBranchTableDiff',
            clientDataBranchTableDiffQueryArgs,
            (draft) => {
              let newDiff = [...draft];
              updatedRows.forEach((updatedRow) => {
                const originalRow = originalTableData.find((row) => row.rowId === updatedRow.rowId) || null;
                if (isDelete) {
                  if (originalRow) {
                    newDiff = getUpdatedTableDataDiff(newDiff, originalRow, null);
                  }
                } else {
                  newDiff = getUpdatedTableDataDiff(newDiff, originalRow, updatedRow);
                }
              });
              selectedTableDataDiff = newDiff;
              return newDiff;
            },
          ),
        ),
      );

      const { data: clientTableColumns = {} } = clientDataApi.endpoints.getClientDataTablesColumns.select({
        dataType,
        clientId,
      })(state);

      patches.push(
        dispatch(
          clientDataApi.util.updateQueryData('getClientDataBranches', { dataType, clientId, groupId }, (draft) =>
            getUpdatedActiveBranches(
              draft,
              branch,
              selectedTableDataDiff,
              clientTableColumns[table],
              table,
              user ? user.name : '',
            ),
          ),
        ),
      );
    }

    const {
      viewer: { selectedPricingTabId: pricingTab = '', selectedTabId, selectedClientId },
      pricing: {
        sizeBased: { selectedCategoryKey: category = '', selectedPricingSheetId: sizeBasedPricingSheetId = '' },
        base: { selectedPricingSheetId: basePricingSheetId = '' },
      },
      currentUser: {
        preferences: { [UserPreference.PricingBasePreferences]: pricingBasePreferences = { gridViewType: {} } } = {},
      },
    } = state;
    let { [groupId]: { [selectedTabId || selectedClientId || '']: gridViewType } = {} } =
      pricingBasePreferences.gridViewType;
    if (!gridViewType) {
      gridViewType = isCarportView(clientId) ? GridViewType.Grid : GridViewType.List;
    }

    if (branch === ClientDataBranch.Pricing) {
      const updatedRows = data[table];

      const fetchingGetClientPricingSheetsPromise =
        state.pricing.fetchingEndpoints[getEndpointCacheKey('getClientPricingSheets', { clientId, groupId, branch })];
      if (fetchingGetClientPricingSheetsPromise) fetchPromises.push(fetchingGetClientPricingSheetsPromise);

      patches.push(
        dispatch(
          pricingApi.util.updateQueryData('getClientPricingSheets', { clientId, groupId, branch }, (draft) => {
            if (draft) {
              const pricingSheetIndex = draft.findIndex((row) => row.id === basePricingSheetId);
              const pricingSheet = draft[pricingSheetIndex];

              if (!pricingSheet) return draft;
              const updatedPricingSheet = updateDraftPricingSheet(
                pricingTab,
                gridViewType,
                pricingSheet,
                updatedRows,
                isDelete,
              );
              draft.splice(pricingSheetIndex, 1, updatedPricingSheet);
            }

            return draft;
          }),
        ),
      );

      const { data: originalPricingSheets = [] } = pricingApi.endpoints.getClientPricingSheets.select({
        clientId,
        groupId,
        branch,
      })(state);
      const originalPricingSheet = originalPricingSheets.find((row) => row.id === basePricingSheetId);
      updateDraftPricingSheetBranchDiff(
        table,
        updatedRows,
        originalPricingSheet,
        {
          clientId,
          dataType: ClientDataType.Supplier,
          branch,
          tables: [getPricingSheetTable(clientId, pricingTab, undefined) || ''],
        },
        fetchPromises,
        patches,
        state,
        dispatch,
      );
    }

    if (branch === ClientDataBranch.PricingSizeBased) {
      const updatedRows = data[table];

      const fetchingGetSizeBasedPricingSheetPricesPromise =
        state.pricing.fetchingEndpoints[
          getEndpointCacheKey('getSizeBasedPricingSheetPrices', {
            groupId,
            clientId,
            category,
            id: sizeBasedPricingSheetId,
            branch,
          })
        ];
      if (fetchingGetSizeBasedPricingSheetPricesPromise)
        fetchPromises.push(fetchingGetSizeBasedPricingSheetPricesPromise);

      patches.push(
        dispatch(
          pricingApi.util.updateQueryData(
            'getSizeBasedPricingSheetPrices',
            { groupId, clientId, category: category as SizeBasedCategoryKey, id: sizeBasedPricingSheetId, branch },
            (draft) => updateDraftPricingSheet(pricingTab, GridViewType.Grid, draft, updatedRows, isDelete),
          ),
        ),
      );

      patches.push(
        dispatch(
          pricingApi.util.updateQueryData(
            'getSizeBasedCategoryPricingSheets',
            { groupId, clientId, category: category as SizeBasedCategoryKey, branch },
            (draft) => {
              const pricingSheetIndex = draft.findIndex((sheet) => sheet.id === sizeBasedPricingSheetId);
              const selectedPricingSheet = draft[pricingSheetIndex];
              if (!selectedPricingSheet) return draft;

              const { priceSetLabel: existingPriceSetLabel } = selectedPricingSheet;
              const updatedPriceSetLabel = updatedRows.find((row) => row.priceSetLabel)?.priceSetLabel;
              if (updatedPriceSetLabel && updatedPriceSetLabel !== existingPriceSetLabel) {
                return [
                  ...draft.slice(0, pricingSheetIndex),
                  { ...selectedPricingSheet, priceSetLabel: `${updatedPriceSetLabel}`, changes: true },
                  ...draft.slice(pricingSheetIndex + 1),
                ];
              }
              return draft;
            },
          ),
        ),
      );

      const { data: originalPricingSheet } = pricingApi.endpoints.getSizeBasedPricingSheetPrices.select({
        groupId,
        clientId,
        category: category as SizeBasedCategoryKey,
        id: sizeBasedPricingSheetId,
        branch,
      })(state);
      const changesExist = updateDraftPricingSheetBranchDiff(
        table,
        updatedRows,
        originalPricingSheet,
        {
          clientId,
          dataType: ClientDataType.Supplier,
          branch,
          tables: [getPricingSheetTable(clientId, pricingTab, category as SizeBasedCategoryKey) || ''],
        },
        fetchPromises,
        patches,
        state,
        dispatch,
      );

      patches.push(
        dispatch(
          pricingApi.util.updateQueryData('getSizeBasedCategories', { groupId, clientId, branch }, (draft) => {
            const newDraft = [...draft];
            const insertIndex = draft.findIndex(({ key }) => key === category);
            if (insertIndex === -1) return newDraft;
            newDraft.splice(insertIndex, 1, {
              ...draft[insertIndex],
              changes: changesExist,
            });
            return newDraft;
          }),
        ),
      );
    }
  });

  const clientDataQueryArgs = {
    dataType,
    branch,
    groupId,
    clientId,
  };
  const fetchingGetClientDataPromise =
    state.clientData.fetchingEndpoints[getEndpointCacheKey('getClientData', clientDataQueryArgs)];
  if (fetchingGetClientDataPromise) fetchPromises.push(fetchingGetClientDataPromise);
  const { data: originalSearchClientData } = clientDataApi.endpoints.getClientData.select(clientDataQueryArgs)(state);
  if (originalSearchClientData) {
    patches.push(
      dispatch(
        clientDataApi.util.updateQueryData('getClientData', clientDataQueryArgs, (draft) => {
          Object.keys(data).forEach((table) => {
            const updatedRows = data[table];
            // eslint-disable-next-line no-param-reassign
            draft[table] = updateDraftTableData(draft[table], updatedRows, isDelete);
          });
          return draft;
        }),
      ),
    );
  }

  if (metadata) {
    Object.keys(metadata).forEach((table) => {
      const fetchingGetClientDataCellMetadataPromise =
        state.clientData.fetchingEndpoints[
          getEndpointCacheKey('getClientDataCellMetadata', { dataType, branch, clientId, table, groupId })
        ];
      if (fetchingGetClientDataCellMetadataPromise) fetchPromises.push(fetchingGetClientDataCellMetadataPromise);

      const updatedMetadataRows = metadata[table];
      patches.push(
        dispatch(
          clientDataApi.util.updateQueryData(
            'getClientDataCellMetadata',
            { dataType, branch, clientId, table, groupId },
            (draft) => [
              ...draft.filter(
                (draftMetadata) =>
                  draftMetadata.clientId !== clientId ||
                  draftMetadata.table !== table ||
                  !updatedMetadataRows.some((updatedMetadata) => draftMetadata.rowId === updatedMetadata.rowId),
              ),
              ...updatedMetadataRows.map((update) => ({
                rowId: update.rowId,
                table,
                clientId,
                metadata: update.metadata,
              })),
            ],
          ),
        ),
      );
    });

    const cellMetadataDiffQueryArgs = {
      dataType,
      branch,
      clientId,
      table: CELL_METADATA_TABLE,
    };

    const fetchingGetClientDataBranchTableDiffPromise =
      state.clientData.fetchingEndpoints[
        getEndpointCacheKey('getClientDataBranchTableDiff', cellMetadataDiffQueryArgs)
      ];
    if (fetchingGetClientDataBranchTableDiffPromise) fetchPromises.push(fetchingGetClientDataBranchTableDiffPromise);

    const updatedMetadataRows = flatMap(Object.keys(metadata).map((table) => metadata[table]));
    patches.push(
      dispatch(
        clientDataApi.util.updateQueryData('getClientDataBranchTableDiff', cellMetadataDiffQueryArgs, (draft) => {
          let newDiff = [...draft];
          updatedMetadataRows.forEach((updatedRow) => {
            const { data: originalMetadataTableData = [] } = clientDataApi.endpoints.getClientDataCellMetadata.select({
              dataType,
              branch,
              clientId,
              table: updatedRow.table,
              groupId,
            })(state);
            const originalRow = originalMetadataTableData.find((row) => row.rowId === updatedRow.rowId) || null;
            if (isDelete) {
              if (originalRow) {
                newDiff = getUpdatedTableDataDiff(newDiff, originalRow, null);
              }
            } else {
              newDiff = getUpdatedTableDataDiff(newDiff, originalRow, updatedRow);
            }
          });
          return newDiff;
        }),
      ),
    );
  }

  /*
    Some of the caches we are updating are fetching new data from the server, so we'll have to wait these fetches to resolve
    as they will replace the cached data and reapply the cache update later.
    We store the fetch promises in the ClientDataState because 'clientDataApi.util.getRunningQueriesThunk' does not return queries refetches
    that comes from events like "refetchOnFocus" - Might be an RTK bug.
  */
  if (reapplyAfterFetch && fetchPromises.length > 0) {
    await Promise.all(fetchPromises);
    patches.push(
      (await updateClientDataGridDataCache(
        { dataType, clientId, branch, data, metadata, user, groupId },
        isDelete,
        dispatch,
        state,
        false,
      )) as PatchCollection,
    );
  }

  return { undo: () => patches.forEach((patch) => patch.undo()) };
};

export const {
  useGetClientDataTablesQuery,
  useGetClientDataTablesColumnsQuery,
  useGetClientDataAllTableMetadataQuery,
  useGetBranchDiffMergesQuery,
  useGetClientDataTableMetadataQuery,
  useGetClientDataCellMetadataQuery,
  useUpdateClientDataCellMetadataMutation,
  useGetClientDataBranchTableDiffQuery,
  useGetClientDataBranchDiffQuery,
  useGetClientDataQuery,
  useGetClientDataTableDataQuery,
  useUpdateClientDataMutation,
  useDeleteClientDataMutation,
  usePublishClientDataMutation,
  useGetClientDataBranchesQuery,
  useAddBranchMutation,
  useDeleteBranchMutation,
  useGetClientDataBranchLogQuery,
  useGetSupplierVendorsListQuery,
  useGetSuppliersQuery,
  useGetVendorsQuery,
  useGetVendorDataQuery,
  useGetTemplateVendorsQuery,
  usePublishVendorMutation,
  useSaveBranchMetadataMutation,
  useGetClientPublishedVersionsQuery,
  useCreateDatasetMutation,
  useGetClientVersionsQuery,
  useRollbackDatasetMutation,
  useGetClientDataBranchChangesSummaryQuery,
  useGetCellHistoryQuery,
  useGetBranchHistoryQuery,
  useGetOpenBranchesQuery,
  useGetClientDataTablesDataQuery,
  useGetVisibleStylesQuery,
} = clientDataApi;
