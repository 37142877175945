/* eslint-disable react/no-danger */
import React, { useEffect, useMemo, useState } from 'react';
import { Card, CardContent, FormGroup, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useTranslation } from 'react-i18next';
import {
  PricingSurchargeCalculation,
  PricingSurchargeCalculationType,
  PricingSurchargeConditionType,
} from '@idearoom/types';
import { debounce } from 'lodash';
import { AppState } from '../types/AppState';
import {
  MaximumDigits,
  PricingSurchargeErrorType,
  defaultSurchargeCalculation,
  SurchargeCalculationProperty,
  SurchargeRuleProperty,
  SurchargeUpdateProperty,
} from '../constants/PricingSurcharge';
import { PricingSurchargeRule } from './PricingSurchargeRule';
import { countUniqueLabels, parseRoundTo, getCurrencySymbol } from '../utils/pricingUtils';
import { isCarportView } from '../utils/clientIdUtils';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';
import { usePricingRepo } from '../hooks/usePricingRepo';
import { PricingSurchargeSelectableChip } from './PricingSurchargeSelectableChip';
import { updateSurchargeProperty } from '../ducks/pricingSlice';

const useStyles = makeStyles({
  card: {
    backgroundColor: '#f0f0f0',
    width: '100%',
  },
  textField: {
    '& > *': {
      width: '15ch',
    },
    marginRight: '10px',
  },
  buttonCardContent: {
    '&:last-child': {
      padding: '5px 15px',
    },
    padding: '5px 15px',
  },
  addIcon: {
    color: 'green',
  },
  onClick: {
    marginTop: '10px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  spacing: {
    marginTop: '10px',
  },
});

export const PricingSurchargeAmount: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const {
    viewer: { currency },
    clientData: { clientId },
    pricing: {
      surcharge: { surchargeErrors: validationErrors },
    },
  } = useAppSelector((state: AppState) => state);

  const { selectedPricingSurcharge: selectedSurcharge } = usePricingRepo({
    useBranches: true,
    useSelectedPricingSurcharge: true,
  });

  const { pricingSurchargeVaryConditions: conditionOptions } = usePricingRepo({
    usePricingSurchargeVaryConditions: true,
  });

  const { key: pricingSelection, calculation, rules } = selectedSurcharge;
  const { percentChange, amountChange, roundTo } = calculation;

  const [percentChangeValue, setPercentChangeValue] = useState(
    percentChange || defaultSurchargeCalculation.percentChange,
  );
  const [amountChangeValue, setAmountChangeValue] = useState(amountChange || defaultSurchargeCalculation.amountChange);
  const [roundToValue, setRoundToValue] = useState(roundTo || defaultSurchargeCalculation.roundTo);

  const typeText =
    pricingSelection === PricingSurchargeCalculationType.Surcharge
      ? t(I18nKeys.PricingAmountSurchargeType)
      : t(I18nKeys.PricingAmountChangeType);

  const handleCalculationValueChange = (
    property: SurchargeCalculationProperty,
    newValue: string | undefined,
    calc: PricingSurchargeCalculation,
    percentage = false,
  ): void => {
    let valueAsFloat;
    if (newValue !== undefined && newValue !== '') {
      if (percentage) {
        const valueAsString =
          newValue.length > MaximumDigits.PercentChange ? newValue.slice(0, MaximumDigits.PercentChange) : newValue;
        valueAsFloat = parseFloat(valueAsString) / 100;
      } else {
        valueAsFloat =
          property === SurchargeCalculationProperty.RoundTo ? parseRoundTo(newValue) : parseFloat(newValue);
      }
    }
    dispatch(
      updateSurchargeProperty([
        {
          property: SurchargeUpdateProperty.Calculation,
          value: {
            property,
            value: valueAsFloat || 0,
          },
        },
      ]),
    );
  };

  const debouncedCalculationValueChangeHandler = useMemo(() => debounce(handleCalculationValueChange, 400), []);

  // Stop the invocation of the debounced function after unmounting
  useEffect(
    () => () => {
      debouncedCalculationValueChangeHandler.cancel();
    },
    [],
  );

  const handleChipSelection = (value: string, selected: boolean): void => {
    const type = value as PricingSurchargeConditionType;
    dispatch(
      updateSurchargeProperty([
        {
          property: SurchargeUpdateProperty.Rules,
          value: {
            property: SurchargeRuleProperty.Conditions,
            // If selected, remove from all rules, otherwise add to all rules
            value: selected ? { type, isDelete: true } : { type, isNew: true },
          },
        },
      ]),
    );
  };

  const [pricingConditionOptions, setPricingConditionOptions] = useState(conditionOptions);
  useEffect(() => {
    let filteredConditionOptions = conditionOptions.filter(
      (option) => countUniqueLabels(option.options) > 1 || option.value === PricingSurchargeConditionType.Subtotal,
    );
    if (isCarportView(clientId)) {
      filteredConditionOptions = filteredConditionOptions.filter(
        (option) =>
          option.value !== PricingSurchargeConditionType.Style &&
          option.value !== PricingSurchargeConditionType.Gauge &&
          option.value !== PricingSurchargeConditionType.Length,
      );
      if (pricingSelection === PricingSurchargeCalculationType.Surcharge) {
        filteredConditionOptions = filteredConditionOptions.filter(
          (option) => option.value !== PricingSurchargeConditionType.Region,
        );
        if (filteredConditionOptions.length !== pricingConditionOptions.length) {
          setPricingConditionOptions(filteredConditionOptions);
        }
        if (selectedSurcharge.rules.some((rule) => rule.conditions.some((condition) => condition.type === 'region'))) {
          dispatch(
            updateSurchargeProperty([
              {
                property: SurchargeUpdateProperty.Rules,
                value: {
                  property: SurchargeRuleProperty.Conditions,
                  value: { type: PricingSurchargeConditionType.Region, isDelete: true },
                },
              },
            ]),
          );
        }
      } else if (pricingSelection === PricingSurchargeCalculationType.LineItem) {
        filteredConditionOptions = filteredConditionOptions.filter(
          (option) =>
            option.value !== PricingSurchargeConditionType.State &&
            option.value !== PricingSurchargeConditionType.Subtotal,
        );
        if (filteredConditionOptions.length !== pricingConditionOptions.length) {
          setPricingConditionOptions(filteredConditionOptions);
          dispatch(
            updateSurchargeProperty([
              {
                property: SurchargeUpdateProperty.Rules,
                value: {
                  property: SurchargeRuleProperty.Conditions,
                  value: { type: PricingSurchargeConditionType.State, isDelete: true },
                },
              },
            ]),
          );
        }
      }
    } else {
      filteredConditionOptions = filteredConditionOptions.filter(
        (option) => option.value !== PricingSurchargeConditionType.State,
      );
      if (filteredConditionOptions.length !== pricingConditionOptions.length) {
        setPricingConditionOptions(filteredConditionOptions);
      }
    }
  }, [pricingSelection, conditionOptions, clientId, selectedSurcharge]);

  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          <Typography>{t(I18nKeys.PricingAmountDefault, { type: typeText })}</Typography>
          <FormGroup row className={classes.spacing}>
            <TextField
              className={classes.textField}
              variant="standard"
              label={t(I18nKeys.PricingPercentChange)}
              type="number"
              value={
                percentChangeValue && percentChangeValue < 1
                  ? parseFloat((percentChangeValue * 100).toFixed(7)).toString()
                  : percentChangeValue
              }
              onChange={(event): void => {
                setPercentChangeValue(Number(event.target.value) / 100);
                debouncedCalculationValueChangeHandler(
                  SurchargeCalculationProperty.PercentChange,
                  event.target.value,
                  calculation,
                  true,
                );
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
              }}
              error={
                validationErrors.some(
                  (error) =>
                    error.ruleId === -1 &&
                    error.errorType === PricingSurchargeErrorType.Calculation &&
                    error.type === SurchargeCalculationProperty.PercentChange,
                )
                  ? true
                  : undefined
              }
            />
            {pricingSelection && pricingSelection === PricingSurchargeCalculationType.Surcharge && (
              <TextField
                className={classes.textField}
                variant="standard"
                label={t(I18nKeys.PricingAmountChange)}
                type="number"
                value={amountChangeValue}
                onChange={(event): void => {
                  setAmountChangeValue(Number(event.target.value));
                  debouncedCalculationValueChangeHandler(
                    SurchargeCalculationProperty.AmountChange,
                    event.target.value,
                    calculation,
                  );
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{getCurrencySymbol(currency)}</InputAdornment>,
                }}
                error={
                  validationErrors.some(
                    (error) =>
                      error.ruleId === -1 &&
                      error.errorType === PricingSurchargeErrorType.Calculation &&
                      error.type === SurchargeCalculationProperty.AmountChange,
                  )
                    ? true
                    : undefined
                }
              />
            )}
            <TextField
              className={classes.textField}
              variant="standard"
              label={t(I18nKeys.PricingRoundToNearest)}
              type="number"
              value={roundToValue}
              onChange={(event): void => {
                setRoundToValue(Number(event.target.value));
                debouncedCalculationValueChangeHandler(
                  SurchargeCalculationProperty.RoundTo,
                  event.target.value,
                  calculation,
                );
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">{getCurrencySymbol(currency)}</InputAdornment>,
              }}
              error={
                validationErrors.some(
                  (error) =>
                    error.ruleId === -1 &&
                    error.errorType === PricingSurchargeErrorType.Calculation &&
                    error.type === SurchargeCalculationProperty.RoundTo,
                )
                  ? true
                  : undefined
              }
            />
          </FormGroup>
        </CardContent>
      </Card>
      <Grid container className={classes.spacing} direction="row" spacing={2} alignItems="center">
        <Grid item>
          <Typography>{t(I18nKeys.PricingAmountVaryBy, { type: typeText })}</Typography>
        </Grid>
        <Grid item>
          <Grid container direction="row" spacing={1}>
            {pricingConditionOptions.map((option) => (
              <Grid key={`${option.value}-grid`} item>
                <PricingSurchargeSelectableChip
                  chip={option}
                  selected={rules.some((rule) => rule.conditions.some((condition) => condition.type === option.value))}
                  handleChipSelection={handleChipSelection}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <PricingSurchargeRule />
      {rules.length > 0 && (
        <Grid
          container
          className={classes.onClick}
          onClick={() => {
            dispatch(updateSurchargeProperty([{ property: SurchargeUpdateProperty.Rules, value: { isNew: true } }]));
          }}
        >
          <Card className={classes.card}>
            <CardContent className={classes.buttonCardContent}>
              <Grid container direction="row" spacing={2} alignItems="center">
                <Grid item>
                  <AddCircleIcon className={classes.addIcon} />
                </Grid>
                <Grid item>
                  <Typography>{t(I18nKeys.PricingAmountAddRule, { type: typeText })}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
    </>
  );
};
