import React from 'react';
import { Box, Grid, Checkbox, FormControl, TextField, FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { SomeAllowed } from './SomeAllowed';
import { SiteDetail, SiteDetailField } from '../types/ClientData';
import { SiteDetailDataFields, informationPanelFieldsMap } from '../constants/VendorData';
import { openDialog } from '../ducks/dialogSlice';
import { Dialogs } from '../constants/Dialogs';
import { sanitizeInput } from '../utils/inputUtils';
import { disabledSiteDetailField } from '../utils/vendorDataUtils';
import { useAppDispatch } from '../hooks';
import { I18nKeys } from '../constants/I18nKeys';

// https://www.figma.com/file/AqMw0mh5Hk4L69sFb6loNh/10308%2C-19253---What%E2%80%99s-New-for-Sales-Tools-(to-advertise-Eagle-digital-signature)

interface Props {
  data?: SiteDetail;
  vendorPropChange: Function;
  isIdeaRoomUser: boolean;
  selectedGroupId: string;
}

const FieldGrid = styled(Grid)({
  padding: '8px',
});

export const SitesInformationalPanel: React.FC<Props> = ({
  data = {},
  vendorPropChange,
  isIdeaRoomUser = false,
  selectedGroupId,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const openAllowedHTMLTagsDialog = (): void => {
    dispatch(openDialog({ dialog: Dialogs.AllowedHtmlTags }));
  };

  return (
    <Grid container>
      {Object.entries(informationPanelFieldsMap).map(([key, field]) => {
        const { props: gridProps = {}, i18nKey } = field as SiteDetailField;
        if ([SiteDetailDataFields.ShowInformationalPanel].includes(key as SiteDetailDataFields)) {
          return (
            <FieldGrid item xs={gridProps.xs || 12} key={key}>
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      name={key}
                      value={data.showInformationalPanel || false}
                      checked={data.showInformationalPanel || false}
                      onChange={(e): void => vendorPropChange(e.target.name, e.target.checked)}
                      color="primary"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      disabled={disabledSiteDetailField(
                        key as SiteDetailDataFields,
                        data,
                        isIdeaRoomUser,
                        selectedGroupId,
                      )}
                    />
                  }
                  label={t(i18nKey)}
                />
              </Box>
            </FieldGrid>
          );
        }
        return (
          <FieldGrid item xs={gridProps.xs || 12} key={key}>
            <FormControl fullWidth>
              <TextField
                disabled={disabledSiteDetailField(key as SiteDetailDataFields, data, isIdeaRoomUser, selectedGroupId)}
                label={t(i18nKey)}
                name={key}
                value={data[key as SiteDetailDataFields] || ''}
                onChange={(e: any): void => vendorPropChange(e.target.name, e.target.value)}
                variant="filled"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...([SiteDetailDataFields.InformationPanelMessage].includes(key as SiteDetailDataFields)
                  ? {
                      rows: '3',
                      multiline: true,
                      onBlur: (e): void => vendorPropChange(e.target.name, sanitizeInput(e.target.value)),
                    }
                  : {})}
              />
            </FormControl>
            {[SiteDetailDataFields.InformationPanelMessage].includes(key as SiteDetailDataFields) && (
              <SomeAllowed onClick={openAllowedHTMLTagsDialog}>{t(I18nKeys.SomeHtmlTagsAllowed)}</SomeAllowed>
            )}
          </FieldGrid>
        );
      })}
    </Grid>
  );
};
