import { Grid, FormControl, TextField, FormControlLabel, Checkbox, MenuItem, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { SiteDetail, SiteDetailField } from '../types/ClientData';
import { floorplanFieldsMap, SiteDetailDataFields } from '../constants/VendorData';
import { disabledSiteDetailField, hideSiteDetailField, ideaRoomOnlySiteDetailField } from '../utils/vendorDataUtils';
import { LoadingSelect } from './LoadingSelect';
import { I18nKeys } from '../constants/I18nKeys';
import {
  floorplanComponentStyleOptions,
  floorplanFrontDirectionOptions,
  floorplanGridStyleOptions,
  floorplanLabelingMethodOptions,
  floorplanMeasurementArrowheadStyleOptions,
} from '../constants/Floorplan';
import { SiteInputLabel } from './SiteInputLabel';
import { IdeaRoomOnlyIndicator } from './IdeaRoomOnlyIndicator';

const useStyles = makeStyles({
  inputLabel: {
    marginTop: '16px',
  },
  fieldContainer: {
    padding: '8px',
  },
});

interface Props {
  data?: SiteDetail;
  vendorPropChange: Function;
  isIdeaRoomUser: boolean;
  selectedGroupId: string;
}

export const SitesFloorplanOptions: React.FC<Props> = ({
  data = {},
  vendorPropChange,
  isIdeaRoomUser = false,
  selectedGroupId,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Grid container>
      {Object.entries(floorplanFieldsMap)
        .filter(([key]) => !hideSiteDetailField(key as SiteDetailDataFields, data, isIdeaRoomUser, selectedGroupId))
        .map(([key, field], i, arr) => {
          const { props: vendorProps = {}, i18nKey } = field as SiteDetailField;
          const checkboxFields = [
            SiteDetailDataFields.EmailFloorPlan,
            SiteDetailDataFields.UseFloorplanFullPage,
            SiteDetailDataFields.UseFloorPlanGridDimensions,
            SiteDetailDataFields.UseFloorPlanGridDimensionsLegend,
            SiteDetailDataFields.FloorplanShowFlowerbox,
            SiteDetailDataFields.FloorplanShowPorchBoardDirection,
            SiteDetailDataFields.FloorplanShowSideTexts,
          ];
          if (checkboxFields.includes(key as SiteDetailDataFields)) {
            return (
              <Grid
                item
                xs={vendorProps.xs || 12}
                className={classes.fieldContainer}
                key={key}
                style={{
                  ...(i && checkboxFields.includes((arr as any)[i - 1][0]) ? { paddingTop: '0px' } : {}),
                  ...(i !== arr.length - 1 && checkboxFields.includes((arr as any)[i + 1][0])
                    ? { paddingBottom: '0px' }
                    : {}),
                }}
              >
                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={key}
                        checked={
                          data[key as SiteDetailDataFields] === undefined
                            ? (!!vendorProps.defaultValue as boolean)
                            : (!!data[key as SiteDetailDataFields] as boolean)
                        }
                        disabled={disabledSiteDetailField(
                          key as SiteDetailDataFields,
                          data,
                          isIdeaRoomUser,
                          selectedGroupId,
                        )}
                        onChange={(e: any): void => vendorPropChange(e.target.name, e.target.checked)}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                    label={
                      <>
                        {ideaRoomOnlySiteDetailField(
                          key as SiteDetailDataFields,
                          data,
                          isIdeaRoomUser,
                          selectedGroupId,
                        ) && <IdeaRoomOnlyIndicator />}
                        <Typography style={{ display: 'inline' }}>{t(i18nKey)}</Typography>
                      </>
                    }
                  />
                </FormControl>
              </Grid>
            );
          }
          if (
            [
              SiteDetailDataFields.FloorplanLabelingMethod,
              SiteDetailDataFields.FloorplanGridStyle,
              SiteDetailDataFields.FloorplanMeasurementArrowheadStyle,
              SiteDetailDataFields.FloorplanComponentStyle,
              SiteDetailDataFields.FloorplanFrontDirection,
            ].includes(key as SiteDetailDataFields)
          ) {
            let selectOptions: any[] = [];
            switch (key) {
              case SiteDetailDataFields.FloorplanLabelingMethod:
                selectOptions = floorplanLabelingMethodOptions;
                break;
              case SiteDetailDataFields.FloorplanGridStyle:
                selectOptions = floorplanGridStyleOptions;
                break;
              case SiteDetailDataFields.FloorplanMeasurementArrowheadStyle:
                selectOptions = floorplanMeasurementArrowheadStyleOptions;
                break;
              case SiteDetailDataFields.FloorplanComponentStyle:
                selectOptions = floorplanComponentStyleOptions;
                break;
              case SiteDetailDataFields.FloorplanFrontDirection:
                selectOptions = floorplanFrontDirectionOptions;
                break;
              default:
                break;
            }

            return (
              <Grid item xs={6} sm={vendorProps.sm || 6} className={classes.fieldContainer} key={key}>
                <FormControl fullWidth>
                  <SiteInputLabel
                    shrink
                    className={classes.inputLabel}
                    htmlFor={`${key}-select`}
                    i18nKey={i18nKey}
                    ideaRoomOnly={ideaRoomOnlySiteDetailField(
                      key as SiteDetailDataFields,
                      data,
                      isIdeaRoomUser,
                      selectedGroupId,
                    )}
                  />
                  <LoadingSelect
                    disabled={disabledSiteDetailField(
                      key as SiteDetailDataFields,
                      data,
                      isIdeaRoomUser,
                      selectedGroupId,
                    )}
                    alerttext={
                      disabledSiteDetailField(key as SiteDetailDataFields, data, isIdeaRoomUser, selectedGroupId)
                        ? t(I18nKeys.SitesProSubscriptionAlert)
                        : undefined
                    }
                    value={data[key as SiteDetailDataFields] || vendorProps.defaultValue}
                    onChange={(e: any): void => vendorPropChange(e.target.name, e.target.value)}
                    inputProps={{
                      displayEmpty: false,
                      name: key,
                      id: `${key}-select`,
                    }}
                    variant="filled"
                    loading={false}
                  >
                    {selectOptions.map((option: any) => (
                      <MenuItem key={option.key} value={option.key}>
                        {t(option.label)}
                      </MenuItem>
                    ))}
                  </LoadingSelect>
                </FormControl>
              </Grid>
            );
          }

          return (
            <Grid item xs={6} sm={vendorProps.sm || 6} className={classes.fieldContainer} key={key}>
              <FormControl fullWidth>
                <SiteInputLabel
                  shrink
                  className={classes.inputLabel}
                  htmlFor={`${key}-text-field`}
                  i18nKey={i18nKey}
                  ideaRoomOnly={ideaRoomOnlySiteDetailField(
                    key as SiteDetailDataFields,
                    data,
                    isIdeaRoomUser,
                    selectedGroupId,
                  )}
                />
                <TextField
                  name={key}
                  onChange={(e): void => vendorPropChange(e.target.name, e.target.value)}
                  value={
                    data[key as SiteDetailDataFields] !== undefined
                      ? data[key as SiteDetailDataFields]
                      : vendorProps.defaultValue || ''
                  }
                  type="number"
                  variant="filled"
                  disabled={disabledSiteDetailField(key as SiteDetailDataFields, data, isIdeaRoomUser, selectedGroupId)}
                />
              </FormControl>
            </Grid>
          );
        })}
    </Grid>
  );
};
