import { Checkbox, ListItemText } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import {
  PricingSurchargeConditionType,
  PricingSurchargeRule,
  PricingSurchargeVaryCondition,
  PricingSurchargeVaryConditionOption,
} from '@idearoom/types';
import { useAppDispatch } from '../hooks';
import { selectAllLabel, SurchargeRuleProperty, SurchargeUpdateProperty } from '../constants/PricingSurcharge';
import { sortObjectsByAlphanumericStrings } from '../utils/sortUtils';
import { allKeysAreSelected } from '../utils/pricingUtils';
import { PricingSurchargeKeyCondition } from '../types/PricingSurcharge';
import { updateSurchargeProperty } from '../ducks/pricingSlice';

interface Props {
  anchorEl: Element | null;
  pricingSurchargeVaryCondition?: PricingSurchargeVaryCondition;
  rule?: PricingSurchargeRule;
  onClose(): void;
}

export const PricingSurchargeKeyConditionMenu: React.FC<Props> = ({
  anchorEl,
  onClose,
  pricingSurchargeVaryCondition,
  rule,
}: Props) => {
  const dispatch = useAppDispatch();

  const handleCheckboxClicked = (
    type: PricingSurchargeConditionType,
    PricingSurchargeVaryConditionOptions: PricingSurchargeVaryConditionOption[],
    surchargeRule: PricingSurchargeRule,
    checked: boolean,
  ): void => {
    // Find condition's current selected keys
    const keys = [
      ...((surchargeRule.conditions.find((condition) => condition.type === type) as PricingSurchargeKeyCondition)
        ?.keys || []),
    ];

    if (pricingSurchargeVaryCondition) {
      PricingSurchargeVaryConditionOptions.forEach((selectedOption) => {
        // Find all keys with a matching label
        const matchingKeys = pricingSurchargeVaryCondition.options
          .filter((option) => option.label === selectedOption.label)
          .map((option) => option.key);
        // Add or remove matches
        matchingKeys.forEach((match) => {
          if (!checked && !keys.includes(match)) keys.push(match);
          else if (checked && keys.includes(match)) keys.splice(keys.indexOf(match), 1);
        });
      });
    }
    dispatch(
      updateSurchargeProperty([
        {
          property: SurchargeUpdateProperty.Rules,
          value: { id: surchargeRule.id, property: SurchargeRuleProperty.Conditions, value: { type, value: keys } },
        },
      ]),
    );
  };

  let uniqueConditionOptions: PricingSurchargeVaryConditionOption[] = [];
  let allOptionsAreSelected = false;
  if (rule && pricingSurchargeVaryCondition && pricingSurchargeVaryCondition.options) {
    // Only list options with unique labels
    uniqueConditionOptions.push(
      ...pricingSurchargeVaryCondition.options.filter(
        (option, i) => !pricingSurchargeVaryCondition.options.slice(0, i).some((o) => o.label === option.label),
      ),
    );
    uniqueConditionOptions = sortObjectsByAlphanumericStrings(uniqueConditionOptions, 'label') || [];
    allOptionsAreSelected = allKeysAreSelected(
      rule,
      pricingSurchargeVaryCondition.value as PricingSurchargeConditionType,
      uniqueConditionOptions.map((option) => option.key),
    );
  }

  return (
    <Menu
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {rule &&
        pricingSurchargeVaryCondition &&
        uniqueConditionOptions.length > 0 && [
          // (Select All) Option
          <MenuItem
            key="all"
            onClick={(): void => {
              handleCheckboxClicked(
                pricingSurchargeVaryCondition.value as PricingSurchargeConditionType,
                uniqueConditionOptions,
                rule,
                allOptionsAreSelected,
              );
            }}
          >
            <ListItemIcon>
              <Checkbox checked={allOptionsAreSelected} />
            </ListItemIcon>
            <ListItemText primary={selectAllLabel} />
          </MenuItem>,
          ...uniqueConditionOptions.map((option) => {
            const checked = allKeysAreSelected(
              rule,
              pricingSurchargeVaryCondition.value as PricingSurchargeConditionType,
              [option.key],
            );
            return (
              <MenuItem
                key={option.key}
                onClick={(): void => {
                  handleCheckboxClicked(
                    pricingSurchargeVaryCondition.value as PricingSurchargeConditionType,
                    [option],
                    rule,
                    checked,
                  );
                }}
              >
                <ListItemIcon>
                  <Checkbox checked={checked} />
                </ListItemIcon>
                <ListItemText primary={option.label} />
              </MenuItem>
            );
          }),
        ]}
    </Menu>
  );
};
