import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { PriceCalculation, PriceColumn } from '@idearoom/types';
import { Check } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../hooks';
import { Table } from './Table';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';
import { compoundCaseToTitleCase } from '../utils/stringUtils';
import { setSelectedComponentId } from '../ducks/pricingSlice';
import { openDialog } from '../ducks/dialogSlice';
import { Dialogs } from '../constants/Dialogs';
import {
  CLIENT_UPDATE_CATEGORY_MAPPING,
  DisplayColumns,
  HelperColumns,
  PricingCalculationColumns,
  componentColumnMap,
} from '../constants/PricingClientUpdate';
import { I18nKeys } from '../constants/I18nKeys';
import { areDiffValuesDifferent, getRowDiffColumnToFromValues } from '../utils/clientDataUtils';
import { ComponentCategoryItem } from '../types/PricingClientUpdate';
import {
  filterClientUpdateItems,
  formatComponentCategoryValue,
  getComponentCategoryColumns,
  getComponentCategoryItems,
  getDefaultParsedComponentPricingColumnValue,
  getMatchedPriceFromConditions,
  groupComponentCategoryItems,
  pricingVariesByRegion,
} from '../utils/pricingClientUpdateUtils';
import { useClientUpdatePricingRepo } from '../hooks/useClientUpdatePricingRepo';
import { SortProperty } from '../types/SortProperty';
import { sortRows } from '../utils/sortUtils';
import { AppState } from '../types/AppState';
import { hasDuplicateLabels } from '../utils/pricingUtils';
import { useClientDataRepo } from '../hooks/useClientDataRepo';
import { UserPreference } from '../constants/User';
import { saveUserPreferences } from '../ducks/currentUserSlice';

const useStyles = makeStyles({
  content: {
    flex: 1,
    minHeight: 0,
  },
  tableGrid: {
    height: '100%',
  },
  modified: {
    backgroundColor: '#E4A76733 !important',
  },
  cell: {
    '&:first-child': {
      paddingLeft: '32px',
    },
    '&:last-child': {
      paddingRight: '32px',
    },
  },
  componentLabel: {
    fontSize: '14px',
  },
  componentKey: {
    paddingLeft: '4px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  priceHeader: {
    textAlign: 'right',
    paddingRight: '4px',
  },
  priceCalculationCell: {
    textAlign: 'left',
    paddingLeft: '6px',
  },
  priceCell: {
    paddingRight: '26px',
    textAlign: 'right',
  },
  variesByRegionHeader: {
    textAlign: 'center',
  },
  variesByRegionCell: {
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

type Props = {
  searchValue?: string;
};

export const PricingComponentCategoryItems: React.FC<Props> = ({ searchValue = '' }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    viewer: { currency },
    pricing: {
      component: { selectedCategoryKey: categoryKey, region },
    },
    currentUser: {
      preferences: { [UserPreference.PricingComponentPreferences]: { sortState: savedSortState = [] } = {} } = {},
    },
  } = useAppSelector((state: AppState) => state);

  const { clientTableColumns } = useClientDataRepo({
    useClientTablesColumns: true,
  });
  const {
    componentCategoryItemsWithConditions = [],
    isLoadingComponentCategoryItemsWithConditions,
    clientUpdateDiffs = [],
    clientUpdateRegions: regions,
    clientUpdateStyles: styles,
  } = useClientUpdatePricingRepo({
    useClientUpdateDiffs: true,
    useComponentCategoryItemsWithConditions: true,
    useClientUpdateRegions: true,
    useClientUpdateStyles: true,
  });
  const priceColumn = regions?.find(({ regionKey }) => regionKey === region)?.priceColumn || PriceColumn.price;

  const [filteredComponents, setFilteredComponents] = useState(
    getComponentCategoryItems(componentCategoryItemsWithConditions),
  );
  const [sortState, setSortState] = React.useState<SortProperty[]>([]);

  useEffect(() => {
    setFilteredComponents(
      filterClientUpdateItems(
        searchValue,
        getComponentCategoryItems(componentCategoryItemsWithConditions),
      ) as ComponentCategoryItem[],
    );
  }, [componentCategoryItemsWithConditions, searchValue]);

  const headers = useMemo(
    () =>
      getComponentCategoryColumns(componentCategoryItemsWithConditions, clientTableColumns, regions)
        .sort((a, b) => (componentColumnMap[a].order || 0) - (componentColumnMap[b].order || 0))
        .map((column: string) => {
          let label = compoundCaseToTitleCase(column);
          if (Object.values(PricingCalculationColumns).some((v) => v === column)) {
            label = t(I18nKeys.PricingCalculationField);
          }
          return {
            i18nKey: label,
            property: column,
            sortKey: column === DisplayColumns.Label ? 'sortLabel' : column,
            valueFormatter: (property: string, row: any) =>
              formatComponentCategoryValue(
                property,
                row,
                categoryKey,
                componentCategoryItemsWithConditions,
                styles,
                currency,
              ),
          };
        }),
    [componentCategoryItemsWithConditions, regions, styles, clientTableColumns, currency, categoryKey, t],
  );

  const getHeaderClass = useCallback(
    (property: string): string => {
      const classList: string[] = [classes.cell];

      if ((Object.values(PriceColumn) as string[]).includes(property)) {
        classList.push(classes.priceHeader);
      } else if (property === PricingCalculationColumns.PriceCalculation) {
        classList.push(classes.priceCalculationCell);
      } else if (property === HelperColumns.VariesByRegion) {
        classList.push(classes.variesByRegionHeader);
      }

      return classList.join(' ');
    },
    [classes],
  );

  const getCellClass = useCallback(
    (row: any, property: string): string => {
      const classList: string[] = [classes.cell];

      const rowWithConditions = componentCategoryItemsWithConditions.find(
        ({ item: i }) => i[ClientDataFixedColumns.RowId] === row[ClientDataFixedColumns.RowId],
      );
      if (rowWithConditions) {
        // Does a diff exist for this item or any of its conditions?
        const diffExists = [rowWithConditions.item, ...rowWithConditions.conditions].some((r) => {
          const diff = clientUpdateDiffs
            .flatMap(({ changes }) => changes || [])
            .find((d) => d.rowId === r[ClientDataFixedColumns.RowId]);
          return (
            diff &&
            Object.keys(r).some((column) => {
              const columnDiff = getRowDiffColumnToFromValues(diff, column);
              return areDiffValuesDifferent(columnDiff?.from, columnDiff?.to);
            })
          );
        });
        if (diffExists) classList.push(classes.modified);
      }

      if ((Object.values(PriceColumn) as string[]).includes(property)) {
        classList.push(classes.priceCell);
      } else if (property === PricingCalculationColumns.PriceCalculation) {
        classList.push(classes.priceCalculationCell);
      } else if (property === HelperColumns.VariesByRegion) {
        classList.push(classes.variesByRegionCell);
      }

      return classList.join(' ');
    },
    [clientUpdateDiffs, classes, componentCategoryItemsWithConditions],
  );

  const rows = useMemo(() => {
    const newRows = groupComponentCategoryItems(categoryKey, filteredComponents)
      .map((row) =>
        Object.fromEntries(
          Object.keys(row).map((column) => [
            column,
            getDefaultParsedComponentPricingColumnValue(column as keyof ComponentCategoryItem, categoryKey, {
              item: row,
              // Conditions are handled in getMatchedPriceFromConditions below
              conditions: [],
            }),
          ]),
        ),
      )
      .map((row) => {
        const showKey = hasDuplicateLabels(row, categoryKey, componentCategoryItemsWithConditions);
        // Display default price calculation for tables without price calculation
        return {
          [PricingCalculationColumns.PriceCalculation]:
            (categoryKey && CLIENT_UPDATE_CATEGORY_MAPPING[categoryKey]?.defaultPricingType) || PriceCalculation.Amount,
          ...row,
          label: showKey ? (
            <Typography className={classes.componentLabel}>
              {row.label as string} <span className={classes.componentKey}> ({row.key as string})</span>
            </Typography>
          ) : (
            row.label
          ),
          sortLabel: `${row.label}${showKey ? ` (${row.key})` : ''}`,
          [PriceColumn.price]: getMatchedPriceFromConditions(
            row[ClientDataFixedColumns.RowId],
            priceColumn,
            componentCategoryItemsWithConditions,
          ),
          [HelperColumns.VariesByRegion]: pricingVariesByRegion(row) ? <Check /> : null,
        };
      })
      .sort((a, b) => {
        // Sorting label by lowercase, spaces removed, alphabetically
        const labelA = a.sortLabel.toLowerCase().replace(/\s/g, '');
        const labelB = b.sortLabel.toLowerCase().replace(/\s/g, '');
        if (labelA < labelB) {
          return -1;
        }
        if (labelA > labelB) {
          return 1;
        }
        return 0;
      });
    sortRows(newRows, sortState);
    return newRows;
  }, [filteredComponents, priceColumn]);

  const handleSort = (sortProperties: SortProperty[]): void => {
    sortRows(rows, sortProperties);
    setSortState(sortProperties);
  };

  useEffect(() => {
    if (savedSortState.length) handleSort(savedSortState);
  }, [savedSortState, categoryKey]);

  if (isLoadingComponentCategoryItemsWithConditions)
    return <CircularProgress style={{ alignSelf: 'center' }} color="primary" />;
  return (
    <Grid container className={classes.content} spacing={1}>
      <Grid item xs={12} className={classes.tableGrid}>
        <Table
          headers={headers}
          rows={rows}
          loading={false}
          sortProperties={sortState}
          sortableProperties={headers}
          onSort={(sortProperties: SortProperty[]): void => {
            handleSort(sortProperties);
            dispatch(
              saveUserPreferences({
                userPreference: UserPreference.PricingComponentPreferences,
                preferences: { sortState: sortProperties },
              }),
            );
          }}
          handleRowClick={(row): void => {
            dispatch(setSelectedComponentId(row[ClientDataFixedColumns.RowId]));
            dispatch(openDialog({ dialog: Dialogs.PricingComponentEdit }));
          }}
          getCellClass={getCellClass}
          getHeaderClass={getHeaderClass}
          noDataMessage={I18nKeys.PricingComponentNoComponentsFound}
        />
      </Grid>
    </Grid>
  );
};
