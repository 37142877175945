export enum ClientDataBranch {
  Main = 'main',
  Unpublished = 'unpublished',
  Hotfix = 'quick-update',
  Pricing = 'pricing',
  ClientUpdate = 'client-update',
  SiteDetail = 'site-detail',
  PricingSizeBased = 'pricing-size-based',
  PricingSurcharge = 'pricing-surcharge',
}
