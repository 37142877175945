import React, { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Box, Card, CardContent, CardHeader, Divider, Grid, Hidden, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { AppState } from '../types/AppState';
import { I18nValue } from '../types/I18n';
import { SortProperty } from '../types/SortProperty';
import { Header, Table } from './Table';
import { ChipMenu } from './ChipMenu';
import { openDialog } from '../ducks/dialogSlice';
import { Dialogs } from '../constants/Dialogs';
import { sortRows } from '../utils/sortUtils';
import { i18nKeyColumnHeader, i18nSortableProperties, i18nValueHeader } from '../constants/I18n';
import { fetchI18nValues, setI18nDialog } from '../ducks/settings';
import { SALESVIEW } from '../constants/App';
import { mapObjectToKeyValuePair } from '../utils/objectUtils';
import { I18nKeys } from '../constants/I18nKeys';
import { languages as languageConstants } from '../constants/Languages';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '2%',
  },
  rootGrid: {
    padding: '2%',
    flex: 1,
  },
  divider: {
    margin: '10px 0',
  },
  chip: {
    marginLeft: '12px',
  },
  i18nCards: {
    flex: 1,
  },
  i18nCard: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  i18nCardHeader: { paddingBottom: '0' },
  i18nCardContent: {
    paddingBottom: '0',
    paddingTop: '0',
  },
  i18nCardContentLine: {
    color: theme.palette.text.secondary,
  },
}));

interface StateProps {
  loading: boolean;
  i18nValues: I18nValue[];
  languages: {
    key: string;
    label?: string;
  }[];
}

interface DispatchProps {
  getI18nByLanguage(language: string): void;
  openI18nDialog(language: string, row: any): void;
}

type Props = StateProps & DispatchProps;

const salesViewI18nTableHeaders: Header[] = [i18nKeyColumnHeader, i18nValueHeader];

const PortalI18nComponent: React.FC<Props> = ({
  loading,
  languages,
  i18nValues,
  getI18nByLanguage,
  openI18nDialog,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedLanguage, setSelectedLanguage] = useState<string>('en');

  useEffect(() => {
    getI18nByLanguage(selectedLanguage);
  }, [getI18nByLanguage, selectedLanguage]);

  const [i18nSortState, setI18nTextSortState] = useState<SortProperty[]>([]);
  const handleI18nTextSort = (sortProperties: SortProperty[]): void => {
    sortRows(i18nValues, sortProperties);
    setI18nTextSortState(sortProperties);
  };

  return (
    <div className={classes.root}>
      <Grid container item alignItems="center">
        <Typography>{t(I18nKeys.I18nLanguage)}</Typography>
        <Grid item className={classes.chip}>
          <ChipMenu items={languages} defaultValue={selectedLanguage} onItemClicked={setSelectedLanguage} />
        </Grid>
      </Grid>

      <Divider className={classes.divider} />

      <Hidden xsDown>
        <Table
          showSearch
          noFab
          headers={salesViewI18nTableHeaders}
          sortProperties={i18nSortState}
          sortableProperties={[...i18nSortableProperties, { i18nKey: 'Override Text', property: 'override' }]}
          onSort={handleI18nTextSort}
          rows={i18nValues}
          handleRowClick={(row: any): void => openI18nDialog(selectedLanguage, row)}
          loading={loading}
          rowsPerPage={20}
        />
      </Hidden>
      <Hidden smUp>
        <div className={classes.i18nCards}>
          {i18nValues &&
            i18nValues.length > 0 &&
            i18nValues.map((i18nValue) => (
              <Card
                className={classes.i18nCard}
                key={i18nValue.key}
                onClick={(): void => openI18nDialog(selectedLanguage, i18nValue)}
              >
                <CardHeader
                  classes={{
                    root: classes.i18nCardHeader,
                  }}
                  title={i18nValue.key}
                />
                <CardContent className={classes.i18nCardContent}>
                  <Box className={classes.i18nCardContentLine}>{i18nValue.value}</Box>
                </CardContent>
              </Card>
            ))}
        </div>
      </Hidden>
    </div>
  );
};

const mapStateToProps = ({ settings: { i18n: i18nValues, loading } }: AppState): StateProps => ({
  loading,
  i18nValues: mapObjectToKeyValuePair(i18nValues, ['override']),
  languages: languageConstants,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  getI18nByLanguage: (language: string): void => {
    dispatch(fetchI18nValues(language));
  },
  openI18nDialog: (language: string, row: any): void => {
    dispatch(setI18nDialog(SALESVIEW, language, row));
    dispatch(openDialog({ dialog: Dialogs.I18n }));
  },
});

export const PortalI18n = connect(mapStateToProps, mapDispatchToProps)(PortalI18nComponent);
