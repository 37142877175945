import { AppBar, Button, Grid, Hidden, Toolbar, IconButton, Tooltip, Typography } from '@mui/material';
import { Theme, alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Menu as MenuIcon, Search, MoreVert } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Check from '@mui/icons-material/Check';
import { DockedDrawerWidth } from '../constants/DockedDrawerWidth';
import { I18nKeys } from '../constants/I18nKeys';
import { ClientDataConfigSelect } from './ClientDataConfigSelect';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { setSearchOpen, toggleSettingOption } from '../ducks/clientDataSlice';
import { useAppDispatch, useAppSelector } from '../hooks';
import { AnchorMenu } from './AnchorMenu';
import { BRANCH_LABELS, SettingsOptions, UtilityOptions, topBarOptions } from '../constants/ClientData';
import { AppState } from '../types/AppState';
import { Dialogs } from '../constants/Dialogs';
import { openDialog } from '../ducks/dialogSlice';
import { useClientDataRepo } from '../hooks/useClientDataRepo';
import { useDeleteBranchMutation, useGetClientPublishedVersionsQuery } from '../services/clientDataApi';
import { ClientDataType } from '../constants/ClientDataType';
import { mapClientIdToConfiguratorAndVendor } from '../utils/clientIdUtils';
import { getConfiguratorUrlWithLocale } from '../utils/vendorUtils';
import { ClientDataApplyDataUpdatesTooltip } from './ClientDataApplyDataUpdatesTooltip';
import { clientHasDatasetsToUpdate } from '../utils/clientDataUtils';
import { s3FilesExist } from '../utils/configuratorUtils';
import { openConfirmationDialog } from '../ducks/confirmation';
import { unknownUser } from '../types/User';
import { LocalStorage } from '../constants/LocalStorage';

interface StyleProps {
  open: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  appBar: {
    backgroundColor: theme.palette.primary.main,
    width: (props): string => (props.open ? '100%' : `calc(100% - ${DockedDrawerWidth}px)`),
    marginLeft: (props): string => (props.open ? '0' : `${DockedDrawerWidth}px`),
    transition: (props): string =>
      props.open
        ? theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          })
        : theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
  },
  topToolbarGrid: {
    padding: '10px',
  },
  drawerAndLogoContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  icon: {
    color: theme.palette.primary.contrastText,
  },
  buttonContainer: {
    '& > :not(:last-child)': {
      margin: `0px ${theme.spacing(1)} 0px 0px`,
    },
  },
  primary: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.contrastText, 0.6),
    },
  },
  secondary: {
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.contrastText, 0.3),
    },
  },
  button: {
    padding: '4px 16px',
  },
  activeChip: {
    fontWeight: 'bold',
    backgroundColor: 'A72927',
    color: 'white',
  },
}));

interface Props {
  open: boolean;
  onDrawerToggle: () => void;
}

interface ClientDataAction {
  i18nKey: string;
  onClick: () => void;
  primary?: boolean;
  disabled?: boolean;
  tooltip?: string | JSX.Element;
}

export const ClientDataTopBar: React.FC<Props> = ({ open, onDrawerToggle }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles({ open });
  const screenIsXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { clientId, clientDataBranch, clientDataType, isCreatingBranch } = useAppSelector(
    (state: AppState) => state?.clientData,
  );
  const [, { isLoading: isDeletingBranch }] = useDeleteBranchMutation({
    fixedCacheKey: 'revert',
  });
  const { user = unknownUser } = useAppSelector((state) => state?.currentUser);
  const { activeBranches, vendorData: { locale = '', productionURL = '' } = {} } = useClientDataRepo({
    useBranches: true,
    useVendorData: true,
  });
  const disableButtons = isCreatingBranch || isDeletingBranch || !clientId;
  const clientBranchOpen = [ClientDataBranch.Pricing, ClientDataBranch.ClientUpdate].includes(
    clientDataBranch || ClientDataBranch.Main,
  );

  const { settings } = useAppSelector((state: AppState) => state?.clientData);

  const [topBarOptionsAnchorEl, setTopBarOptionsAnchorEl] = useState<null | HTMLElement>(null);
  const [showApplyUpdatesButton, setShowApplyUpdatesButton] = useState(false);

  // Fetch supplier versions for supplier data type only
  const { currentData: supplierPublishedVersions } = useGetClientPublishedVersionsQuery(
    { clientId, dataType: ClientDataType.Supplier },
    {
      skip:
        !clientId ||
        !clientDataType ||
        clientDataType !== ClientDataType.Supplier ||
        clientDataBranch !== ClientDataBranch.Main,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    },
  );
  // Fetch vendor versions for both vendor and supplier data types
  const { currentData: vendorPublishedVersions } = useGetClientPublishedVersionsQuery(
    { clientId, dataType: ClientDataType.Vendor },
    {
      skip:
        !clientId ||
        !clientDataType ||
        clientDataType === ClientDataType.Reference ||
        clientDataBranch !== ClientDataBranch.Main,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    },
  );
  const clientPublishedVersions =
    clientDataType === ClientDataType.Supplier ? supplierPublishedVersions : vendorPublishedVersions;

  useEffect(() => {
    setShowApplyUpdatesButton(clientHasDatasetsToUpdate(clientPublishedVersions));
  }, [clientPublishedVersions]);

  const dispatch = useAppDispatch();
  const openSearch = (): void => {
    dispatch(setSearchOpen(true));
  };
  const openTopBarOptions = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setTopBarOptionsAnchorEl(event.currentTarget);
  };

  const validatePricingBranchChange = (canPublishMethod: Function): void => {
    const [clientBranch, pricingBaseBranch, clientUpdateBranch] = [
      clientDataBranch,
      ClientDataBranch.Pricing,
      ClientDataBranch.ClientUpdate,
    ].map((branchType) => activeBranches.find((branch) => branch.branchType === branchType));

    const removeCommitHashesFromLocalStorage = () => {
      const groupId = localStorage.getItem(LocalStorage.SelectedGroupId);
      [
        LocalStorage.LastPricingBaseMergeCommit,
        LocalStorage.LastPricingComponentMergeCommit,
        LocalStorage.LastPricingSizeBasedMergeCommit,
      ].forEach((key) => {
        const lastMergeCommit = JSON.parse(localStorage.getItem(key) || '{}');
        if (groupId && lastMergeCommit[groupId] && lastMergeCommit[groupId][clientId]) {
          lastMergeCommit[groupId][clientId] = undefined;
          localStorage.setItem(key, JSON.stringify(lastMergeCommit));
        }
      });
    };

    const tableHasChanged = [pricingBaseBranch, clientUpdateBranch].some((branch) =>
      branch?.changedTables?.some((table) => clientBranch?.changedTables?.includes(table)),
    );
    if (tableHasChanged) {
      const localizedAuthors = [pricingBaseBranch, clientUpdateBranch]
        .flatMap((branch) => branch?.authors || [])
        .map((authorName) =>
          authorName === user.name ? t(I18nKeys.ClientDataBranchToolTipSummaryYouAuthor) : authorName,
        );

      dispatch(
        openConfirmationDialog(
          [],
          [
            () => {
              removeCommitHashesFromLocalStorage();
              canPublishMethod();
            },
          ],
          undefined,
          t(I18nKeys.ClientDataPublishPricingConfirmMessage, {
            authors: localizedAuthors,
          }),
          t(I18nKeys.ClientDataPublishPricingConfirmButton),
          undefined,
          true,
        ),
      );
      dispatch(openDialog({ dialog: Dialogs.Confirmation }));
    } else {
      removeCommitHashesFromLocalStorage();
      canPublishMethod();
    }
  };

  const getConfiguratorViewUrl = () => {
    const { configurator, vendor } = mapClientIdToConfiguratorAndVendor(clientId);
    const configuratorUrl = getConfiguratorUrlWithLocale(configurator, vendor, locale, productionURL);
    return configuratorUrl;
  };

  const filesExistInS3 = s3FilesExist(clientId, clientDataType, {
    [ClientDataType.Vendor]: vendorPublishedVersions,
    [ClientDataType.Supplier]: supplierPublishedVersions,
  });
  const actions: ClientDataAction[] =
    clientDataBranch === ClientDataBranch.Main
      ? [
          {
            i18nKey: I18nKeys.ClientDataRollbackOpenButton,
            onClick: (): void => {
              dispatch(openDialog({ dialog: Dialogs.ClientDataRollback }));
            },
            primary: true,
            disabled: disableButtons,
          },
          ...(clientDataType !== ClientDataType.Reference
            ? [
                {
                  i18nKey: I18nKeys.ViewButton,
                  onClick: (): void => {
                    const previewUrl = getConfiguratorViewUrl();
                    window.open(previewUrl, '_blank', 'noopener noreferrer');
                  },
                  primary: true,
                  disabled: disableButtons || !filesExistInS3,
                  tooltip: !filesExistInS3 ? (
                    <Typography variant="caption" gutterBottom component="div">
                      {t(I18nKeys.ClientDataDisabledViewTooltip)}
                    </Typography>
                  ) : undefined,
                },
                ...(showApplyUpdatesButton
                  ? [
                      {
                        i18nKey: I18nKeys.ApplyDataUpdatesButton,
                        onClick: () => {
                          dispatch(openDialog({ dialog: Dialogs.ClientDataPublishUpdates }));
                        },
                        primary: true,
                        disabled: disableButtons,
                        tooltip: (
                          <ClientDataApplyDataUpdatesTooltip clientPublishedVersions={clientPublishedVersions} />
                        ),
                      },
                    ]
                  : []),
              ]
            : []),
        ]
      : [
          {
            i18nKey: I18nKeys.RevertButton,
            onClick: (): void => {
              dispatch(openDialog({ dialog: Dialogs.ClientDataRevertBranch }));
            },
            disabled: disableButtons || clientBranchOpen,
            tooltip: clientBranchOpen ? (
              <Typography variant="caption" gutterBottom component="div">
                {t(I18nKeys.ClientDataClientBranchDisabledActionTooltip, {
                  branch: t(BRANCH_LABELS[clientDataBranch || ClientDataBranch.Main]),
                })}
              </Typography>
            ) : undefined,
          },
          {
            i18nKey: I18nKeys.PreviewButton,
            onClick: (): void => {
              dispatch(openDialog({ dialog: Dialogs.ClientDataPreview }));
            },
            primary: true,
            disabled: disableButtons,
          },
          {
            i18nKey: I18nKeys.PublishButton,
            onClick: (): void => {
              if (
                clientDataBranch === ClientDataBranch.Unpublished &&
                activeBranches.find((branch) => branch.branchType === ClientDataBranch.Hotfix)
              ) {
                dispatch(openDialog({ dialog: Dialogs.ClientDataCantPublish }));
              } else {
                validatePricingBranchChange(() => {
                  dispatch(openDialog({ dialog: Dialogs.ClientDataPublish }));
                });
              }
            },
            primary: true,
            disabled: disableButtons || clientBranchOpen,
            tooltip: clientBranchOpen ? (
              <Typography variant="caption" gutterBottom component="div">
                {t(I18nKeys.ClientDataClientBranchDisabledActionTooltip, {
                  branch: t(BRANCH_LABELS[clientDataBranch || ClientDataBranch.Main]),
                })}
              </Typography>
            ) : undefined,
          },
        ];

  const onAnchorMenuItemClick = (option: SettingsOptions | UtilityOptions): void => {
    if (Object.values(UtilityOptions).includes(option as any)) {
      switch (option) {
        case UtilityOptions.KeyboardShortcuts:
          window.open(t(I18nKeys.UtilityOptionsKeyboardShortcutsLink), '_blank', 'noopener noreferrer');
          break;
        case UtilityOptions.VerifyQuotes:
          dispatch(openDialog({ dialog: Dialogs.VerifiedQuotes }));
          setTopBarOptionsAnchorEl(null);
          break;
        default:
          break;
      }
      return;
    }
    dispatch(toggleSettingOption(option as SettingsOptions));
  };

  return (
    <AppBar position="sticky" elevation={0} className={classes.AppBar}>
      <Toolbar
        disableGutters
        variant="regular"
        style={{
          height: 'auto',
        }}
      >
        <Grid container className={classes.topToolbarGrid}>
          {!screenIsXs && (
            <Grid container direction="row" alignItems="center" justifyContent="space-between">
              <Grid item className={classes.drawerAndLogoContainer}>
                <Hidden lgUp>
                  <IconButton aria-label="open drawer" onClick={onDrawerToggle} size="large">
                    <MenuIcon className={classes.icon} />
                  </IconButton>
                </Hidden>
                <Hidden smDown>
                  {!open && (
                    <IconButton aria-label="open drawer" onClick={onDrawerToggle} size="large">
                      <MenuIcon className={classes.icon} />
                    </IconButton>
                  )}
                </Hidden>
                <ClientDataConfigSelect />
                <IconButton onClick={openSearch}>
                  <Search className={classes.icon} />
                </IconButton>
              </Grid>
              <Grid item className={classes.buttonContainer}>
                {actions.map((action: ClientDataAction) => (
                  <Tooltip key={action.i18nKey} title={action.tooltip || ''}>
                    {/* Empty span fixes a MUI error when button is disabled and tooltip is enabled */}
                    <span>
                      <Button
                        key={action.i18nKey}
                        disabled={action.disabled}
                        onClick={action.onClick}
                        className={`${classes.button} ${action.primary ? classes.primary : classes.secondary}`}
                        variant={action.primary ? 'contained' : 'outlined'}
                      >
                        {t(action.i18nKey)}
                      </Button>
                    </span>
                  </Tooltip>
                ))}
                <IconButton onClick={openTopBarOptions}>
                  <MoreVert className={classes.icon} />
                </IconButton>
                <AnchorMenu
                  menuAnchorEl={topBarOptionsAnchorEl}
                  options={topBarOptions}
                  checkedOptions={Object.entries(settings).reduce((acc, [setting, value]) => {
                    if (value) acc.push(setting);
                    return acc;
                  }, [] as string[])}
                  checkedIcon={<Check />}
                  onClick={onAnchorMenuItemClick}
                  onClose={() => setTopBarOptionsAnchorEl(null)}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
