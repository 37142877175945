import { VendorData } from '../types/VendorData';
import { SiteDetail, SiteDetailField } from '../types/ClientData';
import { Theme } from '../types/ViewerState';
import { Configurator } from '../types/Configurator';
import { sitesFieldsMap, SiteDetailDataFields } from '../constants/VendorData';

/**
 * Finds and returns a boolean value for a property within a configurator's vendor data.
 * @param vendorData - Configurator vendor data
 * @param property - Property to be found in vendor data
 * @param isVendorProperty - Whether property is in vendor section
 */
export const getEnabledOnProperty = (
  vendorData: any | undefined,
  property: string,
  isVendorProperty: boolean,
): boolean => {
  let prop = false;
  if (isVendorProperty && vendorData && vendorData.vendor) {
    const value = vendorData.vendor[property];
    prop = typeof value === 'string' ? value !== 'false' : value;
  } else if (!isVendorProperty && vendorData) {
    const value = vendorData[property];
    prop = typeof value === 'string' ? value !== 'false' : value;
  }
  return prop || false;
};

/**
 * Determines whether the user's input is a valid color value. Accepted color formats are HEX, RGB,
 * and RGBA.
 * @param input - User input for a color value
 * @returns boolean whether or not input is valid
 */
export const validateColorInput = (input: string): boolean => {
  const isHEX = /^#([a-f0-9]{6}|[a-f0-9]{3})$/i.test(input);
  const isRGB = /rgb((((25[0-5]|2[0-4]d|1d{1,2}|dd?)s*?,s*?){2}(25[0-5]|2[0-4]d|1d{1,2}|dd?)s*?)?)/i.test(input);
  const isRGBA =
    /(rgba(((25[0-5]|2[0-4]d|1d{1,2}|dd?)s*?,s*?){2}(25[0-5]|2[0-4]d|1d{1,2}|dd?)s*?,s*(0?.d*|0(.d*)?|1)?))/i.test(
      input,
    );
  return isHEX || isRGB || isRGBA;
};

/**
 * Transforms a configurator's vendor data into a Theme object
 *
 * @param {Theme} config - Configurator with vendor data
 * @returns {Theme}
 */
export const extractThemeFromConfig = (config: Configurator): Theme => {
  const { vendorData = {} } = config;
  const { vendor = {} } = vendorData as VendorData;
  const { logoUrl, contactBarColor, contactBarTextColor, selectedTextColor, logoBarColor, logoBarTextColor } = vendor;

  return {
    logoUrl,
    contactBarColor,
    contactBarTextColor,
    selectedTextColor,
    logoBarColor,
    logoBarTextColor,
  };
};

export const hideSiteDetailField = (
  field: SiteDetailDataFields,
  data: SiteDetail = {},
  isIdeaRoomUser = false,
  selectedGroupId: string,
): boolean => {
  const { hidden } = sitesFieldsMap[field] as SiteDetailField;
  if (!hidden) return false;
  return hidden(data, isIdeaRoomUser, selectedGroupId);
};

export const disabledSiteDetailField = (
  field: SiteDetailDataFields,
  data: SiteDetail = {},
  isIdeaRoomUser = false,
  selectedGroupId: string,
): boolean => {
  const { disabled } = sitesFieldsMap[field] as SiteDetailField;
  if (!disabled) return false;
  return disabled(data, isIdeaRoomUser, selectedGroupId);
};

export const ideaRoomOnlySiteDetailField = (
  field: SiteDetailDataFields,
  data: SiteDetail = {},
  isIdeaRoomUser = false,
  selectedGroupId: string,
): boolean => {
  const { ideaRoomOnly } = sitesFieldsMap[field] as SiteDetailField;
  if (!ideaRoomOnly) return false;
  return ideaRoomOnly(data, isIdeaRoomUser, selectedGroupId);
};

export const getSiteDetailFieldDefault = (field: SiteDetailDataFields): string => {
  const { props: { defaultValue = '' } = {} } = sitesFieldsMap[field] as SiteDetailField;
  return defaultValue;
};
