/* eslint-disable react/no-unstable-nested-components */
import { Box, Card, CardActions, CardContent, CardHeader, Hidden, MenuItem } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import AutoSizer, { Size } from 'react-virtualized/dist/commonjs/AutoSizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { AnyAction, Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import {
  closeOrderDetailDialog,
  deleteLead,
  fetchOrderOwnerOptions,
  fetchOrders,
  fetchOrdersCsv,
  openOrderDetailDialog,
  setOrderDealer,
  setOrderOwner,
  setOrderStatus,
  setSortProperties,
} from '../ducks/orders';
import { setSecondaryActions } from '../ducks/secondaryActions';
import { AppState } from '../types/AppState';
import { unknownGroup } from '../constants/Group';
import { Order } from '../types/Order';
import { OrderDealer } from '../types/OrderDealer';
import { OrderOwner } from '../types/OrderOwner';
import { OrderStatus } from '../types/OrderStatus';
import { SortableProperty } from '../types/SortableProperty';
import { SortProperty } from '../types/SortProperty';
import { isCurrentUserManager, isCurrentUserGroupAdmin, isIdeaRoomGroup } from '../utils/userUtils';
import {
  getOrderDealerFromOrder,
  getOrderOwnerFromOrder,
  getOrderStatusFromOrder,
  getOrderStatusLabelFromOrder,
  mapSubmitStatusToLabel,
} from '../utils/orderUtils';
import { formatPhoneNumber } from '../utils/phoneNumberUtils';
import { sortRows } from '../utils/sortUtils';
import { hyphenateString, stringToLowerIncludes } from '../utils/stringUtils';
import { NewWindowLink } from './NewWindowLink';
import { OrderDetailsDialog } from './OrderDetailsDialog';
import { OrderFilterMenu } from './OrderFilterMenu';
import { OrderDealerSelector } from './OrderDealerSelector';
import { OrderOwnerSelector } from './OrderOwnerSelector';
import { OrderStatusSelector } from './OrderStatusSelector';
import { Table } from './Table';
import { TableControls } from './TableControls';
import { unknownUser } from '../types/User';
import { getSiteFromClientId } from '../utils/clientIdUtils';
import { Configurator, ConfiguratorEnabledOnProps } from '../types/Configurator';
import { ColumnFilterMenu } from './ColumnFilterMenu';
import { Columns } from '../constants/Viewer';
import { formatPrice } from '../utils/pricingUtils';
import { Dialogs } from '../constants/Dialogs';
import { openConfirmationDialog } from '../ducks/confirmation';
import { openDialog } from '../ducks/dialogSlice';
import { getEnabledOnProperty } from '../utils/vendorDataUtils';
import { removeQueryParam } from '../utils/urlUtils';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppSelector } from '../hooks';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  orderCards: {
    height: '100%',
  },
  orderCard: {
    minHeight: 150,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  orderCardHeader: {
    paddingTop: theme.spacing(1),
    paddingBottom: '0',
  },
  orderCardHeaderContent: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  orderCardHeaderTitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  orderCardContent: {
    paddingTop: '0',
    paddingBottom: '0',
  },
  orderCardContentLine: {
    color: theme.palette.text.secondary,
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

interface StateProps {
  orders: Order[];
  configurators: Configurator[];
  loading: boolean;
  isIdearoomGroup: boolean;
  dealersEnabled: boolean;
  adminUser: boolean;
  managerUser: boolean;
  searchTerm: string;
  groupId?: string;
  orderDealerOptions: OrderDealer[];
  orderOwnerOptions: OrderOwner[];
  visibleColumns: string[];
  detailsDialogOpen: boolean;
  detailsState: Order | undefined;
}

interface DispatchProps {
  loadOrders(): void;
  handleSort(sortProperties: SortProperty[]): void;
  handleExport(): void;
  setupSecondaryActions(secondaryActions: JSX.Element[]): void;
  teardownSecondaryActions(): void;
  loadOrderOwnerOptions(groupId?: string): void;
  setNewOrderDealer(clientId: string, uuid: string, status: string, orderDealer: OrderDealer): void;
  setNewOrderOwner(clientId: string, uuid: string, status: string, orderOwner: OrderOwner): void;
  setNewOrderStatus(clientId: string, uuid: string, orderStatus: OrderStatus): void;
  confirmLeadDeletion(
    confirmAction: AnyAction,
    confirmationTitle: string,
    description: string,
    confirmButton: string,
  ): void;
  openDetailsDialog(order: Order): void;
  closeDetailsDialog(): void;
}

type Props = StateProps & DispatchProps;

export const OrderReportComponent: React.FC<Props> = ({
  orders,
  configurators,
  loading,
  isIdearoomGroup,
  dealersEnabled,
  adminUser,
  managerUser,
  searchTerm,
  groupId,
  orderDealerOptions,
  orderOwnerOptions,
  visibleColumns,
  detailsDialogOpen,
  detailsState,
  loadOrders,
  handleSort,
  handleExport,
  setupSecondaryActions,
  teardownSecondaryActions,
  loadOrderOwnerOptions,
  setNewOrderDealer,
  setNewOrderOwner,
  setNewOrderStatus,
  confirmLeadDeletion,
  openDetailsDialog,
  closeDetailsDialog,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { currency } = useAppSelector((state: AppState) => state?.viewer);
  const { members } = useAppSelector((state: AppState) => state?.group.group || unknownGroup);

  const [filteredOrders, setFilteredOrders] = React.useState<Order[]>(orders);
  const [sortState, setSortState] = React.useState<SortProperty[]>([]);

  const getListItemSize = (): number => {
    let size = 0;
    size += visibleColumns.includes(Columns.Name) ? 48 : 0;
    size += visibleColumns.includes(Columns.Email) ? 24 : 0;
    size += visibleColumns.includes(Columns.Phone) || visibleColumns.includes(Columns.Created) ? 24 : 0;
    size += visibleColumns.includes(Columns.Zip) || visibleColumns.includes(Columns.City) ? 24 : 0;
    size += visibleColumns.includes(Columns.Style) || visibleColumns.includes(Columns.Estimate) ? 24 : 0;
    size += visibleColumns.includes(Columns.Size) || visibleColumns.includes(Columns.Reference) ? 24 : 0;
    size += configurators && configurators.length > 1 && visibleColumns.includes(Columns.Site) ? 24 : 0;
    size +=
      (dealersEnabled && (adminUser || orderDealerOptions.length > 1) && visibleColumns.includes(Columns.Dealer)) ||
      ((adminUser || managerUser) && visibleColumns.includes(Columns.Owner)) ||
      visibleColumns.includes(Columns.Status)
        ? 48
        : 0;
    size += 6; // padding
    return size;
  };

  // Effect that filters orders by searchTerm anytime either orders or searchTerm change
  React.useEffect(() => {
    // don't bother searching until there's at least 3 characters
    if (searchTerm.length < 3) {
      if (filteredOrders.length !== orders.length) {
        setFilteredOrders(orders);
      }
      return;
    }

    // Filtering the columns to search on based on their visibility
    // Filtering row values based on what is shown and not keys or values
    const searchCriteria = [
      ...(visibleColumns.includes(Columns.Created)
        ? [
            (order: Order): boolean =>
              order.date !== undefined && stringToLowerIncludes(moment(order.date).format('MMM DD'), searchTerm),
          ]
        : []),
      ...(visibleColumns.includes(Columns.Name)
        ? [(order: Order): boolean => stringToLowerIncludes(order.customerName, searchTerm)]
        : []),
      ...(visibleColumns.includes(Columns.Email)
        ? [(order: Order): boolean => stringToLowerIncludes(order.customerEmail, searchTerm)]
        : []),
      ...(visibleColumns.includes(Columns.Phone)
        ? [
            (order: Order): boolean => stringToLowerIncludes(order.customerPhone, searchTerm),
            (order: Order): boolean => stringToLowerIncludes(formatPhoneNumber(order.customerPhone), searchTerm),
          ]
        : []),
      ...(visibleColumns.includes(Columns.City)
        ? [(order: Order): boolean => stringToLowerIncludes(order.city, searchTerm)]
        : []),
      ...(visibleColumns.includes(Columns.Zip)
        ? [(order: Order): boolean => stringToLowerIncludes(order.zipCode, searchTerm)]
        : []),
      ...(visibleColumns.includes(Columns.Style)
        ? [(order: Order): boolean => stringToLowerIncludes(order.buildingStyle, searchTerm)]
        : []),
      ...(visibleColumns.includes(Columns.SubmitStatus)
        ? [
            (order: Order): boolean => stringToLowerIncludes(order.submitStatus, searchTerm),
            (order: Order): boolean => stringToLowerIncludes(mapSubmitStatusToLabel(order.submitStatus), searchTerm),
          ]
        : []),
      ...(visibleColumns.includes(Columns.Size)
        ? [(order: Order): boolean => stringToLowerIncludes(order.buildingSize, searchTerm)]
        : []),
      ...(visibleColumns.includes(Columns.Estimate)
        ? [(order: Order): boolean => stringToLowerIncludes(order.totalPrice.toString(), searchTerm)]
        : []),
      ...(visibleColumns.includes(Columns.Reference)
        ? [(order: Order): boolean => stringToLowerIncludes(order.versionedEmailId, searchTerm)]
        : []),
      ...(visibleColumns.includes(Columns.Site)
        ? [
            (order: Order): boolean =>
              stringToLowerIncludes(getSiteFromClientId(order.clientId, configurators), searchTerm),
          ]
        : []),
      ...(visibleColumns.includes(Columns.Dealer)
        ? [
            (order: Order): boolean => {
              const dealer = getOrderDealerFromOrder(order);
              return stringToLowerIncludes(dealer.name || dealer.key, searchTerm);
            },
          ]
        : []),
      ...(visibleColumns.includes(Columns.Owner)
        ? [
            (order: Order): boolean => {
              const owner = getOrderOwnerFromOrder(order, members);
              return stringToLowerIncludes(owner.name || owner.email, searchTerm);
            },
          ]
        : []),
      ...(visibleColumns.includes(Columns.Status)
        ? [(order: Order): boolean => stringToLowerIncludes(getOrderStatusLabelFromOrder(order), searchTerm)]
        : []),
      (order: Order): boolean => stringToLowerIncludes(order.ipAddress, searchTerm),
      (order: Order): boolean => stringToLowerIncludes(order.versionedEmailId, searchTerm.replace('#', '')),
      (order: Order): boolean => stringToLowerIncludes(hyphenateString(order.versionedEmailId), searchTerm),
    ];

    setFilteredOrders(orders.filter((order) => searchCriteria.some((test) => test(order))));
  }, [orders, configurators, filteredOrders.length, visibleColumns, searchTerm]);

  // Effect that adds the Export button to secondaryActions menu before the browser paints
  React.useLayoutEffect(() => {
    if (adminUser) {
      setupSecondaryActions([
        <MenuItem key="export" onClick={handleExport}>
          {t(I18nKeys.ExportButton)}
        </MenuItem>,
      ]);
    }
  }, [adminUser, setupSecondaryActions, handleExport]);

  // Effect that performs cleanup on the secondaryActions menu when view is unloaded
  React.useLayoutEffect(() => (): void => teardownSecondaryActions(), [teardownSecondaryActions]);

  const handleLeadDelete = (): void => {
    confirmLeadDeletion(
      deleteLead(),
      t(I18nKeys.LeadConfirmDeleteTitle),
      t(I18nKeys.LeadConfirmDeleteMessage),
      t(I18nKeys.DialogDeleteButton),
    );
  };

  const handleSortChanged = (sortProperties: SortProperty[]): void => {
    setSortState(sortProperties);
    handleSort(sortProperties);
    sortRows(filteredOrders, sortProperties);
  };

  React.useEffect(() => loadOrders(), [loadOrders]);
  React.useEffect(() => loadOrderOwnerOptions(groupId), [groupId, loadOrderOwnerOptions]);

  const sortableProperties: SortableProperty[] = [
    { i18nKey: I18nKeys.TableHeaderCreated, property: 'date' },
    { i18nKey: I18nKeys.TableHeaderName, property: 'customerName' },
    { i18nKey: I18nKeys.TableHeaderEmail, property: 'customerEmail' },
    { i18nKey: I18nKeys.TableHeaderPhone, property: 'customerPhone' },
    { i18nKey: I18nKeys.TableHeaderSite, property: 'clientId' },
    { i18nKey: I18nKeys.TableHeaderDealer, property: 'dealerName', defaultValue: t(I18nKeys.DefaultDealerName) },
    { i18nKey: I18nKeys.TableHeaderOwner, property: 'ownerName', defaultValue: t(I18nKeys.DefaultOwnerName) },
    {
      i18nKey: I18nKeys.TableHeaderStatus,
      property: 'orderStatusName',
      defaultValue: t(I18nKeys.DefaultStatusName),
    },
    {
      i18nKey: I18nKeys.TableHeaderSubmitStatus,
      property: 'submitStatus',
    },
    { i18nKey: I18nKeys.TableHeaderDeliveryZip, property: 'zipCode' },
    { i18nKey: I18nKeys.TableHeaderBuildingStyle, property: 'buildingStyle' },
    { i18nKey: I18nKeys.TableHeaderBuildingSize, property: 'buildingSize' },
    { i18nKey: I18nKeys.TableHeaderEstimate, property: 'totalPrice' },
    { i18nKey: I18nKeys.TableHeaderReferenceNumber, property: 'versionedEmailId' },
  ];

  const LeadCard = ({ index, style }: ListChildComponentProps): JSX.Element => {
    const order = filteredOrders[index];
    return (
      <div style={style}>
        <Card className={classes.orderCard} key={order.hash} onClick={(): void => openDetailsDialog(order)}>
          {visibleColumns.includes(Columns.Name) && (
            <CardHeader
              classes={{
                root: classes.orderCardHeader,
                content: classes.orderCardHeaderContent,
                title: classes.orderCardHeaderTitle,
              }}
              title={order.customerName}
              action={
                visibleColumns.includes(Columns.Design) && <NewWindowLink link={order.orderDealerLink || order.link} />
              }
            />
          )}
          <CardContent className={classes.orderCardContent}>
            {visibleColumns.includes(Columns.Email) && (
              <Box className={classes.orderCardContentLine}>{order.customerEmail}</Box>
            )}

            <Box className={classes.orderCardContentLine}>
              {visibleColumns.includes(Columns.Phone) && <Box>{formatPhoneNumber(order.customerPhone)}</Box>}
              {visibleColumns.includes(Columns.Created) && <Box>{moment(order.date).format('MMM DD')}</Box>}
            </Box>

            <Box className={classes.orderCardContentLine}>
              {visibleColumns.includes(Columns.City) && <Box>{order.city}</Box>}
              {visibleColumns.includes(Columns.Zip) && <Box>{order.zipCode}</Box>}
            </Box>

            <Box className={classes.orderCardContentLine}>
              {visibleColumns.includes(Columns.Style) && <Box>{order.buildingStyle}</Box>}
              {visibleColumns.includes(Columns.Estimate) && <Box>{formatPrice(order.totalPrice, currency, 2)}</Box>}
            </Box>

            <Box className={classes.orderCardContentLine}>
              {visibleColumns.includes(Columns.Size) && <Box>{order.buildingSize}</Box>}
              {visibleColumns.includes(Columns.Reference) && <Box>{hyphenateString(order.versionedEmailId)}</Box>}
            </Box>

            {configurators && configurators.length > 1 && visibleColumns.includes(Columns.Site) && (
              <Box className={classes.orderCardContentLine}>{getSiteFromClientId(order.clientId, configurators)}</Box>
            )}
          </CardContent>
          <CardActions>
            <Box display="flex" justifyContent="space-between" width="100%">
              {dealersEnabled &&
                (adminUser || orderDealerOptions.length > 1) &&
                visibleColumns.includes(Columns.Dealer) && (
                  <Box>
                    {!adminUser && !managerUser ? (
                      <span>{getOrderDealerFromOrder(order).name}</span>
                    ) : (
                      <OrderDealerSelector
                        readonly={isIdearoomGroup}
                        dealer={getOrderDealerFromOrder(order)}
                        options={orderDealerOptions}
                        onOrderDealerChange={(orderDealer: OrderDealer): void => {
                          setNewOrderDealer(
                            order.clientId,
                            order.uuid,
                            getOrderStatusLabelFromOrder(order),
                            orderDealer,
                          );
                        }}
                      />
                    )}
                  </Box>
                )}
              {(adminUser || managerUser) && visibleColumns.includes(Columns.Owner) && (
                <Box>
                  <OrderOwnerSelector
                    readonly={isIdearoomGroup}
                    owner={getOrderOwnerFromOrder(order, members)}
                    options={orderOwnerOptions}
                    onOrderOwnerChange={(orderOwner: OrderOwner): void => {
                      setNewOrderOwner(order.clientId, order.uuid, getOrderStatusLabelFromOrder(order), orderOwner);
                    }}
                  />
                </Box>
              )}
              {visibleColumns.includes(Columns.Status) && (
                <Box>
                  <OrderStatusSelector
                    readonly={isIdearoomGroup}
                    status={getOrderStatusFromOrder(order)}
                    onOrderStatusChange={(orderStatus: OrderStatus): void => {
                      setNewOrderStatus(order.clientId, order.uuid, orderStatus);
                    }}
                  />
                </Box>
              )}
            </Box>
          </CardActions>
        </Card>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <OrderDetailsDialog
        open={detailsDialogOpen}
        order={detailsState}
        configurators={configurators}
        admin={adminUser}
        handleClose={closeDetailsDialog}
        handleDelete={handleLeadDelete}
      />
      <TableControls
        filterRows={<OrderFilterMenu count={filteredOrders.length} />}
        filterColumns={<ColumnFilterMenu />}
        sortableProperties={sortableProperties}
        sortProperties={sortState}
        onSort={handleSortChanged}
      />
      <Hidden smDown>
        <Table
          headers={[
            {
              i18nKey: I18nKeys.TableHeaderCreated,
              property: 'date',
              hidden: !visibleColumns.includes(Columns.Created),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              CellComponent: ({ value }: any): JSX.Element => <span>{moment(value).format('MMM DD')}</span>,
            },
            {
              i18nKey: I18nKeys.TableHeaderName,
              property: 'customerName',
              hidden: !visibleColumns.includes(Columns.Name),
            },
            {
              i18nKey: I18nKeys.TableHeaderEmail,
              property: 'customerEmail',
              hidden: !visibleColumns.includes(Columns.Email),
              cellStyle: { whiteSpace: 'nowrap', maxWidth: '400px', overflow: 'hidden', textOverflow: 'ellipsis' },
            },
            {
              i18nKey: I18nKeys.TableHeaderPhone,
              property: 'customerPhone',
              hidden: !visibleColumns.includes(Columns.Phone),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              CellComponent: ({ value }: any): JSX.Element => <span>{formatPhoneNumber(value)}</span>,
              cellStyle: { whiteSpace: 'nowrap', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' },
            },
            {
              i18nKey: I18nKeys.TableHeaderCity,
              property: 'city',
              hidden: !visibleColumns.includes(Columns.City),
              CellComponent: ({ value }: any): JSX.Element => <span>{value}</span>,
              cellStyle: { whiteSpace: 'nowrap', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' },
            },
            {
              i18nKey: I18nKeys.TableHeaderDeliveryZip,
              property: 'zipCode',
              hidden: !visibleColumns.includes(Columns.Zip),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              CellComponent: ({ value }: any): JSX.Element => <span>{value}</span>,
              cellStyle: { whiteSpace: 'nowrap', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' },
            },
            {
              i18nKey: I18nKeys.TableHeaderBuildingStyle,
              property: 'buildingStyle',
              hidden: !visibleColumns.includes(Columns.Style),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              CellComponent: ({ value }: any): JSX.Element => <span>{value}</span>,
              cellStyle: { whiteSpace: 'nowrap', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' },
            },
            {
              i18nKey: I18nKeys.TableHeaderBuildingSize,
              property: 'buildingSize',
              hidden: !visibleColumns.includes(Columns.Size),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              CellComponent: ({ value }: any): JSX.Element => <span>{value}</span>,
              cellStyle: { whiteSpace: 'nowrap', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' },
            },
            {
              i18nKey: I18nKeys.TableHeaderEstimate,
              property: 'totalPrice',
              hidden: !visibleColumns.includes(Columns.Estimate),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              CellComponent: ({ value }: any): JSX.Element => <span>{formatPrice(value, currency, 2)}</span>,
              cellStyle: {
                whiteSpace: 'nowrap',
                maxWidth: '150px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'right',
              },
            },
            {
              i18nKey: I18nKeys.TableHeaderReferenceNumber,
              property: 'versionedEmailId',
              hidden: !visibleColumns.includes(Columns.Reference),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              CellComponent: ({ value }: any): JSX.Element => <span>{hyphenateString(value)}</span>,
              cellStyle: { whiteSpace: 'nowrap', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' },
            },
            {
              i18nKey: I18nKeys.TableHeaderSite,
              property: 'clientId',
              hidden: !(configurators && configurators.length > 1) || !visibleColumns.includes(Columns.Site),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              CellComponent: ({ value }: any): JSX.Element => <span>{getSiteFromClientId(value, configurators)}</span>,
              cellStyle: { whiteSpace: 'nowrap', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' },
            },
            {
              i18nKey: I18nKeys.TableHeaderDealer,
              property: 'dealerName',
              hidden:
                !dealersEnabled ||
                (!adminUser && orderDealerOptions.length < 2) ||
                !visibleColumns.includes(Columns.Dealer),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              CellComponent: ({ row }: any): JSX.Element =>
                !adminUser && !managerUser ? (
                  <span>{getOrderDealerFromOrder(row).name}</span>
                ) : (
                  <OrderDealerSelector
                    readonly={isIdearoomGroup}
                    dealer={getOrderDealerFromOrder(row)}
                    options={orderDealerOptions}
                    onOrderDealerChange={(orderDealer: OrderDealer): void => {
                      setNewOrderDealer(row.clientId, row.uuid, getOrderStatusLabelFromOrder(row), orderDealer);
                    }}
                  />
                ),
            },
            {
              i18nKey: I18nKeys.TableHeaderOwner,
              property: 'ownerName',
              hidden: (!adminUser && !managerUser) || !visibleColumns.includes(Columns.Owner),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              CellComponent: ({ row }: any): JSX.Element => (
                <OrderOwnerSelector
                  readonly={isIdearoomGroup}
                  owner={getOrderOwnerFromOrder(row, members)}
                  options={orderOwnerOptions}
                  onOrderOwnerChange={(orderOwner: OrderOwner): void => {
                    setNewOrderOwner(row.clientId, row.uuid, getOrderStatusLabelFromOrder(row), orderOwner);
                  }}
                />
              ),
            },
            {
              i18nKey: I18nKeys.TableHeaderStatus,
              property: 'orderStatusName',
              hidden: !visibleColumns.includes(Columns.Status),
              CellComponent: ({ row }: any): JSX.Element => (
                <OrderStatusSelector
                  readonly={isIdearoomGroup}
                  status={getOrderStatusFromOrder(row)}
                  onOrderStatusChange={(orderStatus: OrderStatus): void => {
                    setNewOrderStatus(row.clientId, row.uuid, orderStatus);
                  }}
                />
              ),
            },
            {
              i18nKey: I18nKeys.TableHeaderSubmitStatus,
              property: 'submitStatus',
              hidden: !visibleColumns.includes(Columns.SubmitStatus),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              CellComponent: ({ row }: any): JSX.Element => <span>{mapSubmitStatusToLabel(row?.submitStatus)}</span>,
              cellStyle: { whiteSpace: 'nowrap', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' },
            },
            {
              i18nKey: I18nKeys.TableHeaderDesign,
              property: 'link',
              hidden: !visibleColumns.includes(Columns.Design),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              CellComponent: ({ row, value }: any): JSX.Element => (
                <NewWindowLink link={row.orderDealerLink || value} />
              ),
            },
          ]}
          sortableProperties={sortableProperties}
          sortProperties={sortState}
          onSort={handleSortChanged}
          rows={filteredOrders}
          loading={loading}
          handleRowClick={(row): void => openDetailsDialog(row)}
        />
      </Hidden>
      <Hidden smUp>
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <div className={classes.orderCards} style={{ flex: '1 1 auto' }}>
            <AutoSizer>
              {({ height, width }: Size): JSX.Element => (
                <FixedSizeList
                  style={{ paddingBottom: '70px' }}
                  height={height}
                  width={width}
                  itemCount={filteredOrders.length}
                  itemSize={getListItemSize()}
                >
                  {LeadCard}
                </FixedSizeList>
              )}
            </AutoSizer>
          </div>
        </div>
      </Hidden>
    </div>
  );
};

const mapStateToProps = ({
  currentUser: { user = unknownUser, group = unknownGroup, availableDealers = [] },
  orders: { loading, orders, orderOwnerOptions, detailsDialogOpen, detailsState },
  search: { searchTerm },
  viewer: { visibleColumns },
}: AppState): StateProps => {
  const dealersEnabled = group.configurators
    ? group.configurators.some((c) =>
        getEnabledOnProperty(c.vendorData, ConfiguratorEnabledOnProps.DealerNetworkEnabled, true),
      ) && availableDealers.length > 0
    : false;
  const adminUser = isCurrentUserGroupAdmin(user, group, group);
  const managerUser = isCurrentUserManager(user);

  return {
    loading,
    orders,
    configurators: group.configurators || [],
    isIdearoomGroup: isIdeaRoomGroup(group.groupId),
    dealersEnabled,
    adminUser,
    managerUser,
    searchTerm,
    groupId: group.groupId,
    orderDealerOptions: availableDealers,
    orderOwnerOptions,
    visibleColumns,
    detailsDialogOpen,
    detailsState,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  loadOrders: (): void => {
    dispatch(fetchOrders());
  },
  handleSort: (sortProperties: SortProperty[]): void => {
    dispatch(setSortProperties(sortProperties));
  },
  handleExport: (): void => {
    dispatch(fetchOrdersCsv());
  },
  setupSecondaryActions: (secondaryActions: JSX.Element[]): void => {
    dispatch(setSecondaryActions(secondaryActions));
  },
  teardownSecondaryActions: (): void => {
    dispatch(setSecondaryActions([]));
  },
  loadOrderOwnerOptions: (groupId?: string): void => {
    dispatch(fetchOrderOwnerOptions(groupId));
  },
  setNewOrderDealer: (clientId: string, uuid: string, status: string, orderDealer: OrderDealer): void => {
    dispatch(setOrderDealer(clientId, uuid, status, orderDealer));
  },
  setNewOrderOwner: (clientId: string, uuid: string, status: string, orderOwner: OrderOwner): void => {
    dispatch(setOrderOwner(clientId, uuid, status, orderOwner));
  },
  setNewOrderStatus: (clientId: string, uuid: string, orderStatus: OrderStatus): void => {
    dispatch(setOrderStatus(clientId, uuid, orderStatus));
  },
  openDetailsDialog: (order: Order): void => {
    dispatch(openOrderDetailDialog(order));
  },
  closeDetailsDialog: (): void => {
    dispatch(closeOrderDetailDialog());
    removeQueryParam('uuid');
  },
  confirmLeadDeletion: (
    confirmAction: AnyAction,
    confirmationTitle: string,
    description: string,
    confirmButton: string,
  ): void => {
    dispatch(openConfirmationDialog([confirmAction], [], confirmationTitle, description, confirmButton));
    dispatch(openDialog({ dialog: Dialogs.Confirmation }));
  },
});

export const OrderReport = connect(mapStateToProps, mapDispatchToProps)(OrderReportComponent);
