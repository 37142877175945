import { Grid, FormControl, MenuItem, Switch, FormControlLabel } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { SiteDetail, SiteDetailField } from '../types/ClientData';
import { SiteDetailDataFields, advancedSectionFieldsMap, AuthenticationProvider } from '../constants/VendorData';
import { disabledSiteDetailField, hideSiteDetailField, ideaRoomOnlySiteDetailField } from '../utils/vendorDataUtils';
import { I18nKeys } from '../constants/I18nKeys';
import { LoadingSelect } from './LoadingSelect';
import { SiteInputLabel } from './SiteInputLabel';
import { IdeaRoomOnlyIndicator } from './IdeaRoomOnlyIndicator';

const useStyles = makeStyles({
  fieldContainer: {
    padding: '8px',
  },
  checkboxFieldContainer: {
    padding: '0 8px',
    '&:first-child': {
      paddingTop: '8px',
    },
    '&:last-child': {
      paddingBottom: '8px',
    },
  },
  inputLabel: {
    marginTop: '16px',
  },
  filledInput: {
    fontSize: 16,
    borderRadius: 0,
    height: '55px',
  },
});

interface Props {
  data?: SiteDetail;
  vendorPropChange: Function;
  isIdeaRoomUser: boolean;
  selectedGroupId: string;
}

export const SitesAdvancedSection: React.FC<Props> = ({
  data = {},
  vendorPropChange,
  isIdeaRoomUser = false,
  selectedGroupId,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const handlePropChange = (prop: string, value: string): void => {
    if (prop === SiteDetailDataFields.AuthenticationProvider) {
      vendorPropChange(prop, value, false, [
        {
          column: SiteDetailDataFields.SalesViewGroupId,
          value: value === AuthenticationProvider.Cognito ? selectedGroupId : null,
        },
      ]);
    } else {
      vendorPropChange(prop, value);
    }
  };

  return (
    <Grid container>
      {Object.entries(advancedSectionFieldsMap)
        .filter(([key]) => !hideSiteDetailField(key as SiteDetailDataFields, data, isIdeaRoomUser, selectedGroupId))
        .map(([key, field]) => {
          const { props: vendorProps = {}, switch: vendorDataSwitch = false, i18nKey } = field as SiteDetailField;
          const { options: vendorDataOptions = [], defaultValue } = vendorProps;

          if (vendorDataSwitch === true) {
            return (
              <Grid item xs={12} sm={6} className={classes.checkboxFieldContainer} key={key}>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <>
                        {ideaRoomOnlySiteDetailField(
                          key as SiteDetailDataFields,
                          data,
                          isIdeaRoomUser,
                          selectedGroupId,
                        ) ? (
                          <IdeaRoomOnlyIndicator />
                        ) : undefined}
                        <Switch
                          checked={!!data[key as SiteDetailDataFields]}
                          onChange={(e: any): void => {
                            handlePropChange(e.target.name, e.target.checked);
                          }}
                          name={key}
                          id={`${key}-switch`}
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                          disabled={disabledSiteDetailField(
                            key as SiteDetailDataFields,
                            data,
                            isIdeaRoomUser,
                            selectedGroupId,
                          )}
                        />
                      </>
                    }
                    label={t(i18nKey)}
                  />
                </FormControl>
              </Grid>
            );
          }

          return (
            <Grid item xs={12} sm={6} className={classes.fieldContainer} key={key}>
              <FormControl fullWidth>
                <SiteInputLabel
                  shrink
                  className={classes.inputLabel}
                  htmlFor={`${key}-select`}
                  i18nKey={i18nKey}
                  ideaRoomOnly={ideaRoomOnlySiteDetailField(
                    key as SiteDetailDataFields,
                    data,
                    isIdeaRoomUser,
                    selectedGroupId,
                  )}
                />
                <LoadingSelect
                  value={data[key as SiteDetailDataFields] || defaultValue}
                  onChange={(e: any): void => handlePropChange(e.target.name, e.target.value)}
                  inputProps={{
                    displayEmpty: false,
                    name: key,
                    id: `${key}-select`,
                  }}
                  variant="filled"
                  disabled={disabledSiteDetailField(key as SiteDetailDataFields, data, isIdeaRoomUser, selectedGroupId)}
                  alerttext={
                    disabledSiteDetailField(key as SiteDetailDataFields, data, isIdeaRoomUser, selectedGroupId)
                      ? t(I18nKeys.SitesProSubscriptionAlert)
                      : undefined
                  }
                  loading={false}
                >
                  {vendorDataOptions
                    .filter((option: any) => !option.isIdeaRoomOnly || (option.isIdeaRoomOnly && isIdeaRoomUser))
                    .map((option: any) => (
                      <MenuItem key={option.key} value={option.key}>
                        {t(option.label)}
                      </MenuItem>
                    ))}
                </LoadingSelect>
              </FormControl>
            </Grid>
          );
        })}
    </Grid>
  );
};
