import {
  CellRange,
  GridApi,
  SendToClipboardParams,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams,
  ProcessDataFromClipboardParams,
  ColDef,
  RowDragEndEvent,
  RowDragMoveEvent,
  ColumnApi,
  ProcessHeaderForExportParams,
  IRowNode,
  ICellEditorParams,
} from 'ag-grid-community';
import { AnyAction, Dispatch } from 'redux';
import { v1 as uuidV1 } from 'uuid';
import { TFunction } from 'react-i18next';
import _, { flatMap } from 'lodash';
import { toast } from 'react-toastify';
import Decimal from 'decimal.js';
import * as expr from 'jse-eval';
import {
  addClientDataRows,
  addFilterExceptionIds as addFilterExceptionIdsFunc,
  removeClientDataRows,
  setPaste,
  updateClientDataMetadata,
  UpdateClientDataMetadata,
  UpdateClientDataRow,
  updateClientDataRows,
} from '../ducks/clientDataSlice';
import {
  CellMetadata,
  CellMetadataFormulasDiffMap,
  ClientDataBranchMetadata,
  ClientDataTableRowDiff,
  ClientDataUserPreferences,
  ClipboardData,
  ColumnMetadata,
  Metadata,
  TableMetadata,
} from '../types/ClientData';
import {
  COLUMN_DELIMITER,
  CellMetadataProperty,
  ClientPublishedVersions,
  ColumnDataType,
  DoltDiffType,
  EXPRESSION_DEBUG_DELIMITER,
  ROW_DELIMITER,
  TABLE_FILTERS,
} from '../constants/ClientData';
import { addDispatchCommandToUndo } from './undoManagerUtils';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import {
  getProductFromClientDataId,
  getVendorFromClientId,
  isCarportView,
  mapClientAndDataTypeAndTableToUndoStackId,
  mapClientIdToProduct,
} from './clientIdUtils';
import { I18nKeys } from '../constants/I18nKeys';
import { ClientDataPreferences, User } from '../types/User';
import { TableData } from '../types/DataGrid';
import { LocalStorage } from '../constants/LocalStorage';
import { compoundCaseToTitleCase } from './stringUtils';
import { DefaultColumnFieldNames } from '../constants/ClientDataColumn';
import { AppRoutes } from '../constants/AppRoutes';
import { store } from '../index';
import { openConfirmationDialog } from '../ducks/confirmation';
import { openDialog } from '../ducks/dialogSlice';
import { Dialogs } from '../constants/Dialogs';
import { IClearGridFiltersStatusPanel } from '../components/ClearGridFilters';
import { getPathPart } from './urlUtils';

// Matches Dolt Decimal precision https://docs.dolthub.com/sql-reference/sql-support/data-description
export const Decimal65 = Decimal.clone({ precision: 65 });

interface CellRangeInfo {
  startRowIndex: number;
  endRowIndex: number;
  rowCount: number;
  columns: string[];
}

/**
 * Get all the row nodes from the current grid, rendered or not
 *
 * @param gridApi
 * @returns
 */
export const getAllGridRowNodes = (gridApi: GridApi): IRowNode<any>[] => {
  const gridRowNodes: IRowNode<any>[] = [];
  gridApi.forEachNode((node) => gridRowNodes.push(node));
  return gridRowNodes;
};

const NoneKey = 'none';
const isNoneKey = (key: string): boolean => key === NoneKey || key.endsWith(`-${NoneKey}`);

/**
 * Validate if the rowNode is able to be used to generate their icon.
 *
 * @returns
 */
export const isOptionAbleToGenerateIcon = (rowNode: IRowNode<any> | undefined) => {
  if (!rowNode) return false;

  const { isNoneOption, enabled, key } = rowNode.data;
  return 'imageUrl' in rowNode.data && enabled && !isNoneKey(key) && (isNoneOption == null || isNoneOption === 0);
};

/**
 * Get all the grid data from the current grid, rendered or not
 *
 * @param gridApi
 * @returns
 */
export const getAllGridData = (gridApi: GridApi): TableData[] => {
  const gridData: TableData[] = [];
  gridApi.forEachNode((node) => gridData.push(node.data));
  return gridData;
};

/**
 * Get the cell range info. Start and end row indexes and the row count
 *
 * @param cellRanges
 * @returns
 */
export const getCellRangeInfo = (cellRanges: CellRange[] | null | undefined): CellRangeInfo => {
  if (!cellRanges || !cellRanges.length) {
    return { startRowIndex: 0, endRowIndex: 0, rowCount: 0, columns: [] };
  }

  let startRowIndex = cellRanges ? cellRanges[0]?.startRow?.rowIndex || 0 : 0;
  let endRowIndex = cellRanges ? cellRanges[0]?.endRow?.rowIndex || 0 : 0;
  if (startRowIndex > endRowIndex) {
    [startRowIndex, endRowIndex] = [endRowIndex, startRowIndex];
  }
  const rowCount = endRowIndex - startRowIndex + 1;

  const columns = cellRanges[0].columns?.map((column) => column.getColId()) || [];

  return { startRowIndex, endRowIndex, rowCount, columns };
};

/**
 * Get metadata from the cell metadata
 * If the cell does not have metadata, return undefined
 *
 * @param cellMetadata
 * @param rowId
 * @param column
 * @returns
 */
export const getMetadataFromCellMetadata = (
  cellMetadata: CellMetadata[],
  rowId: string | number,
  column: string,
): Metadata | undefined => {
  if (!rowId) return undefined;
  const metadataRow = cellMetadata?.find(
    (cellMeta) => cellMeta.rowId && cellMeta.rowId.toString() === rowId.toString(),
  );
  if (metadataRow) {
    return metadataRow.metadata[column];
  }
  return undefined;
};

/**
 * Formula parser
 * Takes in a formula and parses the tokens, attempting to format them
 * Attempt to remove any spaces from the tokens and convert the token to lower case
 *
 * example formula: '={{ Column one }} {{ another Column }} {{ Yet Another Column }}'
 * example output: '={{columnone}} {{anothercolumn}} {{yetanothercolumn}}'
 *
 * @param formula
 * @returns
 */
export const parseFormula = (formula?: string) => {
  if (!formula) {
    return '';
  }

  const tokens = formula.match(/{{(.*?)}}/g);
  if (!tokens) {
    return formula;
  }

  let parsedFormula = formula;
  tokens.forEach((token) => {
    const parsedToken = token
      .split(' ')
      .map((word) => word.toLowerCase())
      .join('');
    parsedFormula = parsedFormula.replace(token, parsedToken);
  });

  return parsedFormula;
};

/**
 * Evaluate a mathematical operation given a context
 *
 * @param operation
 */
export const evaluateExpression = (expression: string, context: Record<string, any> = {}): unknown => {
  const expressionContext = {
    Math,
    includes: (arr: any[], val: any) => arr.includes(val),
    ...context,
  };

  const plugin = {
    name: 'Exponentiation',
    initEval(JseEval: any) {
      JseEval.addBinaryOp('**', (a: any, b: any) => a ** b);
      JseEval.addBinaryOp('^', (a: any, b: any) => a ** b);
    },
  };
  expr.registerPlugin(plugin);

  const { evaluate, parse } = expr;

  const parsedExpression = parse(expression);
  let exp;
  try {
    exp = evaluate(parsedExpression, expressionContext);
  } catch (e) {
    return false;
  }
  return exp;
};

/**
 * Evaluate a formula
 * Takes in a formula and a row of data and evaluates the formula.
 *
 * Adjecent cells are referenced by their column names with the following format:
 * {{columnName}}
 *
 * Mathemtical operations are supported with the following format:
 * [[operation]]
 *
 * example data: { columnOne: 1, anotherColumn: 2, yetAnotherColumn: 3 }
 *
 * example formula: '={{columnOne}} {{anotherColumn}} {{yetAnotherColumn}}'
 * example output: '1 2 3'
 *
 * example formula: '=[[{{columnOne}} + {{anotherColumn}}]] = {{yetAnotherColumn}}'
 * example output: '3 = 3'
 *
 * @param formula
 * @param data
 * @returns
 */
export const evaluateFormula = (formula?: string, data?: any): string => {
  if (!formula || !data) {
    return '';
  }

  let evaluatedFormula = formula.replace(/^=/, '');

  // Replace reference tokens with values from the data
  const tokens = formula.match(/{{(.*?)}}/g);
  if (tokens) {
    tokens.forEach((token) => {
      const lookupKey = token.replace(/{{|}}/g, '');
      let value = data[Object.keys(data).find((key) => key.toLowerCase() === lookupKey.toLowerCase()) || ''];
      if (value && typeof value === 'string' && value.startsWith('=')) {
        value = evaluateFormula(value, data);
      }
      evaluatedFormula = evaluatedFormula.replace(token, value === null ? '' : value).trim();
    });
  }

  // Evaluate mathematical operations
  const operations = evaluatedFormula.match(/\[\[(.*?)\]\]/g);
  if (operations) {
    operations.forEach((operation) => {
      let operationValue = '';
      try {
        // Remove the [] brackets, commas, and any currency symbols from the operation
        const formattedOperation = operation.replace(/\[\[|\]\]/g, '').replace(/[$€£¥₣,]/g, '');
        operationValue = `${evaluateExpression(formattedOperation)}`;
      } catch (e) {
        operationValue = 'ERROR';
        console.error(e);
      }
      evaluatedFormula = evaluatedFormula.replace(operation, operationValue);
    });
  }
  return evaluatedFormula;
};

/**
 * Determine if the cell metadata has a given property
 *
 * @param cellMetadata
 * @param rowId
 * @param colId
 * @param property
 * @returns {boolean}
 */
export const hasCellMetadataProperty = (
  cellMetadata: CellMetadata[] = [],
  rowId: string | number,
  colId: string,
  property: CellMetadataProperty,
): boolean => {
  if (!cellMetadata || !cellMetadata.length) return false;
  const metadata = getMetadataFromCellMetadata(cellMetadata, rowId, colId);
  if (!metadata || !metadata[property]) return false;
  return true;
};

/**
 * Get the property value from the cell metadata. If the cell does not have the property, return undefined
 *
 * exported for testing
 *
 * @param cellMetadata
 * @param rowId
 * @param column
 * @returns
 */
export const getPropertyFromCellMetadata = (
  cellMetadata: CellMetadata[],
  rowId: string | number,
  column: string,
  property: CellMetadataProperty,
): string | undefined => {
  const metadata = getMetadataFromCellMetadata(cellMetadata, rowId, column);
  if (metadata) {
    const prop = metadata[property];
    if (prop) {
      return prop;
    }
  }
  return undefined;
};

/**
 * AG Grid value getter for the client data
 * Checks if the cell has a formula from the cell metadata and if so, evaluates the formula
 * If the cell does not have a formula, returns the value from the cell
 *
 * Adjecent cells are referenced by their column names with the following format:
 * {{columnName}}
 *
 * @param params
 * @param cellMetadata
 * @returns
 */
export const columnValueGetter = (params: ValueGetterParams) => {
  const { data, colDef, context } = params;
  const { field: column = '' } = colDef;
  const cellMetadata = (context.cellMetadata || []) as CellMetadata[];

  return (
    getPropertyFromCellMetadata(
      cellMetadata,
      data[ClientDataFixedColumns.RowId],
      column,
      CellMetadataProperty.Formula,
    ) || data[column]
  );
};

/**
 * AG Grid value getter for main grid's index column
 *
 * @param params
 * @param tableData
 * @returns
 */
export const indexValueGetter = (params: ValueGetterParams) => {
  const { data: { [ClientDataFixedColumns.RowId]: rowId } = {} } = params;
  const { context } = params;
  const { displayDeletedRowIds = [] } = context;

  let index = -1;
  let deletedRowsCount = 0;
  params.api.forEachNode((node, nodeIndex) => {
    if (node.data.rowId === rowId) {
      index = nodeIndex - deletedRowsCount;
    } else if (displayDeletedRowIds.includes(node.data.rowId)) {
      deletedRowsCount += 1;
    }
  });

  if (index === -1) return '';
  return `${index + 1}`;
};

/**
 * AG Grid value getter for search result grids' index column
 * Attempts to get index from the main grid, otherwise falls back to index of search data
 *
 * @param params
 * @param searchData
 * @param rootGridApi
 * @returns
 */
export const searchIndexValueGetter = (params: ValueGetterParams, rootGridApi?: GridApi) => {
  const { data: { [ClientDataFixedColumns.RowId]: rowId } = {} } = params;
  const rootGridRow = rootGridApi?.getRowNode(rowId);

  let rootGridIndex;
  if (rootGridApi && rootGridRow) {
    rootGridIndex = rootGridApi.getValue(ClientDataFixedColumns.Index, rootGridRow);
  }
  return rootGridIndex || indexValueGetter(params);
};

/**
 * Gets an array of row ids from the selected cell range
 *
 * @param gridApi
 * @returns
 */
export const getRowIdsFromCellRange = (gridApi: GridApi): string[] => {
  const cellRanges = gridApi.getCellRanges();
  if (!cellRanges || !cellRanges.length) return [];

  const { startRowIndex, endRowIndex } = getCellRangeInfo(cellRanges);
  if (startRowIndex === undefined || endRowIndex === undefined) return [];

  return Array.from({ length: Math.abs(endRowIndex - startRowIndex) + 1 }, (_el, i) => startRowIndex + i)
    .map((i) => gridApi.getDisplayedRowAtIndex(i)?.data?.[ClientDataFixedColumns.RowId])
    .filter(Boolean);
};

/**
 * Update the cell metadata for a cell
 *
 * @param clientDataTableId
 * @param updates
 * @param dispatch
 * @param getUndoActions - optional function to get additional undo actions
 */
export const updateCellMetadata = (
  clientDataTableId: string,
  updates: UpdateClientDataMetadata[],
  dispatch: Dispatch<any>,
) => {
  const [{ cellsMetadata = [] } = {}] = updates;

  const filteredUpdates = updates.filter(({ colId }) => !([ClientDataFixedColumns.Index] as string[]).includes(colId));

  const undoUpdates = filteredUpdates.map((update) => ({
    ...update,
    value: getPropertyFromCellMetadata(cellsMetadata, update.rowId, update.colId, update.metadataProperty),
  }));

  addDispatchCommandToUndo(
    dispatch,
    [updateClientDataMetadata(undoUpdates)],
    [updateClientDataMetadata(filteredUpdates)],
    clientDataTableId,
    true,
  );
};

export const processUpdatedValues = (
  updates: { table?: string; data: any; column: string; oldValue: any; newValue: any }[],
  cellMetadata: CellMetadata[],
) => {
  const newRows: UpdateClientDataRow[] = [];
  const oldRows: UpdateClientDataRow[] = [];

  updates
    .filter(({ data }) => !data.rowIsReadOnly)
    .forEach(({ table, data, column, oldValue, newValue }) => {
      if (newValue === undefined || newValue === oldValue) return;
      let value = newValue;
      const undoFormula = getPropertyFromCellMetadata(
        cellMetadata,
        data[ClientDataFixedColumns.RowId],
        column,
        CellMetadataProperty.Formula,
      );
      const undoValue = undoFormula ? evaluateFormula(parseFormula(undoFormula), data) : oldValue;

      if (newValue && typeof newValue === 'string' && newValue.trim().startsWith('=')) {
        const parsedFormula = parseFormula(newValue);
        value = evaluateFormula(parsedFormula, data);
        oldRows.push({ table, rowData: data, column, value: undoValue, formula: undoFormula });
        newRows.push({ table, rowData: data, column, value, formula: newValue });
      } else {
        oldRows.push({ table, rowData: data, column, value: undoValue, formula: undoFormula });
        newRows.push({ table, rowData: data, column, value, formula: undefined });
      }
    });

  return { newRows, oldRows };
};

/**
 * Updates client data values to edit a cell and create actions for undo/redo.
 * Checks if the new value is a formula and if so, evaluates the formula
 * If the new value is not a formula, returns the value from the cell
 *
 * @param clientDataTableId
 * @param updates
 * @param cellMetadata
 * @param dispatch
 * @param getUndoActions - optional function to get additional undo actions
 */
export const updateValues = (
  clientDataTableId: string,
  updates: { table?: string; data: any; column: string; oldValue: any; newValue: any }[],
  cellMetadata: CellMetadata[],
  dispatch: Dispatch<any>,
  getUndoActions?: (rows: UpdateClientDataRow[]) => AnyAction[],
) => {
  const { newRows, oldRows } = processUpdatedValues(updates, cellMetadata);
  if (newRows.length > 0) {
    addDispatchCommandToUndo(
      dispatch,
      [updateClientDataRows(oldRows), ...(getUndoActions?.(oldRows) || [])],
      [updateClientDataRows(newRows), ...(getUndoActions?.(newRows) || [])],
      clientDataTableId,
      true,
    );
  }
};

/**
 * AG Grid value setter for the client data. Handles the value when the user edits a cell.
 *
 * @param clientDataTableId
 * @param params
 * @param cellMetadata
 * @param dispatch
 * @returns
 */
export const columnValueSetter = (
  clientDataTableId: string,
  params: ValueSetterParams,
  cellMetadata: CellMetadata[],
  dispatch: Dispatch<any>,
) => {
  try {
    const { data, colDef, oldValue, newValue } = params;
    const { field: column = '' } = colDef;

    updateValues(clientDataTableId, [{ data, column, oldValue, newValue }], cellMetadata, dispatch);

    return true;
  } catch (e) {
    console.error(`Failed to set value: `, e);
    return false;
  }
};

/**
 * AG Grid formatter for the client data
 *
 * @param params
 * @param showFormulas
 * @param cellMetadata
 * @returns
 */
export const columnFormatter = (params: ValueFormatterParams, showFormulas: boolean) => {
  const { data, colDef, context } = params;
  const { field: column = '', type } = colDef;
  const cellMetadata = (context.cellMetadata || []) as CellMetadata[];

  const formula = getPropertyFromCellMetadata(
    cellMetadata,
    data[ClientDataFixedColumns.RowId],
    column,
    CellMetadataProperty.Formula,
  );
  if (formula && showFormulas) {
    return formula;
  }
  let result = data[column];
  if (formula) {
    const parsedFormula = parseFormula(formula);
    result = evaluateFormula(parsedFormula, data);
  }
  if (
    type === ColumnDataType.Expression &&
    result !== null &&
    result !== undefined &&
    result.startsWith(EXPRESSION_DEBUG_DELIMITER)
  ) {
    result = result.replace(EXPRESSION_DEBUG_DELIMITER, '');
  }
  return result;
};

export const columnShouldNotBeCopied = (columnId: string): boolean =>
  ([ClientDataFixedColumns.Index, ClientDataFixedColumns.RowId] as string[]).includes(columnId);

/**
 * AG Grid cell copy value getter for the client data
 * Checks if the cell has a multi-line value, and if so, returns the value surrounded by quotes and delimits double quotes
 *
 * @param params
 * @param showFormulas
 * @returns
 */
export const copyToClipboard = ({ data, api, columnApi }: SendToClipboardParams, showFormulas = false): string => {
  // Split the data into a matrix of cells
  // One array for each row, with each cell value in the row
  let rawData = data.split('\r\n').map((row) => row.split('\t'));

  // Get selected columns
  const cellRanges = api.getCellRanges();
  const { columns: selectedColumns, startRowIndex, rowCount } = getCellRangeInfo(cellRanges);

  const allColumnsOrdered = (columnApi?.getAllGridColumns() || []).map((col) => col.getColId());
  // If index column is selected, all columns are selected
  const copyingEntireRow = selectedColumns[0] === ClientDataFixedColumns.Index;
  const copyingIndexColumn = copyingEntireRow && selectedColumns.length === 1;
  const selectedColumnsOrdered = allColumnsOrdered.filter((col) => copyingEntireRow || selectedColumns.includes(col));

  // In this case, the data is incorrectly only the row number, so we need to get the row data
  if (copyingIndexColumn) {
    const copiedColumns = selectedColumnsOrdered.filter((col) => !columnShouldNotBeCopied(col));
    const headers = copiedColumns.map((col) => compoundCaseToTitleCase(columnApi?.getColumn(col)?.getColId() || ''));

    // Copies the data correctly ordered and with invisible columns
    rawData = [
      // If index column name is present, should copy headers as well
      ...(data.toLowerCase().includes(ClientDataFixedColumns.Index) ? [headers] : []),
      ...Array.from({ length: rowCount }, (_el, i) => {
        const rowValues: string[] = [];
        const rowData = api.getDisplayedRowAtIndex(startRowIndex + i)?.data;
        copiedColumns.forEach((key) =>
          rowValues.push(rowData[key] !== undefined && rowData[key] !== null ? `${rowData[key]}` : ''),
        );
        return rowValues;
      }),
    ];
  } else {
    const selectedHiddenColumns = selectedColumnsOrdered.filter((col) => !columnApi.getColumn(col)?.isVisible());
    rawData = rawData.map((rowValues) => {
      let orderedRowValues = rowValues;

      // Hidden columns are in a different order, reorder them
      if (selectedHiddenColumns.length) {
        // The hidden columns are added to the start of the clipboard data and in reverse order from what they are on the grid. This appears to be the ag-grid default behavior.
        const hiddenRowValues = rowValues.slice(0, selectedHiddenColumns.length).reverse();
        const visibleRowValues = rowValues.slice(selectedHiddenColumns.length);
        orderedRowValues = selectedColumnsOrdered.reduce((reorderedValues, col) => {
          const nextValue = (selectedHiddenColumns.includes(col) ? hiddenRowValues : visibleRowValues).shift();
          if (nextValue === undefined || nextValue === null) return reorderedValues;
          return [...reorderedValues, nextValue];
        }, [] as string[]);
      }

      // Filter out columns that should not be copied if copying the entire row
      orderedRowValues = orderedRowValues.filter(
        (_cell, i) => !copyingEntireRow || !columnShouldNotBeCopied(selectedColumnsOrdered[i]),
      );

      return orderedRowValues;
    });
  }

  // Evaluate expressions if showFormulas is false
  const clipboardDataMatrix: ClipboardData[][] = [];
  if (!showFormulas) {
    rawData = rawData.map((rowValues, index) => {
      const rowIndex = startRowIndex + index;
      const rowData = api.getDisplayedRowAtIndex(rowIndex)?.data;

      const rowClipboardData: ClipboardData[] = [];
      const evaluatedRowData = rowValues.map((cell) => {
        if (cell.startsWith('=')) {
          const parsedFormula = parseFormula(cell);
          const evaluatedFormula = evaluateFormula(parsedFormula, rowData);
          rowClipboardData.push({
            value: evaluatedFormula,
            formula: cell,
          });
          return evaluatedFormula;
        }
        rowClipboardData.push({
          value: cell,
        });
        return cell;
      });

      clipboardDataMatrix.push(rowClipboardData);
      return evaluatedRowData;
    });
  }

  localStorage.setItem(LocalStorage.ClientDataClipboardData, JSON.stringify(clipboardDataMatrix));
  return rawData.map((row) => row.join('\t')).join('\r\n');
};

/**
 * Sorts the columns by their order in the tableMetadata
 *
 * @param columns
 * @param tableMetadata
 * @returns sorted columns by their order in the tableMetadata
 */
export const sortColumnsByOrder = (
  columns: (string | ColDef<any>)[],
  tableMetadata?: TableMetadata,
): (string | ColDef<any>)[] =>
  _.cloneDeep(columns).sort((colA, colB) => {
    // Sort columns by their order in the tableMetadata
    const colAMetadata = typeof colA === 'string' ? tableMetadata?.metadata[colA] : {};
    const colBMetadata = typeof colB === 'string' ? tableMetadata?.metadata[colB] : {};
    return (colAMetadata?.order || 0) - (colBMetadata?.order || 0);
  });

/**
 * Returns whether a specific column is visible for the current client-id
 * @param clientId
 * @param columnMetadata
 * @returns
 */
export const isColumnHiddenForClient = (clientId: string, columnMetadata: ColumnMetadata) =>
  columnMetadata.hide || (isCarportView(clientId) ? columnMetadata.carports === false : columnMetadata.sheds === false);

/**
 * Filters out columns for a table that are hidden in the tableMetadata
 *
 * @param clientId
 * @param columns
 * @param tableMetadata
 * @returns filtered columns
 */
export const filterHiddenColumns = (clientId: string, columns: string[], tableMetadata?: TableMetadata): string[] => {
  const columnMetadata = Object.entries(tableMetadata?.metadata || {});
  const hiddenColumns = columnMetadata
    .filter(([, meta]) => isColumnHiddenForClient(clientId, meta))
    .map(([column]) => column);
  return columns && Array.isArray(columns) ? columns.filter((column) => !hiddenColumns.includes(column)) : [];
};

/**
 * Combines the table metadata with the user metadata
 *
 * @param tableMetadata current table metadata
 * @returns
 */
export const combineTableAndUserMetadata = (
  clientDataPreferences: ClientDataPreferences = {},
  tableMetadata?: TableMetadata,
): TableMetadata => {
  const tableMetadataClone = _.cloneDeep(tableMetadata);
  const clientDataPreferencesClone = _.cloneDeep(clientDataPreferences);
  const { tableName = '', formattedTableName = '', metadata } = tableMetadataClone || {};
  const userTableMetadata = clientDataPreferencesClone[tableName] || {};
  return { ...tableMetadataClone, tableName, formattedTableName, metadata: _.merge(metadata, userTableMetadata) };
};

/**
 * Copy the entire row to the clipboard.
 * Delimits each cell with a tab and each row with a new line. If the cell has a multi-line value, it is surrounded by quotes and delimits double quotes
 *
 * Used for copying the entire row of a search result.
 *
 * @param data
 * @returns
 */
export const copyEntireRowToClipboard = (
  clientId: string,
  rowNodes: IRowNode<any>[],
  clientDataPreferences?: ClientDataPreferences,
  tableMetadata?: TableMetadata,
  withHeaders = false,
): string => {
  const combinedTableMetadata = combineTableAndUserMetadata(clientDataPreferences, tableMetadata);
  const visibleColumns = filterHiddenColumns(clientId, Object.keys(rowNodes[0].data), combinedTableMetadata);
  const orderedColumns = sortColumnsByOrder(visibleColumns, combinedTableMetadata);

  const headers = withHeaders ? `${visibleColumns.join(COLUMN_DELIMITER)}${ROW_DELIMITER}` : '';
  const rows = rowNodes.map((row) => {
    const { data } = row;
    // Copy to clipboard only visible columns in the order of the tableMetadata
    const filteredData = Object.entries(data)
      .filter(([col]) => !columnShouldNotBeCopied(col) && orderedColumns.includes(col))
      .sort(([colA], [colB]) => orderedColumns.indexOf(colA) - orderedColumns.indexOf(colB));
    return filteredData.map(([, cell]) => cell).join(COLUMN_DELIMITER);
  });
  return `${headers}${rows.join(ROW_DELIMITER)}`;
};

/**
 * Grid should be readonly if the branch is Main and there's an Unpublished branch
 * @param clientDataBranch
 * @param activeBranches
 * @returns
 */
export const cantEditBranchData = (
  clientDataBranch: ClientDataBranch | undefined = ClientDataBranch.Main,
  activeBranches: ClientDataBranchMetadata[],
) =>
  clientDataBranch === ClientDataBranch.Main &&
  activeBranches.some((branch) => branch.branchType === ClientDataBranch.Unpublished);

/**
 * Takes an object and attempts to convert it to another object based on the provided columns.
 *
 * @param {string} client
 * @param {string[]} columns
 * @param {number} order
 * @param {TableData} data
 * @returns
 */
export const generateRowFromObject = (client: string, columns: string[], order?: Decimal, data?: TableData) => {
  const row: TableData = {
    [ClientDataFixedColumns.RowId]: uuidV1(),
    [ClientDataFixedColumns.Order]: order?.toString() || '1',
  };
  columns.forEach((column: string) => {
    switch (column) {
      case ClientDataFixedColumns.Enabled:
        row[column] = (data && data[ClientDataFixedColumns.Enabled]) || 0;
        break;
      case ClientDataFixedColumns.Order:
        break;
      case ClientDataFixedColumns.Product:
        row[column] = mapClientIdToProduct(client).toLowerCase();
        break;
      case ClientDataFixedColumns.RowId:
        break;
      case ClientDataFixedColumns.SourceSheet:
        row[column] = 'grid';
        break;
      case ClientDataFixedColumns.SupplierKey:
        row[column] = getVendorFromClientId(client) || null;
        break;
      case ClientDataFixedColumns.VendorKey:
        row[column] = getVendorFromClientId(client) || null;
        break;
      case ClientDataFixedColumns.ModifyTimestamp:
        row[column] = new Date().toISOString();
        break;
      case ClientDataFixedColumns.Index:
        break;
      default:
        if (data && data[column] !== undefined) {
          row[column] = data[column];
        }
        break;
    }
  });
  return row;
};

/**
 * Builds the branch's authors summary label e.g. (You and 1 Person)
 * @param t
 * @param activeBranches
 * @param branch
 * @param user
 * @returns
 */
export const getBranchAuthorsSummaryLabel = (
  t: TFunction,
  activeBranches: ClientDataBranchMetadata[],
  branch: ClientDataBranch,
  user: User,
): string => {
  const { authors = [], changedTables = [] } = activeBranches.find((metadata) => metadata.branchType === branch) || {};
  const loggedUserIsAnAuthor = authors.some((authorName) => authorName === user.name);

  let branchChangeAuthorsI18nKey =
    changedTables.length > 0
      ? I18nKeys.ClientDataBranchMenuChangesAnotherPerson
      : I18nKeys.ClientDataBranchMenuChangesNoChanges;
  if (loggedUserIsAnAuthor) {
    branchChangeAuthorsI18nKey =
      authors.length > 1
        ? I18nKeys.ClientDataBranchMenuChangesYouAndAnother
        : I18nKeys.ClientDataBranchMenuChangesJustYou;
  }
  const anotherAuthorsCount = loggedUserIsAnAuthor ? authors.length - 1 : authors.length;

  return t(branchChangeAuthorsI18nKey, { count: anotherAuthorsCount, authorsCount: anotherAuthorsCount }); // "count" is for i18n pluralization using _other.
};

export const getRowDiffFromColumn = (columnName: string) => `from_${columnName}`;

export const getRowDiffToColumn = (columnName: string) => `to_${columnName}`;

/**
 * Returns the from/to values for a specific column in a rowDiff
 * @param rowDiff
 * @param columnName
 * @returns
 */
export const getRowDiffColumnToFromValues = (
  rowDiff: ClientDataTableRowDiff,
  columnName: string,
): { from: any; to: any } | undefined => {
  if (getRowDiffFromColumn(columnName) in rowDiff) {
    const from = rowDiff[getRowDiffFromColumn(columnName)];
    const to = rowDiff[getRowDiffToColumn(columnName)];

    return { from, to };
  }
  return undefined;
};

/**
 * Gets the client data user preferences from local storage
 *
 * @returns
 */
export const getClientDataUserPreferences = (): ClientDataUserPreferences => {
  const clientDataUserPreferences = localStorage.getItem(LocalStorage.ClientData);
  return clientDataUserPreferences ? JSON.parse(clientDataUserPreferences) : {};
};

/**
 * Gets a property from the client data user preferences
 *
 * @param property
 * @returns
 */
export const getClientDataUserPreferencesProperty = (property: keyof ClientDataUserPreferences) => {
  const clientDataUserPreferences = getClientDataUserPreferences();
  return clientDataUserPreferences[property];
};

/**
 * Sets the client data user preferences in local storage
 *
 * @param clientDataUserPreferences
 * @returns
 */
export const setClientDataUserPreferences = (clientDataUserPreferences: ClientDataUserPreferences) => {
  localStorage.setItem(LocalStorage.ClientData, JSON.stringify(clientDataUserPreferences));
};

/**
 * Set property in client data user preferences
 *
 * @param property
 * @param value
 * @returns
 */
export const setClientDataUserPreferencesProperty = (property: keyof ClientDataUserPreferences, value: any) => {
  const clientDataUserPreferences = getClientDataUserPreferences();
  clientDataUserPreferences[property] = value;
  setClientDataUserPreferences(clientDataUserPreferences);
};

/**
 * Gets the table filter model from session storage
 *
 * @param table
 * @returns
 */
export const getTableFilterModelFromSessionStorage = (
  table: string,
): {
  [key: string]: any;
} | null => {
  const sessionTableFilters = sessionStorage.getItem(TABLE_FILTERS);

  if (sessionTableFilters) {
    const tableFilters = JSON.parse(sessionTableFilters);
    if (tableFilters[table]) {
      return tableFilters[table];
    }
  }
  return null;
};

/**
 * Sets the table filter model in session storage
 *
 * @param table
 * @param filterModel
 */
export const setTableFilterModelInSessionStorage = (
  table: string,
  filterModel: {
    [key: string]: any;
  },
): void => {
  const sessionTableFilters = sessionStorage.getItem(TABLE_FILTERS);
  const tableFilters = sessionTableFilters ? JSON.parse(sessionTableFilters) : {};
  tableFilters[table] = filterModel;
  sessionStorage.setItem(TABLE_FILTERS, JSON.stringify(tableFilters));
};

/**
 * Clears the table filter model from session storage for a specific table
 *
 * @param table
 * @returns
 */
export const clearTableFilterModelFromSessionStorage = (table: string): void => {
  const sessionTableFilters = sessionStorage.getItem(TABLE_FILTERS);

  if (sessionTableFilters) {
    const tableFilters = JSON.parse(sessionTableFilters);
    if (tableFilters[table]) {
      delete tableFilters[table];
      sessionStorage.setItem(TABLE_FILTERS, JSON.stringify(tableFilters));
    }
  }
};

/**
 * Add rows to the list of rows for filters to ignore. Mainly used for adding new rows while a filter
 * is active.
 *
 * @param {TableData[]} rows rows whose ids should be added to the list of rows to ignore
 * @param {Dispatch<AnyAction>} dispatch redux dispatch function
 * @returns {void}
 */
export const addFilterExceptionIds = (rows: TableData[], dispatch: Dispatch<AnyAction>) => {
  dispatch(addFilterExceptionIdsFunc(rows.map(({ [ClientDataFixedColumns.RowId]: rowId }) => rowId).filter(Boolean)));
};

/**
 * Gets the order value for a row by index
 *
 * @param {number | null} index row index
 * @param {GridApi} api grid api
 * @returns {number} decimal order
 */
export const getOrderByIndex = (index: number | null, api: GridApi): Decimal => {
  const rowModel = api.getModel();

  if (index === null) return new Decimal65(0);

  const order = rowModel.getRow(index)?.data?.order;
  return new Decimal65(order || 0);
};

/**
 * Processes the data from the grid to the clipboard
 *
 * @param clientDataTableId
 * @param params Process Data From Clipboard event params
 * @param param1 Cell metadata and dispatch function
 * @returns
 */
export const processDataFromClipboard = (
  params: ProcessDataFromClipboardParams,
  {
    cellMetadata: existingCellMetadata,
    dispatch,
    pasteOptions: { formulas: pasteFormulas = true },
  }: { cellMetadata: CellMetadata[]; dispatch: Dispatch<any>; pasteOptions: { formulas?: boolean } },
) => {
  const { data: cellMatrix, api, columnApi, context: { clientId, clientDataType, selectedTable } = {} } = params;
  const clientDataTableId = mapClientAndDataTypeAndTableToUndoStackId(clientId, clientDataType, selectedTable);

  const { startRowIndex, endRowIndex, columns: selectedColumns } = getCellRangeInfo(api.getCellRanges());
  const rowCount = api.getModel()?.getRowCount();

  // Columns must be ordered for correct index
  const allColumnsOrdered = (columnApi?.getAllGridColumns() || [])
    .map((col) => col.getColId())
    .filter((col) => col !== ClientDataFixedColumns.Index)
    // Sort so that columns pinned left are first, then unpinned, then pinned right
    .sort((a, b) => {
      const [aOrder, bOrder] = [a, b].map((col) => {
        const column = columnApi?.getColumn(col);
        if (column?.isPinnedLeft()) return -1;
        if (column?.isPinnedRight()) return 1;
        return 0;
      });
      return aOrder - bOrder;
    });
  const selectedColumnsOrdered = allColumnsOrdered.filter((col) => selectedColumns.includes(col));
  const [firstColumn] = selectedColumnsOrdered;
  const columnStartIndex = allColumnsOrdered.indexOf(firstColumn);

  if (startRowIndex === undefined || columnStartIndex === -1) return null;

  const newRows: TableData[] = [];
  const updatedRows: UpdateClientDataRow[] = [];
  const oldRows: UpdateClientDataRow[] = [];

  const clipboardDataMatrix = JSON.parse(
    localStorage.getItem(LocalStorage.ClientDataClipboardData) || '[]',
  ) as ClipboardData[][];

  let copyFormula = false;
  let gridDataMatrix = cellMatrix;
  // After joining with delimiters, compare the grid data to the clipboard data
  const joinedGridData = gridDataMatrix.map((row) => row.join(COLUMN_DELIMITER)).join(ROW_DELIMITER);
  const joinedClipboardData = clipboardDataMatrix
    .map((row) => row.map((cell) => cell.value).join(COLUMN_DELIMITER))
    .join(ROW_DELIMITER);
  if (joinedGridData === joinedClipboardData) {
    copyFormula = pasteFormulas;
    // If the data is the same, use the clipboard data matrix to get rid of any extra rows/columns
    // from \t and \n characters
    if (
      gridDataMatrix.length !== clipboardDataMatrix.length ||
      gridDataMatrix.every((row, i) => row.length !== clipboardDataMatrix[i].length)
    ) {
      gridDataMatrix = clipboardDataMatrix.map((row) => row.map((cell) => cell.value));
    }
  }

  dispatch(setPaste({ formulas: true }));

  Array.from({ length: Math.max(endRowIndex - startRowIndex + 1, gridDataMatrix.length) }).forEach((_el, i) => {
    // Find the row where the data will be pasted
    const { id: existingRowId, data = {} } = api.getDisplayedRowAtIndex(startRowIndex + i) || {};
    const addRowToEnd = !existingRowId && rowCount && rowCount - 1 < startRowIndex + i;

    // If the row is read only, or if a new row shouldn't be added and the row doesn't exist, ignore it
    if (data.rowIsReadOnly || (!existingRowId && !addRowToEnd)) return null;
    const rowId = existingRowId || uuidV1();

    // Repeat the cell matrix if there are more rows than cells
    const [row, rowClipboardData] = [gridDataMatrix, clipboardDataMatrix].map(
      (matrix) => matrix[i % matrix.length] || [],
    );

    const pastedRowData = (row as string[]).reduce((acc, value: string | number | null, j, fullRow: string[]) => {
      // If ran out columns, ignore this value
      if (columnStartIndex + j >= allColumnsOrdered.length) return acc;

      const column = allColumnsOrdered[columnStartIndex + j];

      if (value === undefined || value === null) return acc;

      const cellClipboardData = (rowClipboardData[j % rowClipboardData.length] || {}) as ClipboardData;
      let formula = copyFormula ? cellClipboardData?.formula : undefined;
      if (!formula && typeof value === 'string' && value.trim().startsWith('=')) {
        // The pasted value itself is a formula
        formula = value;
      }

      const changedRowData = { ...acc, [allColumnsOrdered[columnStartIndex + j]]: value };
      // If the row already exists, update the row, otherwise add a new row
      if (existingRowId) {
        updatedRows.push({
          rowData: { ...data, ...changedRowData, rowId },
          column,
          value,
          formula,
        });
        const oldFormula = getPropertyFromCellMetadata(
          existingCellMetadata,
          rowId,
          column,
          CellMetadataProperty.Formula,
        );
        oldRows.push({
          rowData: { ...data, ...changedRowData, rowId },
          column,
          value: data[column],
          formula: oldFormula,
        });
      } else {
        const lastDisplayedRowOrder = getOrderByIndex(rowCount - 1, api);
        const previousRowOrder = _.findLast(updatedRows, ({ rowData = {} }) => rowData.rowId !== rowId)?.rowData[
          ClientDataFixedColumns.Order
        ];
        // Find the last row update for a row that isn't the current row's order
        const previousRowOrderDecimal = previousRowOrder ? new Decimal65(previousRowOrder) : undefined;
        const newRowData = generateRowFromObject(
          clientId,
          allColumnsOrdered,
          (previousRowOrderDecimal || lastDisplayedRowOrder).ceil().plus(1),
          changedRowData as TableData,
        );
        updatedRows.push({
          rowData: { ...newRowData, rowId },
          column,
          value,
          formula,
        });
        if (j === fullRow.length - 1) newRows.push({ ...newRowData, rowId });
      }
      return changedRowData;
    }, {});

    return { ...data, ...pastedRowData, rowId };
  });

  addFilterExceptionIds(newRows, dispatch);
  addDispatchCommandToUndo(
    dispatch,
    [updateClientDataRows(oldRows), removeClientDataRows({ rows: newRows })],
    [updateClientDataRows(updatedRows)],
    clientDataTableId,
    true,
  );
  return null;
};

/**
 * Triggers adding new rows of data and adds to the undo manager
 *
 * @param clientDataTableId
 * @param rows
 * @param addIndex
 * @param dispatch
 */
export const addRows = (clientDataTableId: string, rows: TableData[], dispatch: Dispatch<AnyAction>): void => {
  addFilterExceptionIds(rows, dispatch);

  addDispatchCommandToUndo(
    dispatch,
    [removeClientDataRows({ rows })],
    [addClientDataRows({ rows })],
    clientDataTableId,
    true,
  );
};

/**
 * Triggers removing rows of data and adds to the undo manager
 *
 * @param clientDataTableId
 * @param rows
 * @param removeIndex
 * @param dispatch
 */
export const removeRows = (clientDataTableId: string, rows: TableData[], dispatch: Dispatch<AnyAction>): void => {
  const rowsToRemove = rows.filter((row) => !row.rowIsReadOnly);
  if (rowsToRemove.length > 0) {
    addDispatchCommandToUndo(
      dispatch,
      [addClientDataRows({ rows: rowsToRemove })],
      [removeClientDataRows({ rows: rowsToRemove })],
      clientDataTableId,
      true,
    );
  }
};

/**
 * Gets a list of decimal orders for a group of rows to be inserted
 *
 * @param {number} prevOrder order of the row before the group of rows to be inserted
 * @param {number} nextOrder order of the row after the group of rows to be inserted
 * @param {number} count number of rows to be placed
 * @param {GridApi} api grid api
 * @returns {number[]} array of decimal orders
 */
export const getRowOrders = (
  prevOrder: Decimal = new Decimal65(0),
  nextOrder: Decimal = new Decimal65(0),
  count: number,
  api: GridApi,
): Decimal[] => {
  const rowModel = api.getModel();
  const totalRows = rowModel.getRowCount();

  const lastOrder = getOrderByIndex(totalRows - 1, api);

  let increment = nextOrder.minus(prevOrder).div(count + 1);
  if (increment.lessThanOrEqualTo(0)) increment = new Decimal65(1);

  return Array.from({ length: count }, (_el, i) => {
    let start = prevOrder;
    if (nextOrder.equals(0)) start = prevOrder.greaterThan(lastOrder) ? prevOrder : lastOrder;
    const order = start.plus(increment.mul(i + 1));
    return order;
  });
};

/**
 * Handles a row drag move event by adjusting the displayed rows based on the selected
 * (dragged) range
 *
 * @param {RowDragMoveEvent} params
 * @param {TableData[]} tableData
 * @returns {void}
 */
export const onRowDragMove = ({ api, overNode }: RowDragMoveEvent, tableData: TableData[]): void => {
  const draggedRows = api.getSelectedRows();

  const prevRowId = overNode?.data?.[ClientDataFixedColumns.RowId];
  if (
    !draggedRows?.length ||
    draggedRows.some(({ [ClientDataFixedColumns.RowId]: draggedRowId }) => draggedRowId === prevRowId)
  )
    return;

  const updatedRows = tableData.filter(
    ({ [ClientDataFixedColumns.RowId]: rowId }) =>
      !draggedRows.some(({ [ClientDataFixedColumns.RowId]: draggedRowId }) => draggedRowId === rowId),
  );
  const prevIndex = updatedRows.findIndex(({ [ClientDataFixedColumns.RowId]: rowId }) => rowId === prevRowId);
  // If prevIndex is -1, the row is being dragged to the end of the table
  if (prevIndex === -1) updatedRows.push(...draggedRows);
  else updatedRows.splice(prevIndex, 0, ...draggedRows);

  // The actual "update" to the data's order is done in the RowDragEnd event
  api.setRowData(updatedRows);
};

/**
 * Updates a row's data to a new order value following a drag event
 *
 * @param {RowDragEndEvent} params
 * @param {CellMetadata[]} cellMetadata
 * @param {Dispatch<any>} dispatch
 */
export const onRowDragEnd = (
  { api }: RowDragEndEvent,
  clientDataTableId: string,
  cellMetadata: CellMetadata[],
  dispatch: Dispatch<any>,
): void => {
  const draggedRows: TableData[] = api
    .getSelectedRows()
    .sort(({ order: a = 0 }, { order: b = 0 }) => new Decimal65(a).minus(new Decimal65(b)).trunc().toNumber());
  const draggedCount = draggedRows?.length || 0;

  if (!draggedCount) return;

  const [firstRow] = draggedRows;
  const rowModel = api.getModel();
  const firstRowIndex = rowModel.getRowNode(firstRow[ClientDataFixedColumns.RowId])?.rowIndex || 0;
  // The prevRowIndex will be null when the dragged rows are at the top of the table
  const prevRowIndex = rowModel.getRow(firstRowIndex - 1) ? firstRowIndex - 1 : null;
  const nextRowIndex = prevRowIndex === null ? draggedCount : prevRowIndex + draggedCount + 1;

  // Rows have already been moved in the onRowDragMove event, so consider that when finding indices
  const [prevOrder, nextOrder] = [prevRowIndex, nextRowIndex].map((i) => getOrderByIndex(i, api));
  const newOrders = getRowOrders(prevOrder, nextOrder, draggedCount, api);
  const updates = draggedRows.map((draggedRow, i) => ({
    data: { ...draggedRow, order: newOrders[i] },
    column: ClientDataFixedColumns.Order,
    oldValue: draggedRow?.order,
    newValue: newOrders[i],
  }));
  updateValues(clientDataTableId, updates, cellMetadata, dispatch);
};

/**
 * Autosizes the all columns that do not have a saved width in the user's preferences
 *
 * @param {ColumnApi} columnApi
 * @param {TableMetadata} tableMetadata
 */
export const autosizeColumns = (
  columnApi?: ColumnApi,
  clientDataPreferences?: ClientDataPreferences,
  tableMetadata?: TableMetadata,
): void => {
  const combinedTableMetadata = combineTableAndUserMetadata(clientDataPreferences, tableMetadata);
  const columnIds = (columnApi?.getColumns() || [])
    .map((column) => column.getColId())
    .filter((col) => col !== DefaultColumnFieldNames.Index);

  // Only autosize columns that do not have a saved width
  const autoSizedColumns = columnIds?.filter((column) => {
    const columnMetadata = typeof column === 'string' ? combinedTableMetadata?.metadata[column] : {};
    return !columnMetadata?.width;
  });
  columnApi?.autoSizeColumns(autoSizedColumns, false);
};

/**
 * Processes header name for clipboard for "Copy with Headers"
 *
 * @param {ProcessHeaderForExportParams} params
 * @returns {string} header name or column id
 */
export const processHeaderForClipboard = ({ column, columnApi }: ProcessHeaderForExportParams): string =>
  compoundCaseToTitleCase(columnApi?.getColumn(column)?.getColId() || '');

/**
 * Check if the client has datasets to update
 *
 * @param {ClientPublishedVersions} clientPublishedVersions
 * @returns {boolean} true if the client has datasets to update
 */
export const clientHasDatasetsToUpdate = (clientPublishedVersions?: ClientPublishedVersions): boolean =>
  !!(
    clientPublishedVersions &&
    clientPublishedVersions.latestVersion &&
    ((clientPublishedVersions.vendors &&
      clientPublishedVersions.vendors.some(
        (vendor) => !vendor.published || vendor.published.structureVersion !== clientPublishedVersions.latestVersion,
      )) ||
      (clientPublishedVersions.suppliers &&
        clientPublishedVersions.suppliers
          .filter((supplier) => supplier.latestVersion)
          .some((supplier) => !supplier.published || supplier.published.structureVersion !== supplier.latestVersion)) ||
      (clientPublishedVersions.system?.publishedVersion &&
        clientPublishedVersions.system?.publishedVersion !== clientPublishedVersions.system?.latestVersion))
  );

/**
 *
 *
 * @param {ClientPublishedVersions} clientPublishedVersions
 * @returns {string} version key to skip
 */
const clientPublishedVersionsToSkipKey = (clientPublishedVersions: ClientPublishedVersions): string =>
  `${clientPublishedVersions.system?.latestVersion}-${clientPublishedVersions.suppliers
    ?.map((supplier) => supplier.latestVersion)
    .join('-')}`;

/**
 * Get client data new supplier updates from local storage
 *
 * @returns {ClientDataNewSupplierUpdatesDialogSkip}
 */
const getClientDataNewSupplierUpdatesDialogSkip = () =>
  JSON.parse(localStorage.getItem(LocalStorage.ClientDataNewSupplierUpdatesDialogSkip) || '{}');

/**
 * Check if there are new supplier updates that should be skipped
 *
 * @param {string} clientId
 * @param {ClientPublishedVersions} clientPublishedVersions
 * @returns {boolean} true if the client has datasets to update
 */
export const shouldSkipClientDataNewSupplierUpdatesDialog = (
  clientId: string,
  clientPublishedVersions?: ClientPublishedVersions,
): boolean => {
  if (!clientPublishedVersions) return true;
  const publishedVersionsKey = clientPublishedVersionsToSkipKey(clientPublishedVersions);
  return getClientDataNewSupplierUpdatesDialogSkip()[clientId] === publishedVersionsKey;
};

/**
 * Sets the client published versions to local storage
 *
 * @param {string} clientId
 * @param {ClientPublishedVersions} clientPublishedVersions
 * @returns {void}
 */
export const setClientDataNewSupplierUpdatesDialogSkip = (
  clientId: string,
  clientPublishedVersions?: ClientPublishedVersions,
): void => {
  if (!clientPublishedVersions) return;
  const publishedVersionsKey = clientPublishedVersionsToSkipKey(clientPublishedVersions);
  localStorage.setItem(
    LocalStorage.ClientDataNewSupplierUpdatesDialogSkip,
    JSON.stringify({ ...getClientDataNewSupplierUpdatesDialogSkip(), [clientId]: publishedVersionsKey }),
  );
};

/**
 * Get client data link based on the parameters passed in
 *
 * URL format: /portal/data/:groupId/:clientDataId/:clientDataBranch/:table
 *
 * @param {string} groupId
 * @param {string} clientDataId
 * @param {string} clientDataBranch
 * @param {string} table
 */
export const getClientDataLink = (
  groupId: string | undefined,
  clientDataId: string | undefined,
  clientDataBranch: string | undefined,
  table: string | undefined,
): string => {
  const baseURL = `${window.location.protocol}//${window.location.host}${AppRoutes.ClientData}`;

  if (!groupId) return baseURL;

  if (!clientDataId) return `${baseURL}/${groupId}`;

  if (!clientDataBranch) return `${baseURL}/${groupId}/${clientDataId}`;

  if (!table) return `${baseURL}/${groupId}/${clientDataId}/${clientDataBranch}`;

  return `${baseURL}/${groupId}/${clientDataId}/${clientDataBranch}/${table}`;
};

/**
 * Get the current groupId from the URL
 *
 * URL format: /portal/data/:groupId/:clientDataId/:clientDataBranch/:table
 *
 * @returns {string} groupId
 */
export const getGroupIdFromUrl = (): string => getPathPart(3);

/**
 * Get the current clientDataId from the URL
 *
 * URL format: /portal/data/:groupId/:clientDataId/:clientDataBranch/:table
 *
 * @returns {string} clientDataId
 */
export const getClientDataIdFromUrl = (): string => getPathPart(4);

/**
 * Get the current clientDataBranch from the URL
 *
 * URL format: /portal/data/:groupId/:clientDataId/:clientDataBranch/:table
 *
 * @returns {string} clientDataId
 */
export const getClientDataBranchFromUrl = (): string => getPathPart(5);

/**
 * Get the current table from the URL
 *
 * URL format: /portal/data/:groupId/:clientDataId/:clientDataBranch/:table
 *
 * @returns {string} table
 */
export const getClientDataTableFromUrl = (): string => getPathPart(6);

/**
 * Set the clientDataId in the URL
 *
 * URL format: /portal/data/:groupId/:clientDataId/:clientDataBranch/:table
 *
 * @param {string} clientDataId clientId:product (carportview-eagle:supplier)
 * @returns {void}
 */
export const setClientDataIdInUrl = (clientDataId: string): void => {
  const groupId = getGroupIdFromUrl();
  const clientDataBranch = getClientDataBranchFromUrl();
  const clientTable = getClientDataTableFromUrl();
  // Get the query string from the URL
  const queryString = window.location.search;
  if (!groupId) return;

  const currentProduct = getProductFromClientDataId(getClientDataIdFromUrl());
  const clientProduct = getProductFromClientDataId(clientDataId);

  window.history.replaceState(
    null,
    '',
    `${AppRoutes.ClientData}/${groupId}/${clientDataId}${clientDataBranch ? `/${clientDataBranch}` : ''}${
      currentProduct === clientProduct && clientDataBranch && clientTable ? `/${clientTable}${queryString}` : ''
    }`,
  );
};

/**
 * Set the clientDataBranch in the URL
 *
 * URL format: /portal/data/:groupId/:clientDataId/:clientDataBranch/:table
 *
 * @param {string} clientDataBranch
 * @returns {void}
 */
export const setClientDataBranchInUrl = (clientDataBranch: string | ClientDataBranch | undefined): void => {
  const groupId = getGroupIdFromUrl();
  const clientDataId = getClientDataIdFromUrl();
  const clientTable = getClientDataTableFromUrl();
  // Get the query string from the URL
  const queryString = window.location.search;
  if (!groupId || !clientDataId || !clientDataBranch) return;
  window.history.replaceState(
    null,
    '',
    `${AppRoutes.ClientData}/${groupId}/${clientDataId}/${clientDataBranch}${
      clientTable ? `/${clientTable}${queryString}` : ''
    }`,
  );
};

/**
 * Set the table in the URL
 *
 * URL format: /portal/data/:groupId/:clientDataId/:clientDataBranch/:table
 *
 * @param {string} table
 * @returns {void}
 */
export const setClientDataTableInUrl = (table: string): void => {
  const groupId = getGroupIdFromUrl();
  const clientDataId = getClientDataIdFromUrl();
  const clientDataBranch = getClientDataBranchFromUrl();
  const clientTable = getClientDataTableFromUrl();
  // Get the query string from the URL
  const queryString = window.location.search;
  if (!groupId || !clientDataId || !clientDataBranch) return;
  window.history.replaceState(
    null,
    '',
    `${AppRoutes.ClientData}/${groupId}/${clientDataId}/${clientDataBranch}/${table}${
      table === clientTable ? queryString : ''
    }`,
  );
};

export const getDeletedRowId = (rowId: string) => `${rowId}-deleted`;

/**
 * Get the configurator preview url
 *
 * @param {string} configuratorUrl
 * @param {ClientDataBranch | undefined} clientBranch
 * @param {ClientDataBranch | undefined} structureBranch
 * @param {ClientDataBranch | undefined} systemBranch
 * @returns {string}
 */
export const getConfiguratorPreviewUrl = (
  configuratorUrl: string,
  clientBranch: ClientDataBranch | undefined,
  structureBranch: ClientDataBranch | undefined,
  systemBranch: ClientDataBranch | undefined,
) => {
  const previewUrl =
    clientBranch || structureBranch || systemBranch
      ? `${configuratorUrl}` +
        `${configuratorUrl.indexOf('?') > -1 ? '&' : '?'}serverVersion=v2` +
        `&clientSettings=${clientBranch || ClientDataBranch.Main}` +
        `&structureSettings=${structureBranch || ClientDataBranch.Main}` +
        `&systemSettings=${systemBranch || ClientDataBranch.Main}`
      : configuratorUrl;
  return previewUrl;
};

export const getCellMetadataFormulasDiffMap = (cellMetadataDiff: ClientDataTableRowDiff[]) =>
  new Map(
    cellMetadataDiff.map((diff) => {
      const fromMetadata = diff.from_metadata || {};
      const toMetadata = diff.to_metadata || {};
      const uniqueColumns = Array.from(new Set([...Object.keys(fromMetadata), ...Object.keys(toMetadata)]));
      const diffWithFormulas = { ...diff };

      delete diffWithFormulas.from_metadata;
      delete diffWithFormulas.to_metadata;
      delete diffWithFormulas.from_clientId;
      delete diffWithFormulas.to_clientId;
      delete diffWithFormulas.from_table;
      delete diffWithFormulas.to_table;

      uniqueColumns.forEach((columnName) => {
        const fromFormula = (fromMetadata[columnName] || {}).formula;
        const toFormula = (toMetadata[columnName] || {}).formula;
        if (fromFormula !== toFormula) {
          diffWithFormulas[`from_${columnName}`] = fromFormula;
          diffWithFormulas[`to_${columnName}`] = toFormula;
        }
      });

      return [diff.rowId, diffWithFormulas];
    }),
  );

export const areDiffValuesDifferent = (a: any, b: any) =>
  (a === null || a === undefined ? '' : a) !== (b === null || b === undefined ? '' : b);

export const getRowsRevertData = (
  rows: { rowId: string; columns: string[] }[],
  tableDataDiff: ClientDataTableRowDiff[],
  cellMetadataFormulasDiffMap: CellMetadataFormulasDiffMap,
) => {
  const rangeDiff: {
    rowId: string;
    diffType: DoltDiffType;
    columns: {
      name: string;
      originalValue?: string | null;
      newValue?: string | null;
      originalFormula?: string | null;
      newFormula?: string | null;
    }[];
  }[] = [];
  rows.forEach((row) => {
    const filterColumns = row.columns.includes(DefaultColumnFieldNames.Index) ? null : row.columns;

    const columns: {
      name: string;
      originalValue?: string | null;
      newValue?: string | null;
      originalFormula?: string | null;
      newFormula?: string | null;
    }[] = [];
    const rowDiff = tableDataDiff.find((diff) => diff.rowId === row.rowId);
    if (rowDiff) {
      columns.push(
        ...Object.keys(rowDiff)
          .filter(
            (columnName) =>
              columnName.startsWith('from_') &&
              (!filterColumns || filterColumns.includes(columnName.replace('from_', ''))),
          )
          .reduce<{ name: string; originalValue?: string | null; newValue?: string | null }[]>(
            (acc, columnName) => [
              ...acc,
              {
                name: columnName.replace('from_', ''),
                originalValue: rowDiff[columnName],
                newValue: rowDiff[columnName.replace('from_', 'to_')],
              },
            ],
            [],
          )
          .filter((columnDiff) => areDiffValuesDifferent(columnDiff.originalValue, columnDiff.newValue)),
      );
    }

    const cellMetadataFormulaRowDiff = cellMetadataFormulasDiffMap.get(row.rowId);
    if (cellMetadataFormulaRowDiff) {
      const formulaChanges = Object.keys(cellMetadataFormulaRowDiff)
        .filter(
          (columnName) =>
            columnName.startsWith('from_') &&
            (!filterColumns || filterColumns.includes(columnName.replace('from_', ''))),
        )
        .reduce<{ name: string; originalFormula?: string | null; newFormula?: string | null }[]>(
          (acc, columnName) => [
            ...acc,
            {
              name: columnName.replace('from_', ''),
              originalFormula: cellMetadataFormulaRowDiff[columnName],
              newFormula: cellMetadataFormulaRowDiff[columnName.replace('from_', 'to_')],
            },
          ],
          [],
        )
        .filter((columnDiff) => columnDiff.originalFormula !== columnDiff.newFormula);
      formulaChanges.forEach((formulaChange) => {
        const existingColumnChange = columns.find((col) => col.name === formulaChange.name);
        if (existingColumnChange) {
          existingColumnChange.originalFormula = formulaChange.originalFormula;
          existingColumnChange.newFormula = formulaChange.newFormula;
        } else {
          columns.push(formulaChange);
        }
      });
    }

    if (columns.length > 0) {
      rangeDiff.push({ rowId: row.rowId, diffType: rowDiff?.diffType || DoltDiffType.Modified, columns });
    }
  });

  return rangeDiff;
};

export const getGridSelectedRangeRevertData = (
  api: GridApi,
  tableDataDiff: ClientDataTableRowDiff[],
  cellMetadataFormulasDiffMap: CellMetadataFormulasDiffMap,
) => {
  const cellRanges = api.getCellRanges();
  const { startRowIndex, endRowIndex, columns: selectedColumns } = getCellRangeInfo(cellRanges);

  const rows: { rowId: string; columns: string[] }[] = [];
  for (let i = startRowIndex; i <= endRowIndex; i += 1) {
    const rowNode = api.getDisplayedRowAtIndex(i);
    if (rowNode) {
      const { data } = rowNode;
      rows.push({
        rowId: data.diffType === DoltDiffType.Removed ? data.originalRowId : data.rowId,
        columns: selectedColumns,
      });
    }
  }

  return getRowsRevertData(rows, tableDataDiff, cellMetadataFormulasDiffMap);
};

export const revertRangeChanges = (
  clientDataTableId: string,
  table: string,
  tableData: TableData[],
  tableMetadata: TableMetadata,
  dispatch: typeof store.dispatch,
  rangeDataDiff: ReturnType<typeof getRowsRevertData>,
  isRange: boolean,
  fullRow: boolean,
  cellsMetadata: CellMetadata[],
) => {
  const rowCount = rangeDataDiff.length;
  let hasReevaluatedFormulaDiff = false;
  const rangeDataDiffWithoutReevaluatedFormulas = rangeDataDiff
    .map((diff) => {
      const cellMetadata = cellsMetadata.find((metadata) => metadata.rowId === diff.rowId);
      if (cellMetadata && cellMetadata.metadata) {
        // Filter out cells that changed because their formulas evaluated into a different result, but had no actual formula changes
        const columns = diff.columns.filter(
          (columnDiff) =>
            !cellMetadata.metadata[columnDiff.name] ||
            !cellMetadata.metadata[columnDiff.name].formula ||
            columnDiff.originalFormula !== columnDiff.newFormula,
        );
        hasReevaluatedFormulaDiff = hasReevaluatedFormulaDiff || diff.columns.length !== columns.length;
        return { ...diff, columns };
      }
      return diff;
    })
    .filter((diff) => diff.diffType !== DoltDiffType.Modified || diff.columns.length > 0);

  let selectionDescription = rowCount > 1 || isRange ? 'range' : 'cell';
  if (fullRow) {
    selectionDescription = rowCount > 1 ? 'lines' : 'line';
  }
  const formulaWarning = hasReevaluatedFormulaDiff
    ? '<br/>Some cell values will not change because their values are affected by formulas.'
    : '';
  if (rangeDataDiffWithoutReevaluatedFormulas.length > 0) {
    dispatch(
      openConfirmationDialog(
        [],
        [
          () => {
            const defaultValueIfNull = (columnName: string, value: any) => {
              if (value === undefined || value === null)
                return tableMetadata.metadata[columnName].dataType === ColumnDataType.Boolean ? 0 : null;
              return value;
            };

            const updates = flatMap(
              rangeDataDiffWithoutReevaluatedFormulas
                .filter((diff) => !fullRow || diff.diffType === DoltDiffType.Modified)
                .map((diff) => {
                  const data = tableData.find((row) => row.rowId === diff.rowId);
                  return diff.columns.map((columnDiff) => ({
                    table,
                    data,
                    column: columnDiff.name,
                    oldValue: defaultValueIfNull(
                      columnDiff.name,
                      areDiffValuesDifferent(columnDiff.originalFormula, columnDiff.newFormula)
                        ? // If new formula doesn't exist then it's a raw value
                          columnDiff.newFormula || columnDiff.newValue
                        : columnDiff.newValue,
                    ),
                    newValue: defaultValueIfNull(
                      columnDiff.name,
                      areDiffValuesDifferent(columnDiff.originalFormula, columnDiff.newFormula)
                        ? // If original formula doesn't exist then it was a raw value
                          columnDiff.originalFormula ||
                            (columnDiff.originalValue !== undefined // If originalValue is undefined it means it didn't actually change - get the value from the tableData
                              ? columnDiff.originalValue
                              : (data || ({} as any))[columnDiff.name])
                        : columnDiff.originalValue,
                    ),
                  }));
                }),
            );
            const updateRows = processUpdatedValues(updates, cellsMetadata);

            const rowsToDelete = rangeDataDiffWithoutReevaluatedFormulas
              .filter((diff) => fullRow && diff.diffType === DoltDiffType.Added)
              .map((diff) => ({ ...(tableData.find((row) => row.rowId === diff.rowId) || {}) }))
              .filter((row) => row.rowId !== undefined) as TableData[];

            const rowsToAdd = rangeDataDiffWithoutReevaluatedFormulas
              .filter((diff) => diff.diffType === DoltDiffType.Removed)
              .map((diff) => {
                const tableDataRow = tableData.find(
                  (row) => row.rowId === diff.rowId || row.originalRowId === diff.rowId,
                );
                if (tableDataRow) {
                  const rowData: TableData = { rowId: '' };

                  Object.keys(tableDataRow)
                    .filter((column) => tableMetadata.metadata[column])
                    .forEach((column) => {
                      rowData[column] = defaultValueIfNull(column, tableDataRow[column]);
                    });
                  rowData.rowId = tableDataRow.originalRowId as string;
                  return rowData;
                }
                return undefined;
              })
              .filter((row) => row !== undefined) as TableData[];

            const actionsToUndo: AnyAction[] = [];
            const actionsToRedo: AnyAction[] = [];
            if (updateRows.newRows.length > 0) {
              actionsToUndo.push(updateClientDataRows(updateRows.oldRows));
              actionsToRedo.push(updateClientDataRows(updateRows.newRows));
            }

            if (rowsToDelete.length > 0) {
              actionsToUndo.push(addClientDataRows({ rows: rowsToDelete, table }));
              actionsToRedo.push(removeClientDataRows({ rows: rowsToDelete, table }));
            }

            if (rowsToAdd.length > 0) {
              actionsToUndo.push(removeClientDataRows({ rows: rowsToAdd, table }));
              actionsToRedo.push(addClientDataRows({ rows: rowsToAdd, table }));
            }

            addDispatchCommandToUndo(dispatch, actionsToUndo, actionsToRedo, clientDataTableId, true);
          },
        ],
        '',
        `Are you sure you want to revert the data in the current ${selectionDescription}?${formulaWarning}`,
        'Revert',
      ),
    );
    dispatch(openDialog({ dialog: Dialogs.Confirmation }));
  } else {
    toast.error(`Can't revert cell change as the value is affected by a formula.`);
  }
};

export const getTableDataWithRemovedRows = (tableData: TableData[], tableDataDiff: ClientDataTableRowDiff[]) => {
  const removedRows = tableDataDiff
    .filter(({ diffType }) => diffType === DoltDiffType.Removed)
    .map((diff) => {
      const newDiff = {
        ...diff,
        rowId: getDeletedRowId(diff.rowId),
        originalRowId: diff.rowId,
        rowIsReadOnly: true,
      } as TableData;

      const columns = Object.keys(newDiff)
        .filter((columnName) => columnName.startsWith('from_'))
        .map((columnName) => columnName.replace('from_', ''));
      columns.forEach((column) => {
        newDiff[column] = newDiff[`from_${column}`];
        delete newDiff[`from_${column}`];
      });

      return newDiff;
    });

  return [...tableData, ...removedRows].sort((a, b) =>
    new Decimal65((a.order as string) || 0)
      .minus(new Decimal65((b.order as string) || 0))
      .trunc()
      .toNumber(),
  );
};

export const getInitialEditorValue = (props: ICellEditorParams) => {
  const { value: initialValue, eventKey } = props;

  let startValue = initialValue;
  const isBackspace = eventKey === 'Backspace';

  if (isBackspace) {
    startValue = '';
  } else if (eventKey && eventKey.length === 1) {
    startValue = eventKey;
  }

  if (startValue !== null && startValue !== undefined) {
    return startValue;
  }

  return '';
};

/**
 * Display a disco light show on the grid
 *
 * @param gridRef - grid reference
 * @param changeCellFlashColor - function to change the cell flash color
 * @param flashCount - number of flashes
 * @param delay - delay between flashes in ms
 */
export const disco = async (
  api: GridApi | undefined,
  changeCellFlashColor?: (color?: string) => void | undefined,
  discoType = 0, // 0 = disco, 1 = rainbow, 2 = halloween, 3 = christmas, 4 = easter
  flashCount = 10,
  delay = 500,
): Promise<void> => {
  const colorOptions = [
    [],
    ['#F2878A', '#EBA3DC', '#919DDB', '#6CD7DB', '#B6FFAA', '#FFFF95'], // rainbow
    ['#FF6F00', '#FF7518', '#EEEB27', '#666666', '#515594', '#881EE4'], // halloween
    ['#CC231E', '#34A65F', '#0F8A5F', '#FABC02', '#B5B5B5', '#D6001C'], // christmas
    ['#e0cdff', '#c1f0fb', '#dcf9a8', '#ffebaf', '#f9ceee'], // easter
  ];

  const colors = colorOptions[discoType];

  if (!api || !changeCellFlashColor) return;

  const getNextColor = (currentColor: string) => {
    const currentIndex = colors.indexOf(currentColor);
    return currentIndex === colors.length - 1 ? colors[0] : colors[currentIndex + 1];
  };

  const rowCount = api.getDisplayedRowCount() || 0;
  let cellFlashColor = '';
  for (let i = 0; i < flashCount; i += 1) {
    cellFlashColor = getNextColor(cellFlashColor);
    changeCellFlashColor(cellFlashColor);
    for (let j = 0; j < rowCount * 6; j += 1) {
      const row = Math.floor(Math.random() * rowCount);
      const rowNode = api.getDisplayedRowAtIndex(row);
      const columnDefs = api.getColumnDefs();
      const columns = columnDefs?.map((col: ColDef) => col.field || '') || [];
      const col = columns[Math.floor(Math.random() * columns.length)];
      if (rowNode) {
        api.flashCells({ rowNodes: [rowNode], columns: [col] });
      }
    }
    // eslint-disable-next-line no-await-in-loop, no-promise-executor-return
    await new Promise((resolve) => setTimeout(resolve, delay));
  }
  changeCellFlashColor();
};

/**
 * Display a disco light show on the grid upon publishing
 * Shows a rainbow disco light show by default
 * If the month of December, change the disco type to Christmas
 * If the week before Halloween, change the disco type to Halloween
 * If the week before Easter, change the disco type to Easter
 *
 * @param gridRef - grid reference
 * @param changeCellFlashColor - function to change the cell flash color
 * @param discoType - 0 = disco, 1 = rainbow, 2 = halloween, 3 = christmas, 4 = easter
 * @param flashCount - number of flashes
 * @param delay - delay between flashes in ms
 * @returns {void}
 */
export const publishDisco = async (
  api: GridApi | undefined,
  changeCellFlashColor: (color?: string) => void | undefined,
  discoType = 0, // 0 = disco, 1 = rainbow, 2 = halloween, 3 = christmas, 4 = easter
  flashCount = 10,
  delay = 500,
): Promise<void> => {
  const today = new Date();

  // If the month of December, change the disco type to Christmas
  const christmasEnd = new Date(today.getFullYear(), 11, 25 + 1);
  const christmasStart = new Date(today.getFullYear(), 11, 1);

  // If the week before Halloween, change the disco type to Halloween
  const halloweenEnd = new Date(today.getFullYear(), 9, 31 + 1);
  const halloweenStart = new Date(today.getFullYear(), 9, 24);

  // If the week before Easter, change the disco type to Easter
  const easterEnd = new Date(today.getFullYear(), 3, 4 + 1);
  const easterStart = new Date(today.getFullYear(), 2, 28);

  if (today >= christmasStart && today < christmasEnd) {
    // eslint-disable-next-line no-param-reassign
    discoType = 3;
  } else if (today >= halloweenStart && today < halloweenEnd) {
    // eslint-disable-next-line no-param-reassign
    discoType = 2;
  } else if (today >= easterStart && today < easterEnd) {
    // eslint-disable-next-line no-param-reassign
    discoType = 4;
  } else {
    // eslint-disable-next-line no-param-reassign
    discoType = 1;
  }

  await disco(api, changeCellFlashColor, discoType, flashCount, delay);
};

/**
 * Update the visibility of the status bar filter clear button
 *
 * @param {GridApi} api - grid api
 * @param {boolean} isVisible - true if the clear button should be visible
 */
export const updateStatusBarFilterClearButton = (api: GridApi, isVisible: boolean) => {
  const statusBarFilter = api.getStatusPanel<IClearGridFiltersStatusPanel>('ClearGridFilters');
  if (statusBarFilter) {
    statusBarFilter.setVisible(isVisible);
  }
};

/**
 * Format an expression with indentation and new line characters
 *
 * @param {string} expression - expression to format
 */
export const formatExpression = (expression: string): string => {
  // Indicates the start of a subexpression and not a function
  const isExpressionGroupStart = (char: string, precedingString: string) => {
    if (char !== '(') return false;
    return precedingString.length === 0 || ['\n', ' ', '!'].includes(precedingString[precedingString.length - 1]);
  };

  // Remove existing new lines and spaces around || and &&
  const cleanString = expression.replace(/\s*(\|\||&&)\s*/g, '$1').replace(/\n/g, '');

  // Add new lines around subexpressions and || and &&
  const stringWithNewLines = cleanString.split('').reduce((acc, char, index, charArr) => {
    // Add new line character after ( for subexpressions
    if (isExpressionGroupStart(char, acc)) return `${acc}${char}\n`;
    if (char === ')') {
      // Reverse the string to find the closest unbalanced opening parenthesis
      const groupStartIndex =
        acc.length -
        acc
          .split('')
          .reverse()
          .findIndex(
            (c, i, arr) =>
              c === '(' &&
              // Check all other parenthesis are balanced
              arr.slice(0, i).filter((e) => e === '(').length === arr.slice(0, i).filter((e) => e === ')').length,
          ) -
        1;
      // If this is the end of a subexpression, add new line character before
      if (isExpressionGroupStart(acc[groupStartIndex], acc.slice(0, groupStartIndex))) return `${acc}\n${char}`;
    }
    // Add new line character before and after || and &&
    if ((char === '|' && charArr[index + 1] === '|') || (char === '&' && charArr[index + 1] === '&'))
      return `${acc}\n${char}`;
    if ((char === '|' && charArr[index - 1] === '|') || (char === '&' && charArr[index - 1] === '&'))
      return `${acc}${char}\n`;

    return `${acc}${char}`;
  }, '');

  // Add indentation to the expression
  return stringWithNewLines.split('').reduce((acc, char, index, arr) => {
    if (char !== '\n') return `${acc}${char}`;

    const groupStarts = (acc.match(/\n\s*\(/g) || []).length;
    const functionStarts = (acc.match(/\(/g) || []).length - groupStarts;
    const groupEnds = (acc.match(/\)/g) || []).length - functionStarts;

    let unbalancedGroups = groupStarts - groupEnds;
    // Reduce the indentation if the next character is a closing parenthesis
    if (unbalancedGroups && arr[index + 1] === ')') unbalancedGroups -= 1;

    const indentation = '  '.repeat(unbalancedGroups);
    return `${acc}${char}${indentation}`;
  }, '');
};
